import React from 'react'
import { SelectDomain } from '../SelectDomain'
import { CreateDomain } from '../CreateDomain'

export function DomainRegistrationSection({
  pageData,
  retry,
  doDomainsExist,
  setSelectedOrganization,
  selectedOrganization,
  selectedCountry,
  setSelectedCountry,
  availableOrganizations,
  enteredOrganization,
  organizationEntryHandler,
  countrySelectorHandler,
  isOrganizationNameValid,
  hpPlusCountrySelected,
  supportedCountriesClickHandler,
  setEnteredOrganizationDescription
}) {
  return (
    <>
      {doDomainsExist && (
        <SelectDomain
          pageData={pageData}
          retry={retry}
          setSelectedOrganization={setSelectedOrganization}
          availableOrganizations={availableOrganizations}
          selectedOrganization={selectedOrganization}
        />
      )}
      {(!doDomainsExist || selectedOrganization === 'new') && (
        <CreateDomain
          pageData={pageData}
          enteredOrganization={enteredOrganization}
          organizationEntryHandler={organizationEntryHandler}
          retry={retry}
          isOrganizationNameValid={isOrganizationNameValid}
          hpPlusCountrySelected={hpPlusCountrySelected}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          countrySelectorHandler={countrySelectorHandler}
          supportedCountriesClickHandler={supportedCountriesClickHandler}
          setEnteredOrganizationDescription={setEnteredOrganizationDescription}
          doDomainsExist={doDomainsExist}
        />
      )}
    </>
  )
}
