import React, { useContext } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import classes from './ContentStackErrorModal.module.scss'
import Button from '@veneer/core/dist/scripts/button'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import { VENEER_SECONDARY_BUTTON_APPEARANCE } from '@/store/Constants'
import BizModelMismatchError from './BizModelMismatchError'
import PartnerLinkError from './PartnerLinkError'
import GenericError from './GenericError'
import PairingTokenError from './PairingTokenError'
import DuplicatedAccountError from '@/components/UI/Errors/DuplicatedAccountError'
import DeviceIsPaasError from '@/components/UI/Errors/DeviceIsPaasError'
import { getRedirectionPath } from '@/utils/Functions'
import AccountError from '@/components/UI/Errors/AccountError'
import RateLimitError from '@/components/UI/Errors/RateLimitError'
import ExpiredCodeError from '@/components/UI/Errors/ExpiredCodeError'
import DagDownError from '@/components/UI/Errors/DagDownError'
import ScanToEmailNotEnabledError from '@/components/UI/Errors/ScanToEmailNotEnabledError'
import UnsupportedLocaleError from '@/components/UI/Errors/UnsupportedLocaleError'
import { UNSUPPORTED_LOCALE_ERROR } from '@/store/ErrorContext'

const ERROR_ENUMS = {
  duplicated_account: DuplicatedAccountError,
  partner_link: PartnerLinkError,
  device_is_paas: DeviceIsPaasError,
  account: AccountError,
  activation_error: GenericError,
  pairing_code_consumed: PairingTokenError,
  pairing_error: PairingTokenError,
  pairing_code_expired: PairingTokenError,
  scan_to_email_not_enabled: ScanToEmailNotEnabledError,
  [UNSUPPORTED_LOCALE_ERROR]: UnsupportedLocaleError
}

const PAIRING_ERROR_ENUMS = {
  biz_model_mismatch: BizModelMismatchError,
  rate_limit: RateLimitError,
  pairing_code_expired: ExpiredCodeError,
  pairing_error: DagDownError
}

const ContentStackErrorModal = () => {
  const { error, errorCopy, errorCategory, errorData, stage } = useContext(
    ErrorContext
  )
  const { getTextTree } = useContext(ConfigContext)
  const commonCopy = getTextTree('common')
  const errorForDisplay = `(${commonCopy.error_code} ${error})`

  // The wrapper will inject all the error specific interfaces. However, the
  // error is only known at runtime. So we have to dynamically assign the
  // component wrapper.

  const ErrorWrapper =
    stage === STAGES.pairing
      ? PAIRING_ERROR_ENUMS[errorCategory]
      : ERROR_ENUMS[errorCategory]

  return (
    <ErrorWrapper error={error} errorCopy={errorCopy}>
      {(props) => (
        <ErrorModal
          errorCopy={errorCopy}
          errorForDisplay={errorForDisplay}
          errorData={errorData}
          {...props}
        />
      )}
    </ErrorWrapper>
  )
}
/**
 * @param {function} primaryClick - Primary click behavior of the component
 * @param {function} secondaryClick - Secondary click behavior of the component
 * @param {function} hyperlinkClick - Click event to fire should a hyperlink be clicked in the body
 * @param {object} errorCopy - Copy for the modal to show
 * @param {string} errorForDisplay - Error code for bottom of error modal
 * @param {object} errorData - Contains timeout details for rate limit error modal
 * @param {object} Layout - Layout used for the error
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorModal = ({
  primaryClick,
  secondaryClick,
  hyperlinkClick,
  errorCopy,
  errorForDisplay,
  errorData,
  Layout
}) => {
  const { offer, bizModel } = useContext(PrinterContext)
  const {
    contentStackLanguageSelection,
    setContentStackLanguageSelection
  } = useContext(UserContext)

  const fireChildAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      hyperlinkClick()
    }
  }

  let footer = (
    <>
      {errorCopy?.secondary_button_text && (
        <Button
          data-testid="modal-secondary-button"
          appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
          onClick={secondaryClick}
        >
          {errorCopy.secondary_button_text}
        </Button>
      )}
      {errorCopy?.primary_button_text && (
        <Button onClick={primaryClick} data-testid="modal-primary-button">
          {errorCopy.primary_button_text}
        </Button>
      )}
    </>
  )

  const layoutProps = {
    errorCopy,
    errorData,
    fireChildAnalyticsEvent,
    setContentStackLanguageSelection,
    contentStackLanguageSelection
  }

  return (
    <Modal
      id="error-modal"
      data-testid="error-modal"
      className={classes.modal}
      show={true}
      closeOnBlur={false}
      title={errorCopy.header}
      footer={
        <div className={`vn-button-group--responsive ${classes.footer}`}>
          {footer}
        </div>
      }
      alignFooter="end"
    >
      <div className={classes.error}>
        {Layout ? (
          <Layout {...layoutProps} />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: errorCopy.body.replace(
                '[[URL]]',
                'admin.hpsmart.com' + getRedirectionPath(offer, bizModel)
              )
            }}
            onClick={fireChildAnalyticsEvent}
          />
        )}
        <div className={classes.errorForDisplay}>
          <div>{errorForDisplay}</div>
        </div>
      </div>
    </Modal>
  )
}

export default ContentStackErrorModal
