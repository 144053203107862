// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qnq8yYd4dcseU9QsuDC9T{padding:0 14px;display:flex;flex-direction:column;flex-grow:1;justify-content:space-between}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/PartnerLink/PartnerLinkPage.module.scss"],"names":[],"mappings":"AAAA,yBAAiB,cAAc,CAAC,YAAY,CAAC,qBAAqB,CAAC,WAAW,CAAC,6BAA6B","sourcesContent":[".partnerLinkPage{padding:0 14px;display:flex;flex-direction:column;flex-grow:1;justify-content:space-between}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerLinkPage": "_2qnq8yYd4dcseU9QsuDC9T"
};
export default ___CSS_LOADER_EXPORT___;
