// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2edI5VArnSQjZijSxijCIr{display:flex;justify-content:flex-end;gap:10px;padding-top:40px}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Smallville/ButtonsFooter/ButtonsFooter.module.scss"],"names":[],"mappings":"AAAA,yBAAe,YAAY,CAAC,wBAAwB,CAAC,QAAQ,CAAC,gBAAgB","sourcesContent":[".buttonsFooter{display:flex;justify-content:flex-end;gap:10px;padding-top:40px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsFooter": "_2edI5VArnSQjZijSxijCIr"
};
export default ___CSS_LOADER_EXPORT___;
