// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2clFOck-eG9U01HiDixnEh ._8PkY9Kf9nczTO6ajGNNZg{margin-bottom:16px;font-size:40px;line-height:48px}._2clFOck-eG9U01HiDixnEh p{font-size:16px;line-height:24px;margin-bottom:40px}._2clFOck-eG9U01HiDixnEh ._12toK0cLFXgfbwvZUVSs0A{display:block;text-align:right}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/Account/CreateAccountPage.module.scss"],"names":[],"mappings":"AAAA,iDAAmB,kBAAkB,CAAC,cAAc,CAAC,gBAAgB,CAAC,2BAAa,cAAc,CAAC,gBAAgB,CAAC,kBAAkB,CAAC,kDAAmB,aAAa,CAAC,gBAAgB","sourcesContent":[".container .header{margin-bottom:16px;font-size:40px;line-height:48px}.container p{font-size:16px;line-height:24px;margin-bottom:40px}.container .footer{display:block;text-align:right}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2clFOck-eG9U01HiDixnEh",
	"header": "_8PkY9Kf9nczTO6ajGNNZg",
	"footer": "_12toK0cLFXgfbwvZUVSs0A"
};
export default ___CSS_LOADER_EXPORT___;
