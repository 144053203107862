import React, { useContext } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ErrorContext, PrinterContext, UserContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS

const AccountError = ({ error, children }) => {
  const errorModalAnalytics = useAnalytics(SCREENS.DOMAIN_ERROR_MODAL)
  const { resetError } = useContext(ErrorContext)

  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.TRY_AGAIN)
    resetError()
  }

  const hyperlinkClick = () => {
    errorModalAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
  }

  errorModalAnalytics.fireScreenDisplayed({
    detail: error,
    name: ANALYTICS.ERROR_CODE
  })

  return (
    <>
      {children({
        primaryClick,
        hyperlinkClick
      })}
    </>
  )
}

export default AccountError
