import {
  getAndParseListElementsIntoArray,
  getFirstTextContentByTag,
  getResourceUriElement,
  getElementTextContent,
  getFirstElementByTag
} from './ElementUtils'

export const getResourceTypeElement = (element) =>
  getFirstElementByTag(element, 'dd:ResourceType')

export const getManifestURI = (element) =>
  getFirstTextContentByTag(element, 'ledm:ManifestURI')

export const getManifestResourceNodesElements = (element) =>
  getAndParseListElementsIntoArray(element, 'map:ResourceNode')

export const getManifestResourceMap = (manifest) =>
  getFirstElementByTag(manifest, 'map:ResourceMap')

export const buildManifestUri = (resourceNodeElement) => {
  let element = resourceNodeElement
  const resourceUriElements = []

  while (element) {
    const resourceUriElement = getResourceUriElement(element)

    if (
      resourceUriElement &&
      !resourceUriElements.includes(resourceUriElement)
    ) {
      resourceUriElements.push(resourceUriElement)
    }
    element = element.parentElement
  }

  return resourceUriElements
    .reverse()
    .reduce(
      (path, resourceUriElement) =>
        `${path}${getElementTextContent(resourceUriElement)}`,
      ''
    )
}

export const checkMethodExists = (resourceNode, method) => {
  const methods = getAndParseListElementsIntoArray(resourceNode, 'map:Verb')

  return methods.some(
    (element) =>
      getElementTextContent(element).toUpperCase() === method.toUpperCase()
  )
}

export const findResourceNodeByTypeKeyAndUri = (
  resourceNodes,
  uriResourceTypeKey,
  { resourceType, resourceUri }
) => {
  return resourceNodes.find((resourceNode) => {
    let resourceLinkCheck = true

    const uriResourceTypeElement = getFirstElementByTag(
      resourceNode,
      uriResourceTypeKey
    )

    if (resourceUri) {
      const resourceUriElement = getResourceUriElement(resourceNode)
      resourceLinkCheck =
        !!resourceUriElement &&
        getElementTextContent(resourceUriElement) === resourceUri
    }

    return (
      !!uriResourceTypeElement &&
      getElementTextContent(uriResourceTypeElement) === resourceType &&
      resourceLinkCheck
    )
  })
}
