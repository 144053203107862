import React from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ANALYTICS } from '@/store/Constants'
import { TimerLayout } from './Layouts/TimerLayout'

const { SCREENS } = ANALYTICS
const RateLimitError = ({ error, children }) => {
  const errorModalAnalytics = useAnalytics(SCREENS.CODE_LIMIT_ERROR)

  errorModalAnalytics.fireModalWindowDisplayed({
    actionAuxParams: `error=${error}`,
    name: ANALYTICS.ERROR_CODE
  })

  return <>{children({ Layout: TimerLayout })}</>
}

export default RateLimitError
