import React from 'react'
import NextStageButton from '@/components/UI/NextStageButton'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import classes from './AssignOrgAppTemplate.module.scss'
import { ButtonsFooter, PrinterView } from '@/components/UI/Smallville'

export function AssignOrgAppTemplate({
  pageData,
  retry,
  assignOwnershipHandler,
  submitDisabled,
  domainRegistrationSection
}) {
  return (
    <>
      <TextHeader>{pageData.header}</TextHeader>
      <TextSubHeader>{pageData.subheader}</TextSubHeader>
      <div className={classes.assignOrganization} data-testid="assign-org-page">
        <div className={classes.baseContainer}>
          <div className={classes.leftSection}>{domainRegistrationSection}</div>
          <div className={classes.rightSection}>
            <PrinterView data-testid="printer-view" />
          </div>
        </div>
        <ButtonsFooter>
          <NextStageButton
            copy={pageData.continue_button_text}
            onClick={assignOwnershipHandler}
            dataTestId="submit"
            disabled={submitDisabled}
            loading={retry}
          />
        </ButtonsFooter>
      </div>
    </>
  )
}
