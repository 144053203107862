import { SPLUNK_RUM_FIELDS } from '@/store/Constants'
import { Logger } from '@/utils/Logger'

export function publishRumEvent(moduleName, workflow, attributes) {
  try {
    window.Shell.v1.monitoring
      .startSpan(moduleName, {
        [SPLUNK_RUM_FIELDS.WORKFLOW]: workflow,
        ...attributes
      })
      .end()
  } catch (error) {
    Logger.error('Not able to start span through Shell.v1.monitoring', error)
  }
}
