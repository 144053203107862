import { DeviceShadowApiClient } from '@jarvis/web-stratus-client'
import { useContext, useEffect, useRef, useState } from 'react'
import { ConfigContext, UserContext } from '@/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useErrorRegistered from './useErrorRegistered'
import { Logger } from '@/utils/Logger'

const RTP_CHECK_RETRY_MILLISECONDS = 5 * 1000

const useDeviceRtp = () => {
  const [waitingForRtp, setWaitingForRtp] = useState(false)
  const [rtpStatus, setRtpStatus] = useState(null)
  const deviceShadowClient = useRef(null)
  const { getSessionId } = useContext(UserContext)
  const { authProvider, stack } = useContext(ConfigContext)
  const { errorHasBeenRegistered } = useErrorRegistered()
  const { onbpReleaseRtpconfigtrigger: checkRtpConfig } = useFlags()

  const _reject = (error, code, suffix) => {
    // Suppress 404 as it takes time for RTP shadow to be available
    if (error.response.status === 404) {
      return {
        status: 'success',
        data: null
      }
    }

    let _code
    try {
      _code = error.response.data.errors.pop().code
    } catch {
      _code = code
    }

    return {
      status: 'error',
      message: `SD_${error.response.status}_${_code}_${suffix}`
    }
  }

  // If the desired state is not present and reported state is present the
  // device must have already accepted all recent requests available to it
  const getRtpConfig = (cloudId) => {
    return deviceShadowClient.current
      .getRtpConfig({
        cloudId,
        headers: { 'X-Correlation-ID': getSessionId() }
      })
      .then((response) => {
        return {
          status: 'success',
          data:
            response.data?.state?.desired?.cdmData ||
            response.data?.state?.reported?.cdmData
        }
      })
      .catch((err) => {
        Logger.error('getRtpConfig error:', err)
        return _reject(err, 'OPRTP0002', 'RC')
      })
  }

  const getRtpStatus = (cloudId) => {
    return deviceShadowClient.current
      .getRtpStatus({
        cloudId,
        headers: { 'X-Correlation-ID': getSessionId() }
      })
      .then((response) => {
        return {
          status: 'success',
          data: checkRtpConfig
            ? response?.data?.state?.reported?.cdmData
            : parseInt(response?.data?.timestamp)
        }
      })
      .catch((err) => {
        Logger.error('getRtpStatus error:', err)
        return _reject(err, 'OPRTP0001', 'RS')
      })
  }

  const _sleepAndWaitForRtp = async (claimTimestamp, cloudId) => {
    await new Promise((resolve) =>
      setTimeout(resolve, RTP_CHECK_RETRY_MILLISECONDS)
    )
    return waitForRtp(claimTimestamp, cloudId)
  }

  const waitForRtp = async (claimTimestamp, cloudId) => {
    if (!deviceShadowClient.current || errorHasBeenRegistered.current) return
    setWaitingForRtp(true)

    let getStatus = await getRtpStatus(cloudId)
    if (getStatus.status === 'error') {
      setRtpStatus(getStatus)
      setWaitingForRtp(false)
      return
    }

    if (!checkRtpConfig) {
      if (getStatus.data > claimTimestamp) {
        setRtpStatus({ status: 'success' })
        setWaitingForRtp(false)
        return
      }
      return _sleepAndWaitForRtp(claimTimestamp, cloudId)
    }

    let getConfig = await getRtpConfig(cloudId)
    if (getConfig.status === 'error') {
      setRtpStatus(getConfig)
      setWaitingForRtp(false)
      return
    }

    if (
      getStatus?.data?.lastValidConfigSeqNum &&
      getStatus?.data?.lastValidConfigSeqNum === getConfig?.data?.configSeqNum
    ) {
      setRtpStatus({ status: 'success' })
      setWaitingForRtp(false)
      return
    }

    return _sleepAndWaitForRtp(claimTimestamp, cloudId)
  }

  useEffect(() => {
    deviceShadowClient.current = new DeviceShadowApiClient(stack, authProvider)
    return () => {
      deviceShadowClient.current = null
    }
  }, [authProvider, stack])

  return {
    rtpStatus,
    waitingForRtp,
    waitForRtp
  }
}

export default useDeviceRtp
