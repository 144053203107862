import React, { useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import {
  ANALYTICS,
  VENEER_SECONDARY_BUTTON_APPEARANCE,
  VENEER_TERTIARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import classes from './FirstPrintButton.module.scss'
import { createPortal } from 'react-dom'
import fallbackImage from './FirstPrintDocumentImage.png'
import printJS from '@/assets/printjs_override/src/index'
import { Logger } from '@/utils/Logger'

const { BUTTONS } = ANALYTICS

const FirstPrintButton = ({
  copy,
  image,
  disabled = false,
  fireButtonClick,
  isMobile,
  isAndroid
}) => {
  const [contentRef, setContentRef] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const mountNode = contentRef?.contentWindow?.document?.body
  const [printButtonAppearance, setPrintButtonAppearance] = useState(
    VENEER_SECONDARY_BUTTON_APPEARANCE
  )

  const isPDF = (image?.image?.url || '').toLowerCase().endsWith('.pdf')
  const url = image?.image?.url

  const openToPrint = (url, windowOpen) => {
    setIsDownloading(true)
    fetch(url).then((response) => {
      response
        .blob()
        .then((blob) => {
          if (isAndroid) window.open(window.URL.createObjectURL(blob), '_blank')
          else windowOpen.location.href = window.URL.createObjectURL(blob)
        })
        .catch((err) => {
          Logger.error('Could not open first print PDF.', err)
        })
        .finally(() => {
          setIsDownloading(false)
        })
    })
  }

  const printPage = (windowOpen) => {
    fireButtonClick(BUTTONS.PRINT_TEST_PAGE)
    setPrintButtonAppearance(VENEER_TERTIARY_BUTTON_APPEARANCE)
    if (isMobile) {
      return openToPrint(url, windowOpen)
    }
    if (isPDF) {
      return printJS(url)
    }
    document.querySelector('[data-id=FirstPrintDocument]').contentWindow.print()
  }

  return (
    <>
      {!isPDF && (
        <iframe
          ref={setContentRef}
          data-id={'FirstPrintDocument'}
          className={classes.framePage}
        >
          {mountNode &&
            createPortal(
              <div className={classes.page}>
                <img
                  src={image?.image?.url || fallbackImage}
                  style={{
                    width: '21cm',
                    maxHeight: '27.17cm',
                    objectFit: 'contain'
                  }}
                />
              </div>,
              mountNode
            )}
        </iframe>
      )}
      <Button
        appearance={printButtonAppearance}
        data-testid="first-print-button"
        onClick={() => {
          //The only javascript that is allowed to open a new window in Safari is javascript directly attached to a user's event
          isMobile && !isAndroid ? printPage(window.open()) : printPage()
        }}
        loading={isDownloading}
        disabled={disabled}
      >
        {copy}
      </Button>
    </>
  )
}

export default FirstPrintButton
