import React, { useEffect, useState } from 'react'
import Select from '@veneer/core/dist/scripts/select'
import { SUPPORTED_LOCALE_SELECTION_ITEMS } from '@/store/Constants'
import classes from './SelectSupportedLocaleLayout.module.scss'

export const SelectSupportedLocaleLayout = ({
  errorCopy,
  setContentStackLanguageSelection,
  contentStackLanguageSelection
}) => {
  const [numberOfVisibleOptions, setNumberOfVisibleOptions] = useState(8)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleResize = () => {
    let clientHeight = document.documentElement.clientHeight

    if (clientHeight > 875 && numberOfVisibleOptions !== 8) {
      setNumberOfVisibleOptions(8)
    } else if (clientHeight < 876 && clientHeight > 767) {
      setNumberOfVisibleOptions(7)
    } else if (clientHeight < 768 && clientHeight > 683) {
      setNumberOfVisibleOptions(6)
    } else if (clientHeight < 684 && clientHeight > 583) {
      setNumberOfVisibleOptions(5)
    } else if (clientHeight < 584 && numberOfVisibleOptions !== 4) {
      setNumberOfVisibleOptions(4)
    }
  }

  return (
    <div className={classes.selectSupportedLocaleLayoutModal}>
      <div
        dangerouslySetInnerHTML={{
          __html: errorCopy.body
        }}
      />
      <Select
        clearIcon={false}
        className={classes.select}
        options={SUPPORTED_LOCALE_SELECTION_ITEMS}
        id="select-usage"
        data-testid="unsupported-locale-select"
        label={errorCopy.unsupported_locale_select_label}
        placement="bottom"
        helperTextVisibility="auto"
        onChange={(selectedOption) => {
          setContentStackLanguageSelection([selectedOption.value])
        }}
        value={contentStackLanguageSelection}
        visibleOptions={numberOfVisibleOptions}
      />
    </div>
  )
}
