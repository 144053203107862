import { Stack } from '@jarvis/web-stratus-client'
import {
  BIZ_MODEL_HP_PLUS,
  ERROR_SUFFIX_AND_RELATED_MODE,
  ONBOARDING_CENTER_DOMAIN_PIE,
  ONBOARDING_CENTER_DOMAIN_PROD,
  ONBOARDING_CENTER_DOMAIN_STAGE
} from '@/store/Constants'
import { Logger } from '@/utils/Logger'

export const getStackPrefix = (stack, suffix = '-') => {
  if (stack === Stack.dev) return `dev${suffix}`
  if (stack === Stack.stage) return `stage${suffix}`
  if (stack === Stack.prod) return ``
  return `pie${suffix}`
}

export const getRedirectionPath = (
  offer,
  bizModel,
  contentStackLanguageSelection = '',
  isLfp = false
) => {
  if (offer) {
    return contentStackLanguageSelection + '/onboard'
  }
  if (isLfp) {
    return contentStackLanguageSelection + '/go'
  }
  if (bizModel?.toUpperCase() === BIZ_MODEL_HP_PLUS) {
    return contentStackLanguageSelection + '/activate'
  }
  return contentStackLanguageSelection + '/connect'
}

export const replaceFragments = (copy) => {
  return copy.replace(/(href=(?:"|')+#(?:"|')+)/g, 'href="javascript:void(0);"')
}

export const EMPTY_FUNCTION = () => {
  //intentional empty function
}

export const updatePageCopyWithPrefixes = (pageCopy, prefixes) => {
  let updatedPageCopy = pageCopy
  prefixes.forEach((prefix) => {
    // update keys with prefixed
    updatedPageCopy = mergePageCopyEntriesWithPrefix(updatedPageCopy, prefix)
    // update copy with block prefix
    if (updatedPageCopy[prefix]) {
      updatedPageCopy = mergePageCopies(
        updatedPageCopy,
        updatedPageCopy[prefix]
      )
    }
  })
  return updatedPageCopy
}

export const getModeForAnalytics = (error) => {
  let mode = null
  for (const key in ERROR_SUFFIX_AND_RELATED_MODE) {
    if (error.endsWith(key)) {
      mode = ERROR_SUFFIX_AND_RELATED_MODE[key]
    }
  }
  return mode
}

const mergePageCopyEntriesWithPrefix = (pageCopy, prefix) => {
  let updatedPageCopy = {}
  Object.keys(pageCopy).forEach((keyName) => {
    const prefixedKeyName = `${prefix}_${keyName}`
    if (typeof pageCopy[keyName] === 'object') {
      updatedPageCopy[keyName] = mergePageCopyEntriesWithPrefix(
        pageCopy[keyName],
        prefix
      )
      return
    }
    if (pageCopy[prefixedKeyName]) {
      updatedPageCopy[keyName] = pageCopy[prefixedKeyName]
    } else if (keyName.indexOf(prefix + '_') === -1) {
      updatedPageCopy[keyName] = pageCopy[keyName]
    }
  })
  return updatedPageCopy
}

const mergePageCopies = (pageCopy, anotherPageCopy) => {
  const updatedPageCopy = {}
  Object.keys(pageCopy).forEach((value) => {
    if (typeof pageCopy[value] === 'object') {
      if (anotherPageCopy && anotherPageCopy[value]) {
        updatedPageCopy[value] = mergePageCopies(
          pageCopy[value],
          anotherPageCopy[value]
        )
      } else {
        updatedPageCopy[value] = pageCopy[value]
      }
    } else {
      updatedPageCopy[value] =
        (anotherPageCopy && anotherPageCopy[value]) || pageCopy[value]
    }
  })
  return updatedPageCopy
}

export const changeHref = (htmlText, newUrl) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlText
  try {
    const aElement = tempDiv.querySelector('a')
    aElement.setAttribute('href', newUrl)
  } catch (error) {
    Logger.error('Error setting href value to anchor element: ', error)
  }
  return tempDiv.innerHTML
}

export const clipboardAPICopyCommand = (htmlText) => {
  const blobInput = new Blob([htmlText], {
    type: 'text/html'
  })
  // eslint-disable-next-line no-undef
  const clipboardItemInput = new ClipboardItem({ 'text/html': blobInput })
  navigator.clipboard.write([clipboardItemInput])
}

export const fallbackCopyCommand = (htmlText) => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = htmlText
  document.body.appendChild(tempElement)
  const range = document.createRange()
  range.selectNode(tempElement)
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  document.execCommand('copy')
  selection.removeAllRanges()
  document.body.removeChild(tempElement)
}
