// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK{display:flex;justify-content:space-between;padding:25px 0;border-bottom:1px solid lightgray}._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK:last-child{border-bottom:0}._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK ._11JrzufkdoxtndpcVz9D7t{display:flex;flex-direction:column;gap:4px;max-width:580px}._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK ._11JrzufkdoxtndpcVz9D7t h6{font-size:18px;font-weight:400;line-height:24px}._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK ._11JrzufkdoxtndpcVz9D7t span{font-size:14px;font-weight:400;line-height:17px}._3HTodemIYF5OIh7VZJpKZT ._3HcV4dMsJ8_QT0AWLD-5wK ._DdRLDeRX53FNZyvltkQF{align-self:center}\n", "",{"version":3,"sources":["webpack://./src/components/UI/ECP/FinishSetupActionList/FinishSetupActionList.module.scss"],"names":[],"mappings":"AAAA,kDAA6B,YAAY,CAAC,6BAA6B,CAAC,cAAc,CAAC,iCAAiC,CAAC,6DAAwC,eAAe,CAAC,2EAA+C,YAAY,CAAC,qBAAqB,CAAC,OAAO,CAAC,eAAe,CAAC,8EAAkD,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,gFAAoD,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,yEAA8C,iBAAiB","sourcesContent":[".finishSetupActionList .item{display:flex;justify-content:space-between;padding:25px 0;border-bottom:1px solid lightgray}.finishSetupActionList .item:last-child{border-bottom:0}.finishSetupActionList .item .contentContainer{display:flex;flex-direction:column;gap:4px;max-width:580px}.finishSetupActionList .item .contentContainer h6{font-size:18px;font-weight:400;line-height:24px}.finishSetupActionList .item .contentContainer span{font-size:14px;font-weight:400;line-height:17px}.finishSetupActionList .item .buttonContainer{align-self:center}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishSetupActionList": "_3HTodemIYF5OIh7VZJpKZT",
	"item": "_3HcV4dMsJ8_QT0AWLD-5wK",
	"contentContainer": "_11JrzufkdoxtndpcVz9D7t",
	"buttonContainer": "_DdRLDeRX53FNZyvltkQF"
};
export default ___CSS_LOADER_EXPORT___;
