import React, { useContext, useState } from 'react'
import classes from './InstallDriversPage.module.scss'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  HP_SMART_SOFTWARE_URL,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import { ConfigContext, UserContext } from '@/store'
import Button from '@veneer/core/dist/scripts/button'
import ButtonGroup from '@veneer/core/dist/scripts/button_group'
import useContentStack from '@/hooks/useContentStack'
import useAnalytics from '@/hooks/useAnalytics'

const buildCards = (data, installHpSmartHandler, fireHyperLinkClick) => {
  const onClickLink = (event) => {
    if (event.target.closest('a').href.indexOf('#LearnHpSmartLink') !== -1) {
      fireHyperLinkClick(ANALYTICS.LINKS.LEARN_HP_SMART)
      window.open(
        'https://support.hp.com/us-en/document/ish_1716406-1413451-16',
        '_blank'
      )
    }
  }

  if (!data) return <></>
  return data.map((item, i) => (
    <div className={classes.card} key={`card-${i}`}>
      <div className={classes.cardHeader}>
        <img
          src={item.section.image.url}
          data-testid={`image-${item.section.image.url}`}
        />
        <div className={classes.cardText}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.section.header
            }}
          />
        </div>
      </div>
      {item.section.card_body.map((card_body_item, j) => (
        <div className={classes.cardText} key={`text-${j}`}>
          <p
            onClick={onClickLink}
            data-testid={`card-text-${j}`}
            dangerouslySetInnerHTML={{
              __html: card_body_item.text.copy
            }}
          />
        </div>
      ))}
      {item.section.button_text && (
        <div className={classes.installHpSmartButton}>
          <Button
            onClick={installHpSmartHandler}
            data-testid="install-hp-smart"
          >
            {item.section.button_text}
          </Button>
        </div>
      )}
    </div>
  ))
}

const InstallDriversPage = () => {
  const configCtx = useContext(ConfigContext)
  const { navigation } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)
  const [installHpSmartClicked, setInstallHpSmartClicked] = useState(false)
  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(ANALYTICS.SCREENS.INSTALL_DRIVER_OTHER)

  const parseCmsData = (data) => {
    const parsedData = {}
    parsedData.header = data.header
    parsedData.cards = data.cards
    parsedData.skip_button_text = data.skip_button_text
    parsedData.continue_button_text = data.continue_button_text

    return parsedData
  }

  const installHpSmartHandler = () => {
    fireButtonClick(ANALYTICS.BUTTONS.INSTALL_HP_SMART)
    setInstallHpSmartClicked(true)
    window.open(HP_SMART_SOFTWARE_URL, '_blank')
  }

  const skipHandler = () => {
    fireButtonClick(ANALYTICS.BUTTONS.SKIP)
    configCtx.nextStage({
      result: 'declined',
      xCorrelationId: getSessionId()
    })
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.install_drivers_non_windows,
    parsing_function: parseCmsData
  })

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div className={classes.installDrivers} data-testid="install-drivers-page">
      <div className={classes.header}>{pageData.header}</div>
      <div className={classes.cards}>
        {buildCards(pageData.cards, installHpSmartHandler, fireHyperLinkClick)}
      </div>
      <div className={classes.buttons}>
        <Button
          appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
          data-testid="skip"
          onClick={skipHandler}
        >
          {pageData.skip_button_text}
        </Button>
        <Button
          disabled={!installHpSmartClicked}
          data-testid="continue"
          onClick={() => {
            navigation.push('first-print')
            fireButtonClick(ANALYTICS.BUTTONS.CONTINUE)
          }}
        >
          {pageData.continue_button_text}
        </Button>
      </div>
    </div>
  )
}

export default InstallDriversPage
