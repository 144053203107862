import React, { useContext, useState } from 'react'
import { PrinterContext, ConfigContext } from '@/store'
import useAnalytics from '@/hooks/useAnalytics'
import { ANALYTICS, CONTENT_STACK_TYPES } from '@/store/Constants'
import useContentStack from '@/hooks/useContentStack'
import { NamePrinterAppTemplate, NamePrinterWebTemplate } from './Templates'
import { useNextStage } from '@/hooks/useNextStage'

const { BUTTONS, MODES, SCREENS } = ANALYTICS

const NamePrinterPage = () => {
  const { navigation, isScanSetup, isBinaryApp } = useContext(ConfigContext)
  const { setName, setLocation, isHpPlus, isSingleSku } = useContext(
    PrinterContext
  )
  const [enteredName, setEnteredName] = useState(null)
  const [enteredLocation, setEnteredLocation] = useState(null)
  const { dispatchSuccessStage } = useNextStage()

  const {
    fireScreenDisplayed,
    fireButtonClick,
    buildButtonClickEvent,
    fireBatchEvents
  } = useAnalytics(SCREENS.PRINTER_NAME)
  fireScreenDisplayed()

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.printer_name_location
  })

  const navigate = () => {
    if (isScanSetup || isBinaryApp) {
      const output = {
        user: {
          selectedDeviceName: enteredName,
          selectedDeviceLocation: enteredLocation
        }
      }
      dispatchSuccessStage(output)
    } else {
      if (isSingleSku) return navigation.push('/hp-plus-benefits')
      if (isHpPlus) return navigation.push('/activation')
      return navigation.push('/activating')
    }
  }

  const submitHandler = () => {
    setName(enteredName)
    setLocation(enteredLocation)
    fireBatchEvents([
      buildButtonClickEvent(BUTTONS.CONTINUE),
      buildButtonClickEvent(BUTTONS.PRINTER_LOC, {
        detail: MODES.PROVIDED[!!enteredLocation]
      }),
      buildButtonClickEvent(BUTTONS.PRINTER_NAME, {
        detail: MODES.PROVIDED[!!enteredName]
      })
    ])
    navigate()
  }

  const skipHandler = () => {
    fireButtonClick(BUTTONS.SKIP)
    navigate()
  }

  if (!pageData) return <></>

  const templateProps = {
    pageData,
    enteredName,
    enteredLocation,
    setEnteredName,
    setEnteredLocation,
    skipHandler,
    submitHandler
  }

  return isBinaryApp ? (
    <NamePrinterAppTemplate {...templateProps} />
  ) : (
    <NamePrinterWebTemplate {...templateProps} />
  )
}

export default NamePrinterPage
