import { useCallback, useContext, useMemo } from 'react'
import { getManifestUriByResourceType } from '@/utils/DeviceHttpProxy'
import { DeviceHttpProxyContext } from '@/store'

export function useLedmManifest() {
  const {
    fetch,
    resourceController: { getManifest, setManifest, getPrinterServices }
  } = useContext(DeviceHttpProxyContext)

  const fetchManifest = useCallback(
    async (manifestResourceType) => {
      const manifest = getManifest(manifestResourceType)
      if (manifest) {
        return manifest
      }
      const path = getManifestUriByResourceType(
        getPrinterServices(),
        manifestResourceType
      )
      const { body } = await fetch({ path, method: 'GET' })
      setManifest(manifestResourceType, body.data)
      return body.data
    },
    [getManifest, setManifest, fetch, getPrinterServices]
  )

  return useMemo(() => ({ fetchManifest }), [fetchManifest])
}
