// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2zfL_FnM3H67C3gwLUFvJZ{display:flex}._2zfL_FnM3H67C3gwLUFvJZ>*{flex-basis:100%}._2zfL_FnM3H67C3gwLUFvJZ ._3I5Va_h-CKjScOkAeac2SZ{margin:40px 0}._2zfL_FnM3H67C3gwLUFvJZ ._3ifsLtpFcWxlu-w2KJ3IYJ{margin-top:40px;margin-bottom:72px}._2zfL_FnM3H67C3gwLUFvJZ ._3ifsLtpFcWxlu-w2KJ3IYJ ._1c7Hg_C8P0WUTzDwiPnE4F{height:102px}._2zfL_FnM3H67C3gwLUFvJZ ._3ifsLtpFcWxlu-w2KJ3IYJ ._1rXDZ4DO-ov_i_Cg4WprVs{max-width:100%}@media screen and (max-width: 1279px){._2zfL_FnM3H67C3gwLUFvJZ ._3I5Va_h-CKjScOkAeac2SZ{margin:16px 0}._2zfL_FnM3H67C3gwLUFvJZ ._3ifsLtpFcWxlu-w2KJ3IYJ{margin-top:16px}}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/Activation/NamePrinterPage/Templates/NamePrinterAppTemplate/NamePrinterAppTemplate.module.scss"],"names":[],"mappings":"AAAA,yBAAS,YAAY,CAAC,2BAAW,eAAe,CAAC,kDAAuB,aAAa,CAAC,kDAAiB,eAAe,CAAC,kBAAkB,CAAC,2EAAgC,YAAY,CAAC,2EAA0B,cAAc,CAAC,sCAAsC,kDAAuB,aAAa,CAAC,kDAAiB,eAAe,CAAC","sourcesContent":[".content{display:flex}.content>*{flex-basis:100%}.content .imageContent{margin:40px 0}.content .inputs{margin-top:40px;margin-bottom:72px}.content .inputs .textContainer{height:102px}.content .inputs .textBox{max-width:100%}@media screen and (max-width: 1279px){.content .imageContent{margin:16px 0}.content .inputs{margin-top:16px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_2zfL_FnM3H67C3gwLUFvJZ",
	"imageContent": "_3I5Va_h-CKjScOkAeac2SZ",
	"inputs": "_3ifsLtpFcWxlu-w2KJ3IYJ",
	"textContainer": "_1c7Hg_C8P0WUTzDwiPnE4F",
	"textBox": "_1rXDZ4DO-ov_i_Cg4WprVs"
};
export default ___CSS_LOADER_EXPORT___;
