import React, { useEffect, useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import {
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import classes from './GenericModal.module.scss'
import DeclineHpPlusModal from './DeclineHpPlusModal'
import PdsnModal from './PdsnModal'
import LearnMoreModal, { CardLayout } from './LearnMoreModal'
import GenericAccordionModal, { AccordionLayout } from './GenericAccordionModal'
import LearnMoreOverviewSaveOnInkModal from './LearnMoreOverviewSaveOnInkModal'
import ReconsiderModal from './ReconsiderModal'
import { ExitHpCloudConnection } from './ExitHpCloudConnection'

export const GENERIC_MODAL_TYPES = {
  PDSN_MODAL: 'pdsn',
  DECLINE_HP_PLUS_MODAL: 'decline_hp_plus',
  LEARN_MORE_MODAL: 'learn_more',
  LEARN_MORE_HP_PLUS_OVERVIEW_MODAL: 'learn_more_hp_plus_overview',
  LEARN_MORE_INSTANT_INK_MODAL: 'learn_more_instant_ink',
  LEARN_MORE_HP_PLUS_REQUIREMENTS_MODAL: 'learn_more_hp_plus_requirements',
  LEARN_MORE_SMART_SECURITY_MODAL: 'learn_more_smart_security',
  LEARN_MORE_FOREST_FIRST_MODAL: 'learn_more_forest_first',
  LEARN_MORE_HP_SMART_ADVANCE_MODAL: 'learn_more_hp_smart_advance',
  LEARN_MORE_HP_SMART_PRO_MODAL: 'learn_more_hp_smart_pro',
  LEARN_MORE_HP_PLUS_OVERVIEW_SAVE_ON_INK_MODAL:
    'learn_more_hp_plus_overview_save_on_ink',
  RECONSIDER_MODAL: 'reconsider',
  EXIT_HP_CLOUD_CONNECTION: 'exit_hp_cloud_connection'
}
export const CUSTOM_LAYOUTS = [
  GENERIC_MODAL_TYPES.LEARN_MORE_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_OVERVIEW_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_INSTANT_INK_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_REQUIREMENTS_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_SMART_SECURITY_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_FOREST_FIRST_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_SMART_ADVANCE_MODAL,
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_SMART_PRO_MODAL
]
export const LAYOUT_TYPES = {
  CARD_LAYOUT: 'card_layout',
  ACCORDION_LAYOUT: 'accordion_layout'
}
const LAYOUTS = {}
LAYOUTS[LAYOUT_TYPES.CARD_LAYOUT] = CardLayout
LAYOUTS[LAYOUT_TYPES.ACCORDION_LAYOUT] = AccordionLayout

const MODAL_MAP = {}
MODAL_MAP[GENERIC_MODAL_TYPES.PDSN_MODAL] = PdsnModal
MODAL_MAP[GENERIC_MODAL_TYPES.DECLINE_HP_PLUS_MODAL] = DeclineHpPlusModal
MODAL_MAP[GENERIC_MODAL_TYPES.LEARN_MORE_MODAL] = LearnMoreModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_OVERVIEW_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_INSTANT_INK_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_REQUIREMENTS_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_SMART_SECURITY_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_FOREST_FIRST_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_SMART_ADVANCE_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_SMART_PRO_MODAL
] = GenericAccordionModal
MODAL_MAP[
  GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_OVERVIEW_SAVE_ON_INK_MODAL
] = LearnMoreOverviewSaveOnInkModal
MODAL_MAP[GENERIC_MODAL_TYPES.RECONSIDER_MODAL] = ReconsiderModal
MODAL_MAP[GENERIC_MODAL_TYPES.EXIT_HP_CLOUD_CONNECTION] = ExitHpCloudConnection

const hasCustomLayout = (type) => {
  return CUSTOM_LAYOUTS.indexOf(type) > -1
}

const GenericModal = ({ type, visible, setVisibility }) => {
  const [overrideType, setOverrideType] = useState()
  const [customLayout, setCustomLayout] = useState()
  const getModalWrapper = (wrapper) => {
    return MODAL_MAP[wrapper]
  }

  const wrapper = overrideType || type
  const ModalWrapper = getModalWrapper(wrapper)
  const CustomWrapper = LAYOUTS[customLayout]
  const useCustomLayout = hasCustomLayout(type)

  useEffect(() => {
    if (!visible) {
      setOverrideType(type)
    }
  }, [visible, type])

  return (
    <>
      {visible && (
        <ModalWrapper
          setOverride={setOverrideType}
          setCustomLayout={setCustomLayout}
          setVisibility={setVisibility}
          wrapper={wrapper}
        >
          {(props) =>
            customLayout && useCustomLayout ? (
              <CustomWrapper {...props} />
            ) : (
              <Content {...props} />
            )
          }
        </ModalWrapper>
      )}
    </>
  )
}

/** @typedef {import('@veneer/core/dist/scripts/modal').ModalInterface} ModalInterface */

/**
 *
 * @param {array} buttonClicks - Array of button click events, in order on the screen from left to right, top to bottom
 * @param hyperlinkClick
 * @param {() => void} onClose? - Function to enables X close button
 * @param {{ content?: ModalInterface.align, footer?: ModalInterface.alignFooter }} alignment? - Modal alignment
 * @param data
 * @constructor
 */
const Content = ({
  buttonClicks,
  hyperlinkClick,
  data,
  onClose,
  alignment = {
    footer: 'end'
  }
}) => {
  const fireChildAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      hyperlinkClick()
    }
  }
  if (!data) return <></>
  let footer = (
    <>
      {data.buttons.map((b, i) => {
        const isPrimary = b?.primary_button
        const button = isPrimary ? b.primary_button : b.secondary_button
        return (
          <Button
            key={i}
            data-testid={`modal-button-${i}`}
            appearance={
              isPrimary
                ? VENEER_PRIMARY_BUTTON_APPEARANCE
                : VENEER_SECONDARY_BUTTON_APPEARANCE
            }
            onClick={buttonClicks[i]}
          >
            {button.text}
          </Button>
        )
      })}
    </>
  )
  return (
    <Modal
      id="generic-modal"
      data-testid="generic-modal"
      className={classes.modal}
      show={true}
      closeOnBlur={false}
      closeButton={!!onClose}
      onClose={onClose}
      align={alignment.content}
      alignFooter={alignment.footer}
      footer={
        <div className={`vn-button-group--responsive ${classes.footer}`}>
          {footer}
        </div>
      }
    >
      <div>
        <div className={classes.header}>
          {data.header_image?.url && <img src={data.header_image.url} />}
          {data?.header}
        </div>
        <div
          className={classes.body}
          dangerouslySetInnerHTML={{ __html: data?.body }}
          onClick={fireChildAnalyticsEvent}
        />
      </div>
    </Modal>
  )
}

export default GenericModal
