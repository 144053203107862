import React from 'react'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import RadioGroup from '@veneer/core/dist/scripts/radio_group'

import classes from './SelectDomain.module.scss'

export function SelectDomain({
  pageData,
  retry,
  setSelectedOrganization,
  availableOrganizations,
  selectedOrganization
}) {
  const organizations = [
    ...availableOrganizations,
    {
      nodeId: 'new',
      accountName: pageData.new_organization
    }
  ]

  return (
    <RadioGroup
      name="radiobutton_default"
      className={classes.radioButtons}
      key="radio"
      onChange={(e) => setSelectedOrganization(e.target.value)}
    >
      {organizations.map((org) => {
        return (
          <div data-testid={`radio-button-${org.nodeId}`} key={org.nodeId}>
            <RadioButton
              checked={org.nodeId === selectedOrganization}
              className={classes.radioButton}
              key={org.nodeId}
              value={org.nodeId}
              label={org.accountName}
              disabled={retry}
            />
          </div>
        )
      })}
    </RadioGroup>
  )
}
