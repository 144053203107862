import React, { useContext, useEffect } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { PrinterContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'

const { BUTTONS, SCREENS } = ANALYTICS
const DeviceIsPaasError = ({ error, children }) => {
  const { productNumber } = useContext(PrinterContext)

  const errorModalAnalytics = useAnalytics(SCREENS.DEVICE_IS_PAAS_ERROR_MODAL)
  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.VISIT_WEBSITE)
    window.location.href = `https://start.hp.com/${productNumber}`
  }

  useEffect(() => {
    errorModalAnalytics.fireModalWindowDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE,
      actionAuxParams: `error=${error}`
    })
  })

  return <>{children({ primaryClick })}</>
}

export default DeviceIsPaasError
