// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._32Wm9bvfHtSYq6p8w5Oe06{display:flex;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/pages/App/FinishSetup/HPSmartAdminValuePropPage.module.scss"],"names":[],"mappings":"AAAA,yBAAkB,YAAY,CAAC,sBAAsB","sourcesContent":[".contentContainer{display:flex;justify-content:center}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "_32Wm9bvfHtSYq6p8w5Oe06"
};
export default ___CSS_LOADER_EXPORT___;
