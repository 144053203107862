// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vKmToAL_W6G_iQLj1UUVO{margin-top:40px}.vKmToAL_W6G_iQLj1UUVO ._2D-qwkA5Z5m3d44ZMp1VYh{display:flex;justify-content:space-between;margin-top:57px;align-items:flex-end;flex-direction:column}.vKmToAL_W6G_iQLj1UUVO a{text-decoration:underline}@media screen and (max-width: 1279px){.vKmToAL_W6G_iQLj1UUVO{margin-top:16px}}@media screen and (max-width: 767px){.vKmToAL_W6G_iQLj1UUVO button{width:auto !important}}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/AssignOrganizationPage/Templates/AssignOrgWebTemplate/AssignOrgWebTemplate.module.scss"],"names":[],"mappings":"AAAA,uBAAoB,eAAe,CAAC,gDAA6B,YAAY,CAAC,6BAA6B,CAAC,eAAe,CAAC,oBAAoB,CAAC,qBAAqB,CAAC,yBAAsB,yBAAyB,CAAC,sCAAsC,uBAAoB,eAAe,CAAC,CAAC,qCAAqC,8BAA2B,qBAAqB,CAAC","sourcesContent":[".assignOrganization{margin-top:40px}.assignOrganization .buttons{display:flex;justify-content:space-between;margin-top:57px;align-items:flex-end;flex-direction:column}.assignOrganization a{text-decoration:underline}@media screen and (max-width: 1279px){.assignOrganization{margin-top:16px}}@media screen and (max-width: 767px){.assignOrganization button{width:auto !important}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assignOrganization": "vKmToAL_W6G_iQLj1UUVO",
	"buttons": "_2D-qwkA5Z5m3d44ZMp1VYh"
};
export default ___CSS_LOADER_EXPORT___;
