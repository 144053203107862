// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3EFTBCJV2qROWxijffY4Rn{display:flex;flex-direction:column;gap:72px}._3EFTBCJV2qROWxijffY4Rn ._1_A6DLnov6SAk-jci-s5bY{margin-left:8px}._3EFTBCJV2qROWxijffY4Rn ._2vW5okqezSZBUwdiNSCBIb{display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/PartnerLink/components/ContentSectionPartnerLink/ContentSectionPartnerLink.module.scss"],"names":[],"mappings":"AAAA,yBAA2B,YAAY,CAAC,qBAAqB,CAAC,QAAQ,CAAC,kDAA0C,eAAe,CAAC,kDAA4C,YAAY,CAAC,kBAAkB","sourcesContent":[".contentSectionPartnerLink{display:flex;flex-direction:column;gap:72px}.contentSectionPartnerLink .iconCheckmark{margin-left:8px}.contentSectionPartnerLink .headerContainer{display:flex;align-items:center}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentSectionPartnerLink": "_3EFTBCJV2qROWxijffY4Rn",
	"iconCheckmark": "_1_A6DLnov6SAk-jci-s5bY",
	"headerContainer": "_2vW5okqezSZBUwdiNSCBIb"
};
export default ___CSS_LOADER_EXPORT___;
