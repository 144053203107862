// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1efXrtfGbXkvn1FbuwoEkf{margin-top:40px}._1efXrtfGbXkvn1FbuwoEkf a{text-decoration:underline}._1efXrtfGbXkvn1FbuwoEkf ._3-p6NtgV1vcRGKTXZmzB7n{display:flex}._1efXrtfGbXkvn1FbuwoEkf ._3-p6NtgV1vcRGKTXZmzB7n ._2gRzTKsLtID6tCqrHOkiS_{flex:1}._1efXrtfGbXkvn1FbuwoEkf ._3-p6NtgV1vcRGKTXZmzB7n ._2gRzTKsLtID6tCqrHOkiS_ p{max-width:495px}._1efXrtfGbXkvn1FbuwoEkf ._3-p6NtgV1vcRGKTXZmzB7n ._1d1DZkzgKwo9ceyQfZIgNq{width:452px;text-align:center}@media screen and (max-width: 1279px){._1efXrtfGbXkvn1FbuwoEkf{margin-top:16px}}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/AssignOrganizationPage/Templates/AssignOrgAppTemplate/AssignOrgAppTemplate.module.scss"],"names":[],"mappings":"AAAA,yBAAoB,eAAe,CAAC,2BAAsB,yBAAyB,CAAC,kDAAmC,YAAY,CAAC,2EAAgD,MAAM,CAAC,6EAAkD,eAAe,CAAC,2EAAiD,WAAW,CAAC,iBAAiB,CAAC,sCAAsC,yBAAoB,eAAe,CAAC","sourcesContent":[".assignOrganization{margin-top:40px}.assignOrganization a{text-decoration:underline}.assignOrganization .baseContainer{display:flex}.assignOrganization .baseContainer .leftSection{flex:1}.assignOrganization .baseContainer .leftSection p{max-width:495px}.assignOrganization .baseContainer .rightSection{width:452px;text-align:center}@media screen and (max-width: 1279px){.assignOrganization{margin-top:16px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assignOrganization": "_1efXrtfGbXkvn1FbuwoEkf",
	"baseContainer": "_3-p6NtgV1vcRGKTXZmzB7n",
	"leftSection": "_2gRzTKsLtID6tCqrHOkiS_",
	"rightSection": "_1d1DZkzgKwo9ceyQfZIgNq"
};
export default ___CSS_LOADER_EXPORT___;
