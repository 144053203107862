import { useCallback, useContext, useMemo } from 'react'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import { FW_UPDATE_AUTO } from '@/store/Constants'

export function useODOutput() {
  const { sessionContext } = useContext(ConfigContext)
  const { programLevel } = useContext(UserContext)
  const {
    uuid,
    serialNumber,
    cloudId,
    location: deviceLocation,
    name: deviceName,
    bizModel
  } = useContext(PrinterContext)

  const onboardingContextOutput = useCallback(
    () => ({
      onboardingContext: {
        programLevel:
          programLevel || sessionContext?.onboardingContext.programLevel
      }
    }),
    [programLevel, sessionContext]
  )

  const userOutput = useCallback(
    () => ({
      user: {
        selectedBizModel: bizModel
      }
    }),
    [bizModel]
  )

  const deviceOutput = useCallback(
    () => ({
      device: {
        uuid,
        serialNumber,
        cloudId,
        deviceLocation,
        deviceName
      }
    }),
    [uuid, serialNumber, cloudId, deviceLocation, deviceName]
  )

  const firmwareUpdateConfigOutput = useCallback(
    (updateType) => ({
      firmwareUpdateConfig: {
        updateType
      }
    }),
    []
  )

  const shortcutsTenantManagerOutput = useCallback(
    (httpStatusCode, responsePayload) => ({
      shortcutsTenantManager: {
        httpStatusCode,
        responsePayload
      }
    }),
    []
  )

  const shortcutsErrorOutput = useCallback(
    (httpStatusCode, responsePayload) => ({
      ...onboardingContextOutput(),
      ...userOutput(),
      ...deviceOutput(),
      ...firmwareUpdateConfigOutput(FW_UPDATE_AUTO),
      ...shortcutsTenantManagerOutput(httpStatusCode, responsePayload)
    }),
    [
      onboardingContextOutput,
      userOutput,
      deviceOutput,
      firmwareUpdateConfigOutput,
      shortcutsTenantManagerOutput
    ]
  )

  const shortcutsSuccessOutput = useCallback(
    (id) => shortcutsTenantManagerOutput(201, { id }),
    [shortcutsTenantManagerOutput]
  )

  return useMemo(
    () => ({
      shortcutsErrorOutput,
      shortcutsSuccessOutput
    }),
    [shortcutsErrorOutput, shortcutsSuccessOutput]
  )
}
