import { useCallback, useContext, useMemo } from 'react'
import { CDM_DISCOVERY_TREE, LEDM_DISCOVERY_TREE } from '@/store/Constants'
import { DeviceHttpProxyContext } from '@/store'

export function usePrinterDiscoveryServices() {
  const { isCdm, fetch } = useContext(DeviceHttpProxyContext)

  const fetchPrinterServices = useCallback(async () => {
    const path = isCdm ? CDM_DISCOVERY_TREE : LEDM_DISCOVERY_TREE
    return (await fetch({ path, method: 'GET' })).body.data
  }, [fetch, isCdm])

  return useMemo(() => ({ fetchPrinterServices }), [fetchPrinterServices])
}
