import { useState, useRef, useEffect, useContext } from 'react'
import { DeviceRegistrationClient } from '@/clients/DeviceRegistrationClient'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useErrorRegistered from './useErrorRegistered'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import useCookies from 'react-use-cookie'
import { Logger } from '@/utils/Logger'

const useDeviceRegistration = () => {
  const [isClaiming, setIsClaiming] = useState(false)
  const [result, setResult] = useState(null)
  const { authProvider, stack, sessionContext, isScanSetup } = useContext(
    ConfigContext
  )
  const { getSessionId, isCompanyAccount } = useContext(UserContext)
  const printerCtx = useContext(PrinterContext)
  const deviceClient = useRef(null)
  const { onbpReleaseUsermanagementservicev3: useUserMgtV3 } = useFlags()
  const { errorHasBeenRegistered } = useErrorRegistered()
  const [, setNewPrinterAddedCookie] = useCookies('newPrinterAdded')

  const _updateErrorResult = (error) => {
    Logger.error('device claim error:', error)
    setResult({
      status: 'error',
      message: error ? error.message : 'Device claim error'
    })
  }

  const validateDevice = (success) => {
    if (!deviceClient.current || errorHasBeenRegistered.current) return
    setIsClaiming(true)
    if (!printerCtx.claimTimestamp) {
      // There is a discrepancy between ECMAScript epoch and UNIX epoch
      printerCtx.setClaimTimestamp((Date.now() / 1000) | 0)
    }
    Logger.log('calling validateDevice')
    return deviceClient.current
      .validateDevice({
        fingerprint: printerCtx.fingerprint,
        claimPostcard: printerCtx.claimPostcard,
        productNumber: printerCtx.productNumber,
        selectedBizModel: printerCtx.bizModel,
        uuid: printerCtx.uuid
      })
      .then(success)
      .catch((error) => _updateErrorResult(error))
      .finally(() => {
        setIsClaiming(false)
      })
  }

  const registerDevice = (onSuccess = EMPTY_FUNCTION) => {
    if (!deviceClient.current || errorHasBeenRegistered.current) return
    Logger.log('calling registerDevice')
    return deviceClient.current
      .registerDevice({
        fingerprint: printerCtx.fingerprint,
        claimPostcard: printerCtx.claimPostcard,
        productNumber: printerCtx.productNumber,
        selectedBizModel: printerCtx.bizModel,
        uuid: printerCtx.uuid,
        name: printerCtx.name,
        location: printerCtx.location,
        programId: sessionContext.device?.programId
      })
      .then((response) => {
        printerCtx.setCloudId(response.data.cloudId)
        if (isCompanyAccount && !isScanSetup) {
          const newPrinterAddedCookie = {
            cloudID: response.data.cloudId,
            modelName: printerCtx.modelName
          }

          setNewPrinterAddedCookie(JSON.stringify(newPrinterAddedCookie))
        }
        setResult({ status: 'success' })
        onSuccess()
      })
      .catch((error) => _updateErrorResult(error))
      .finally(() => {
        setIsClaiming(false)
      })
  }

  useEffect(() => {
    deviceClient.current = new DeviceRegistrationClient(
      stack,
      authProvider,
      getSessionId(),
      useUserMgtV3
    )
    return () => {
      deviceClient.current = null
    }
  }, [authProvider, stack, getSessionId, useUserMgtV3])

  return {
    isClaiming,
    result,
    validateDevice,
    registerDevice
  }
}

export default useDeviceRegistration
