import React, { useContext } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ConfigContext, UserContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'
import { SelectSupportedLocaleLayout } from './Layouts/SelectSupportedLocaleLayout'

const { BUTTONS, SCREENS } = ANALYTICS

const UnsupportedLocaleError = ({ error, children }) => {
  const { contentStackLanguageSelection } = useContext(UserContext)
  const { isBinaryApp, navigation, setLocale } = useContext(ConfigContext)

  const errorModalAnalytics = useAnalytics(SCREENS.UNSUPPORTED_LOCALE)

  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CONTINUE, {
      detail: contentStackLanguageSelection
    })

    const currentPath = window.location.pathname.split('/').pop()
    const queryParam = window.location.search

    if (isBinaryApp) {
      const [country, language] = contentStackLanguageSelection[0].split('/')
      setLocale(`${language}-${country.toLowerCase()}`)
      navigation.push(
        `/${contentStackLanguageSelection}/${currentPath}${queryParam}`
      )
      return
    }

    window.location.href = `/${contentStackLanguageSelection}/${currentPath}${queryParam}`
  }

  errorModalAnalytics.fireModalWindowDisplayed({
    detail: error,
    name: ANALYTICS.ERROR_CODE
  })

  return <>{children({ primaryClick, Layout: SelectSupportedLocaleLayout })}</>
}

export default UnsupportedLocaleError
