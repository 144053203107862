import { Stack } from '@jarvis/web-stratus-client'
import React from 'react'
import PortalOobe from '../src/components/PortalOobe'
import { ShellProps } from './types/shell'
import packageInfo from '../package.json'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  stack = Stack.pie,
  properties = {
    ldClientId: '60a54de5656c4a0f2ce0bc78'
  }
}) {
  const {
    v1: {
      navigation,
      store,
      localization,
      analytics,
      orgSelector,
      sessionInterface,
      serviceRouting,
      monitoring,
      tenantHandlerInterface
    },
    v2: { authProvider }
  } = window.Shell as ShellProps
  return (
    <section id={packageInfo.name}>
      <PortalOobe
        authProvider={authProvider}
        orgSelector={orgSelector}
        navigation={navigation}
        analytics={analytics}
        sessionInterface={sessionInterface}
        store={store}
        stack={stack}
        localization={localization}
        ldClientId={properties.ldClientId}
        serviceRouting={serviceRouting}
        monitoring={monitoring}
        tenantHandlerInterface={tenantHandlerInterface}
      />
    </section>
  )
}
