import React, { useContext } from 'react'
import { LinkButton } from '@/components/UI/Buttons'
import Button from '@veneer/core/dist/scripts/button'
import { ECP_DOMAIN } from '@/constants/Links'
import { ConfigContext } from '@/store'

import classes from './FinishSetupActionList.module.scss'

function Item({ title, description, button }) {
  return (
    <div className={classes.item}>
      <div className={classes.contentContainer}>
        <h6>{title}</h6>
        <span>{description}</span>
      </div>
      <div className={classes.buttonContainer}>{button}</div>
    </div>
  )
}

export function FinishSetupActionList({
  actionListCopy,
  buttonsCopy,
  partnerLinkAction = undefined
}) {
  const { stack } = useContext(ConfigContext)

  return (
    <div className={classes.finishSetupActionList}>
      <Item
        title={actionListCopy.get_drivers.title}
        description={actionListCopy.get_drivers.description}
        button={
          <LinkButton
            openNewTab
            iconPosition="end"
            href={buttonsCopy.get_drivers.link}
          >
            {buttonsCopy.get_drivers.label}
          </LinkButton>
        }
      />
      {partnerLinkAction && (
        <Item
          title={actionListCopy.partner_link.title}
          description={actionListCopy.partner_link.description}
          button={
            <Button onClick={partnerLinkAction}>
              {buttonsCopy.partner_link.label}
            </Button>
          }
        />
      )}
      <Item
        title={actionListCopy.hp_command_center.title}
        description={actionListCopy.hp_command_center.description}
        button={
          <LinkButton iconPosition="end" href={ECP_DOMAIN[stack]}>
            {buttonsCopy.hp_command_center.label}
          </LinkButton>
        }
      />
    </div>
  )
}
