export const getElementsByTag = (element, tag) =>
  element.getElementsByTagName(tag)

export const getFirstElementByTag = (element, tag) =>
  getElementsByTag(element, tag)[0]

export const getElementTextContent = (element) => element.textContent

export const getFirstTextContentByTag = (element, tag) =>
  getElementTextContent(getFirstElementByTag(element, tag))

export const getAndParseListElementsIntoArray = (element, tag) =>
  Array.from(getElementsByTag(element, tag))

export const getResourceUriElement = (element) =>
  getFirstElementByTag(element, 'dd:ResourceURI')
