import React, { useEffect, useState, useCallback, useRef } from 'react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

const equals = (obj, obj2) => {
  try {
    return JSON.stringify(obj) === JSON.stringify(obj2)
  } catch (_) {
    return false
  }
}

const LaunchDarkly = (props) => {
  const [content, setContent] = useState(<></>)
  const prevPropsRef = useRef()
  useEffect(() => {
    // when component finishes mounting save actual props
    prevPropsRef.current = { ...props }
  })
  const prevProps = prevPropsRef.current
  const getLdConfig = useCallback(async () => {
    if (!equals(prevProps, props)) {
      // provide update only when new props are different
      // eslint-disable-next-line no-unused-vars
      const LDProvider = await asyncWithLDProvider({
        clientSideID: props.clientId
      })
      setContent(<LDProvider>{props.children}</LDProvider>)
    }
  }, [prevProps, props])
  useEffect(() => {
    getLdConfig()
  }, [getLdConfig])
  return content
}
export default LaunchDarkly
