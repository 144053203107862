import { useCallback, useMemo } from 'react'
import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'
import { GET_CONSUMABLE_SUPPLIES_SERVICE } from '@/constants/DeviceHttpProxy'
import {
  getAndParseListElementsIntoArray,
  getFirstElementByTag,
  getFirstTextContentByTag
} from '@/utils/DeviceHttpProxy'

export const CMY_COLOR_CODE = 'CMY'
export const CARTRIDGE_INK_TYPE_LEDM = 'ink'
export const CARTRIDGE_INK_TYPE_CDM = 'inkCartridge'

function isCMYInkSupportedLedm(xml) {
  const productConsumableElement = getFirstElementByTag(
    xml,
    'ccdyn:ProductConsumableInfo'
  )
  const supportedConsumablesElement = getFirstElementByTag(
    productConsumableElement,
    'ccdyn:SupportedConsumables'
  )
  return getAndParseListElementsIntoArray(
    supportedConsumablesElement,
    'ccdyn:SupportedConsumable'
  ).some((supportedConsumableElement) => {
    const consumableType = getFirstTextContentByTag(
      supportedConsumableElement,
      'dd:ConsumableTypeEnum'
    )
    const colorCode = getFirstTextContentByTag(
      supportedConsumableElement,
      'dd:ConsumableLabelCode'
    )
    return (
      consumableType === CARTRIDGE_INK_TYPE_LEDM && colorCode === CMY_COLOR_CODE
    )
  })
}

function isCMYInkSupportedCdm(data) {
  return data.suppliesList.some(({ supplyType, supplyColorCode }) => {
    return (
      supplyType === CARTRIDGE_INK_TYPE_CDM &&
      supplyColorCode === CMY_COLOR_CODE
    )
  })
}

export function usePrinterConsumableSupplies() {
  const { sendRequest, isCdm } = useDeviceHttpProxy()

  const isCMYInkSupported = useCallback(async () => {
    const { body } = await sendRequest(GET_CONSUMABLE_SUPPLIES_SERVICE)
    return isCdm
      ? isCMYInkSupportedCdm(body.data)
      : isCMYInkSupportedLedm(body.data)
  }, [sendRequest, isCdm])

  return useMemo(
    () => ({
      isCMYInkSupported
    }),
    [isCMYInkSupported]
  )
}
