import React, { useContext } from 'react'
import { ErrorContext } from '@/store'

const PartnerLinkError = ({ children }) => {
  /**
   * Continue Without Connecting is a workaround to manipulate the PartnerLinkPage
   * local state Status between not-part components of PartnerLinkPage render chain
   *
   * As both components (PartnerLinkPage and PartnerLinkError) share the same context
   * (ErrorContext), we are leveraging the retry logic to apply the Continue Without
   * Connect logic which will change the PartnerLinkPage local state
   */
  const { resetError, retry: continueWithoutConnecting } = useContext(
    ErrorContext
  )

  /**
   * Close modal
   * This handler should close the modal by resetting the current error
   */
  const primaryClick = () => {
    resetError()
  }

  /**
   * Continue Without Connecting
   * This handler should change the PartnerLinkPage local state Status to success
   * and reset the error message to close the modal
   *
   * It's calling the behavior lambda function passed to the error context on
   * ButtonsPartnerLink component
   */
  const secondaryClick = () => {
    resetError()
    continueWithoutConnecting()
  }

  return <>{children({ primaryClick, secondaryClick })}</>
}

export default PartnerLinkError
