// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PXMMnIItVQqjq8WGt_7f5{height:100%;display:flex;flex-direction:column}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr{display:flex;min-height:375px}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr section{flex-grow:1}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr ._2l_k803T5Rp_syroJ9z0AJ h6{padding:32px 0 20px;font-size:20px;font-weight:400;line-height:28px}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr ._2l_k803T5Rp_syroJ9z0AJ ul{list-style:none}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr ._2l_k803T5Rp_syroJ9z0AJ ul li{display:flex;align-items:center;gap:8px}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr ._2l_k803T5Rp_syroJ9z0AJ ul li+li{margin-top:20px}.PXMMnIItVQqjq8WGt_7f5 ._2QVleS5tajJ7JfLyHb2Ynr ._3I7XI1mERjqSnxcyPRlaxE{display:flex;align-items:center;justify-content:center;padding:0 40px}\n", "",{"version":3,"sources":["webpack://./src/pages/App/ValueProp/LandingPage.module.scss"],"names":[],"mappings":"AAAA,uBAAuB,WAAW,CAAC,YAAY,CAAC,qBAAqB,CAAC,gDAAgC,YAAY,CAAC,gBAAgB,CAAC,wDAAwC,WAAW,CAAC,4EAA6C,mBAAmB,CAAC,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,4EAA6C,eAAe,CAAC,+EAAgD,YAAY,CAAC,kBAAkB,CAAC,OAAO,CAAC,kFAAmD,eAAe,CAAC,yEAA8C,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,cAAc","sourcesContent":[".landingPageSmallville{height:100%;display:flex;flex-direction:column}.landingPageSmallville .content{display:flex;min-height:375px}.landingPageSmallville .content section{flex-grow:1}.landingPageSmallville .content .features h6{padding:32px 0 20px;font-size:20px;font-weight:400;line-height:28px}.landingPageSmallville .content .features ul{list-style:none}.landingPageSmallville .content .features ul li{display:flex;align-items:center;gap:8px}.landingPageSmallville .content .features ul li+li{margin-top:20px}.landingPageSmallville .content .printerImage{display:flex;align-items:center;justify-content:center;padding:0 40px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landingPageSmallville": "PXMMnIItVQqjq8WGt_7f5",
	"content": "_2QVleS5tajJ7JfLyHb2Ynr",
	"features": "_2l_k803T5Rp_syroJ9z0AJ",
	"printerImage": "_3I7XI1mERjqSnxcyPRlaxE"
};
export default ___CSS_LOADER_EXPORT___;
