import React from 'react'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Button from '@veneer/core/dist/scripts/button'
import classes from './NamePrinterAppTemplate.module.scss'
import {
  ButtonsFooter,
  PrinterView,
  PageHeader
} from '@/components/UI/Smallville'
import { VENEER_SECONDARY_BUTTON_APPEARANCE } from '@/store/Constants'

export function NamePrinterAppTemplate({
  pageData,
  enteredName,
  enteredLocation,
  setEnteredName,
  setEnteredLocation,
  skipHandler,
  submitHandler
}) {
  return (
    <>
      <div data-testid="name-printer-page">
        <PageHeader
          header={pageData.header}
          description={pageData.subhead}
        ></PageHeader>
        <div className={classes.content}>
          <div className={classes.inputs}>
            <div className={classes.textContainer}>
              <TextBox
                id="printer-name"
                onChange={setEnteredName}
                className={classes.textBox}
                helperTextVisibility="visible"
                maxLength={32}
                label={pageData.name_label}
                helperText={pageData.name_helper_text}
              />
            </div>
            <div className={classes.textContainer}>
              <TextBox
                id="location"
                onChange={setEnteredLocation}
                className={classes.textBox}
                helperTextVisibility="visible"
                maxLength={32}
                label={pageData.location_label}
                helperText={pageData.location_helper_text}
              />
            </div>
          </div>
          <div className={classes.imageContent}>
            <PrinterView />
          </div>
        </div>
        <ButtonsFooter>
          <Button
            appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
            onClick={skipHandler}
            data-testid="name-printer-skip"
          >
            {pageData.buttons.skip}
          </Button>
          <Button
            disabled={!enteredName && !enteredLocation}
            onClick={submitHandler}
            data-testid="name-printer-continue"
          >
            {pageData.buttons.continue}
          </Button>
        </ButtonsFooter>
      </div>
    </>
  )
}
