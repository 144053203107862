// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a1wiUF-CLhquaWC8PxU_i{display:flex;align-self:flex-start;margin-top:20px;color:#737373}.a1wiUF-CLhquaWC8PxU_i svg{margin-right:9px;width:50px}.a1wiUF-CLhquaWC8PxU_i p{font-size:16px;line-height:24px}@media screen and (max-width: 768px){.a1wiUF-CLhquaWC8PxU_i{margin:9px 0}.a1wiUF-CLhquaWC8PxU_i p{font-size:12px;line-height:20px}}\n", "",{"version":3,"sources":["webpack://./src/components/UI/InfoSection.module.scss"],"names":[],"mappings":"AAAA,uBAAM,YAAY,CAAC,qBAAqB,CAAC,eAAe,CAAC,aAAa,CAAC,2BAAU,gBAAgB,CAAC,UAAU,CAAC,yBAAQ,cAAc,CAAC,gBAAgB,CAAC,qCAAqC,uBAAM,YAAY,CAAC,yBAAQ,cAAc,CAAC,gBAAgB,CAAC","sourcesContent":[".info{display:flex;align-self:flex-start;margin-top:20px;color:#737373}.info svg{margin-right:9px;width:50px}.info p{font-size:16px;line-height:24px}@media screen and (max-width: 768px){.info{margin:9px 0}.info p{font-size:12px;line-height:20px}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "a1wiUF-CLhquaWC8PxU_i"
};
export default ___CSS_LOADER_EXPORT___;
