import React, { useContext, useEffect } from 'react'
import { ConfigContext, UserContext } from '@/store'
import Modal from '@veneer/core/dist/scripts/modal'
import classes from './FirmwareUpdateModal.module.scss'
import { ANALYTICS } from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import Button from '@veneer/core/dist/scripts/button'
import FeaturesList from '@/components/FirmwareUpdate/FeaturesList'
import clsx from 'clsx'

const { BUTTONS, SCREENS } = ANALYTICS

const FirmwareUpdateModal = ({
  copy,
  showModal,
  onClose,
  cancelButtonStyle,
  continueButtonStyle
}) => {
  const { nextStage, isScanSetup, isBinaryApp, isEcpExperience } = useContext(
    ConfigContext
  )
  const { getSessionId } = useContext(UserContext)
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.IRIS_RECONSIDER_MODAL
  )

  const closeClickHandler = () => {
    fireButtonClick(BUTTONS.CLOSE)
    onClose()
  }

  const cancelClickHandler = () => {
    fireButtonClick(BUTTONS.CANCEL_SETUP)
    if (isBinaryApp) {
      onClose()
    } else {
      nextStage({
        result: 'failure',
        errorInfo: {
          errorCode: 'XXXXXXXXXX',
          errorDescription: ''
        },
        xCorrelationId: getSessionId()
      })
    }
  }

  const continueClickHandler = () => {
    fireButtonClick(BUTTONS.CONTINUE_SETUP)
    if (isScanSetup || isBinaryApp || isEcpExperience) {
      onClose()
    } else {
      nextStage({
        result: 'success',
        xCorrelationId: getSessionId()
      })
    }
  }

  useEffect(() => {
    fireScreenDisplayed()
  }, [])

  return (
    <Modal
      show={showModal}
      closeButton
      onClose={closeClickHandler}
      data-testid="firmware-update-modal"
      className={clsx(classes.modalContent, {
        [classes.modalEcp]: isEcpExperience,
        [classes.modalBinaryApp]: isBinaryApp
      })}
    >
      <div className={classes.firmwareUpdateModal}>
        <h1 data-testid="text-header">{copy.modal.header}</h1>
        <div
          data-testid={'modal-content-before-features'}
          dangerouslySetInnerHTML={{
            __html: copy.modal.content_before_features
          }}
        />
        {copy.features.length > 0 && (
          <FeaturesList copy={copy.features} isModal={true} />
        )}
        <div
          data-testid={'modal-content-after-features'}
          dangerouslySetInnerHTML={{
            __html: copy.modal.content_after_features
          }}
        />
        <div className={classes.buttonSection}>
          <Button
            onClick={continueClickHandler}
            data-testid="btn-modal-continue"
            appearance={continueButtonStyle}
          >
            {copy.modal.continue_button}
          </Button>
          <Button
            onClick={cancelClickHandler}
            data-testid="btn-modal-cancel"
            appearance={cancelButtonStyle}
          >
            {copy.modal.cancel_button}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default FirmwareUpdateModal
