// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17cvyNKeueZPzZuD411DMQ{display:flex;flex-direction:column;gap:7px}._17cvyNKeueZPzZuD411DMQ .uXNZQD9CR6_ZL-RJNpPhS{max-width:360px}._17cvyNKeueZPzZuD411DMQ ._18mIWIH8am9HnLqocq7i06{color:#737373}._17cvyNKeueZPzZuD411DMQ ._18mIWIH8am9HnLqocq7i06 svg{color:#585858;margin-right:6px;margin-top:10px;translate:0 4px}@media screen and (max-width: 767px){._17cvyNKeueZPzZuD411DMQ .uXNZQD9CR6_ZL-RJNpPhS{max-width:none}}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/PartnerLink/components/InputsPartnerLink/InputsPartnerLink.module.scss"],"names":[],"mappings":"AAAA,yBAA2B,YAAY,CAAC,qBAAqB,CAAC,OAAO,CAAC,gDAA2C,eAAe,CAAC,kDAA0C,aAAa,CAAC,sDAA8C,aAAa,CAAC,gBAAgB,CAAC,eAAe,CAAC,eAAe,CAAC,qCAAqC,gDAA2C,cAAc,CAAC","sourcesContent":[".partnerLinkInputContainer{display:flex;flex-direction:column;gap:7px}.partnerLinkInputContainer .accountIdInput{max-width:360px}.partnerLinkInputContainer .inputHelpText{color:#737373}.partnerLinkInputContainer .inputHelpText svg{color:#585858;margin-right:6px;margin-top:10px;translate:0 4px}@media screen and (max-width: 767px){.partnerLinkInputContainer .accountIdInput{max-width:none}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerLinkInputContainer": "_17cvyNKeueZPzZuD411DMQ",
	"accountIdInput": "uXNZQD9CR6_ZL-RJNpPhS",
	"inputHelpText": "_18mIWIH8am9HnLqocq7i06"
};
export default ___CSS_LOADER_EXPORT___;
