import React from 'react'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES } from '@/store/Constants'
import { GENERIC_MODAL_TYPES } from './GenericModal'

const LearnMoreOverviewSaveOnInkModal = ({
  children,
  setVisibility,
  setOverride
}) => {
  const { pageData } = useContentStack({
    content_type:
      CONTENT_STACK_TYPES.learn_more_hp_plus_overview_save_on_ink_modal
  })

  const closeClickHandler = () => {
    setVisibility(false)
  }
  const backClickHandler = () => {
    setOverride(GENERIC_MODAL_TYPES.LEARN_MORE_HP_PLUS_OVERVIEW_MODAL)
  }
  return (
    <>
      {children({
        buttonClicks: [closeClickHandler, backClickHandler],
        data: pageData
      })}
    </>
  )
}

export default LearnMoreOverviewSaveOnInkModal
