// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._29_2GnsOLqEH_kZv0YMks0{margin-left:25px;max-width:484px}._29_2GnsOLqEH_kZv0YMks0 ._2o1g-kzfTtMGglpdHQRGKh{display:flex;margin:8px 0 20px;flex-direction:row;align-items:center;font-size:16px;line-height:24px;color:#404040}._29_2GnsOLqEH_kZv0YMks0 ._2o1g-kzfTtMGglpdHQRGKh span{margin:2px 12px 0 6px;align-self:flex-start}._29_2GnsOLqEH_kZv0YMks0 ._3jK8s3h9SEWV45MRMAPuoU,._29_2GnsOLqEH_kZv0YMks0 .-OYx_y1lKSsdMnzgxFnbO{max-width:484px}._29_2GnsOLqEH_kZv0YMks0 ._3jK8s3h9SEWV45MRMAPuoU,._29_2GnsOLqEH_kZv0YMks0 ._2pWn-TUvnFHBnnF7Very1L,._29_2GnsOLqEH_kZv0YMks0 .-OYx_y1lKSsdMnzgxFnbO{margin-bottom:20px}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/AssignOrganizationPage/Components/CreateDomain/CreateDomain.module.scss"],"names":[],"mappings":"AAAA,yBAAqB,gBAAgB,CAAC,eAAe,CAAC,kDAAkC,YAAY,CAAC,iBAAiB,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,cAAc,CAAC,gBAAgB,CAAC,aAAa,CAAC,uDAAuC,qBAAqB,CAAC,qBAAqB,CAAC,kGAA4D,eAAe,CAAC,oJAA0F,kBAAkB","sourcesContent":[".createDomainSection{margin-left:25px;max-width:484px}.createDomainSection .inlineError{display:flex;margin:8px 0 20px;flex-direction:row;align-items:center;font-size:16px;line-height:24px;color:#404040}.createDomainSection .inlineError span{margin:2px 12px 0 6px;align-self:flex-start}.createDomainSection .textArea,.createDomainSection .select{max-width:484px}.createDomainSection .textArea,.createDomainSection .textBox,.createDomainSection .select{margin-bottom:20px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createDomainSection": "_29_2GnsOLqEH_kZv0YMks0",
	"inlineError": "_2o1g-kzfTtMGglpdHQRGKh",
	"textArea": "_3jK8s3h9SEWV45MRMAPuoU",
	"select": "-OYx_y1lKSsdMnzgxFnbO",
	"textBox": "_2pWn-TUvnFHBnnF7Very1L"
};
export default ___CSS_LOADER_EXPORT___;
