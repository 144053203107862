import {
  AssetsProvider,
  JarvisAssetsProvider
} from '@jarvis/web-assets-provider'
import localeToStringsJson from './locale/index'
import { LocaleToStringsJson } from '@jarvis/web-assets-provider/dist/interfaces/LocaleToStringsJson'

class PortalOOBEAssetsProvider extends JarvisAssetsProvider {
  private readonly _localeToStringsJson = null
  constructor(
    localeToStringsJson: LocaleToStringsJson,
    language?: string,
    country?: string
  ) {
    super(localeToStringsJson, language, country)
    this._localeToStringsJson = localeToStringsJson
  }

  getTextTree(key: string) {
    const keys = key.split('.')
    let root = this._localeToStringsJson[this.getLocaleCode()]
    for (key of keys) {
      if (!root[key]) {
        return undefined
      }
      root = root[key]
    }
    return root
  }
}

export default {
  create: (language: string, country: string) => {
    return new PortalOOBEAssetsProvider(
      localeToStringsJson,
      language,
      country
    ) as AssetsProvider
  }
}
