import React, { useEffect, useContext } from 'react'
import useContentStack from '@/hooks/useContentStack'
import {
  ANALYTICS,
  BIZ_MODEL_STANDARD,
  CONTENT_STACK_TYPES,
  STANDARD_PROGRAM_LEVEL
} from '@/store/Constants'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import useAnalytics from '@/hooks/useAnalytics'
const { LINKS, BUTTONS, SCREENS } = ANALYTICS

const PdsnModal = ({ children, setVisibility, setOverride }) => {
  const {
    fireModalWindowDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(SCREENS.PDSN_MODAL)
  const { navigation } = useContext(ConfigContext)
  const { setBizModel } = useContext(PrinterContext)
  const { setProgramLevel } = useContext(UserContext)
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.pdsn_modal
  })

  useEffect(() => {
    fireModalWindowDisplayed()
  }, [])

  const backClickHandler = () => {
    fireButtonClick(BUTTONS.BACK_TO_OFFER_BUTTON)
    setOverride(null)
    setVisibility(false)
  }

  const declineClickHandler = () => {
    fireButtonClick(BUTTONS.OK)
    setBizModel(BIZ_MODEL_STANDARD)
    setProgramLevel(STANDARD_PROGRAM_LEVEL)
    navigation.push('/activating')
  }

  const hyperlinkClick = () => {
    fireHyperLinkClick(LINKS.LEARN_MORE)
  }

  return (
    <>
      {children({
        buttonClicks: [backClickHandler, declineClickHandler],
        hyperlinkClick,
        data: pageData
      })}
    </>
  )
}
export default PdsnModal
