import {
  AccountMgtSvcClient,
  UserMgtSvcClientv3,
  UserTenantMgtSvcClient,
  DeviceShadowApiClient
} from '@jarvis/web-stratus-client'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { Logger } from '@/utils/Logger'

const VALIDATE_DEVICE_RETRY_MILLISECONDS = 10 * 1000

/** Defining JSON schema validation as undefined to prevent errors
 * since it's not handling the right data on web-stratus-client lib
 * check this issue: https://github.azc.ext.hp.com/jarvis/web-stratus-client/issues/352 */
AccountMgtSvcClient.registerDeviceSchema = () => undefined
/** Disabling for both to prevent possible errors until get this
 * issue solved */
AccountMgtSvcClient.validateDeviceSchema = () => undefined

export class DeviceRegistrationClient {
  constructor(stack, authProvider, sessionId, v3 = false) {
    this.userMgtClient = v3
      ? new UserTenantMgtSvcClient(stack, authProvider)
      : new UserMgtSvcClientv3(stack, authProvider)
    this.accountMgtClient = new AccountMgtSvcClient(stack, authProvider)
    this.deviceShadowClient = new DeviceShadowApiClient(stack, authProvider)
    this.sessionId = sessionId
  }

  _reject(error, fallbackCode, prefix, suffix) {
    let _code
    try {
      _code = error.response.data.errors.pop().code
    } catch {
      _code = fallbackCode
    }
    throw new Error(`${prefix}_${error.response.status}_${_code}_${suffix}`)
  }

  async getAccountId(operation) {
    try {
      const response = await this.userMgtClient.getCurrentActiveUser()
      return response.data.tenantResourceId
    } catch (err) {
      this._reject(err, `OP${operation}00003`, 'SU', 'AU')
    }
  }

  _waitForDeviceValidation(accountId, uuid) {
    return this.accountMgtClient
      .validateDevice(accountId, uuid, { 'X-Correlation-ID': this.sessionId })
      .then(async (response) => {
        const isOnline =
          response.data.deviceStatus.connectivityState === 'online'
        if (isOnline) {
          return response
        }
        // Sleep so we don't spam UCDE
        await new Promise((resolve) =>
          setTimeout(resolve, VALIDATE_DEVICE_RETRY_MILLISECONDS)
        )
        return this._waitForDeviceValidation(accountId, uuid)
      })
      .catch((err) => {
        Logger.error('validateDevice error:', err)
        this._reject(err, 'OPVD00002', 'UA', 'VD')
      })
  }

  async validateDevice(device) {
    const accountId = await this.getAccountId('VD')
    return this._waitForDeviceValidation(accountId, device.uuid)
  }

  async _setDeviceConfiguration(cloudId, device) {
    const state = {
      desired: {
        cdmData: {}
      }
    }
    if (device.name) {
      state.desired.cdmData.deviceDescription = device.name
    }
    if (device.location) {
      state.desired.cdmData.deviceLocation = device.location
    }
    await this.deviceShadowClient
      .postSystemConfiguration({
        cloudId,
        data: { state },
        headers: {
          'X-Correlation-ID': this.sessionId
        }
      })
      .catch(EMPTY_FUNCTION)
  }

  async registerDevice(device) {
    const accountId = await this.getAccountId('RD')
    return this.accountMgtClient
      .registerDevice(
        accountId,
        device.claimPostcard,
        device.productNumber,
        device.selectedBizModel,
        device.uuid,
        device.fingerprint,
        device.programId,
        { 'X-Correlation-ID': this.sessionId }
      )
      .then(async (response) => {
        if (response?.data?.cloudId) {
          if (device.name || device.location) {
            await this._setDeviceConfiguration(response.data.cloudId, device)
          }
          return response
        }
        const error = new Error()
        error.response = {
          status: response.status,
          data: { errors: [{ code: 'OPRD00001' }] }
        }
        throw error
      })
      .catch((err) => {
        Logger.error('registerDevice error:', err)
        this._reject(err, 'OPRD00002', 'UA', 'RD')
      })
  }
}
