import React, { useContext, useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { ConfigContext } from '@/store'
import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  FW_UPDATE_AUTO,
  FW_UPDATE_NOTIFY,
  CONTENT_STACK_TYPES,
  SPLUNK_RUM_FIELDS,
  SPLUNK_RUM_RUN_TIME_WORKFLOWS,
  SPLUNK_RUM_CUSTOM_EVENTS
} from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import NextStageButton from '@/components/UI/NextStageButton'
import RadioGroup from '@veneer/core/dist/scripts/radio_group'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import useContentStack from '@/hooks/useContentStack'
import { useFirmwareUpdateConfig } from '@/hooks/DeviceHttpProxy/useFirmwareUpdateConfig'
import { useSplunkRum } from '@/hooks/useSplunkRum'
import { usePrinterConsumableSupplies } from '@/hooks/DeviceHttpProxy/usePrinterConsumableSupplies'
import { Logger } from '@/utils/Logger'
import classes from './FirmwareUpdateChoice.module.scss'
import { useNextStage } from '@/hooks/useNextStage'
import { CARTRIDGE_TYPE } from '@/constants/ContentStack'

const { BUTTONS, SCREENS, LINKS } = ANALYTICS

const FirmwareUpdateChoicePage = () => {
  const { isBinaryApp, sessionContext, isHpx } = useContext(ConfigContext)
  const { dispatchSuccessStage, dispatchNextStage } = useNextStage()
  const [isEligible, setIsEligible] = useState(false)
  const [selectedRadioButton, setSelectedRadioButton] = useState(null)
  const {
    pageData,
    dataRetrieved,
    currentlyFetching,
    startQuery
  } = useContentStack({ queryOnInit: false })
  const { isCMYInkSupported } = usePrinterConsumableSupplies()

  const fwUpdateAnalytics = useAnalytics(SCREENS.FIRMWARE_UPDATE)
  const fwUpdateChoiceAnalytics = useAnalytics(SCREENS.FIRMWARE_UPDATE_CHOICE)
  const { writeFWUChoiceOnPrinter } = useFirmwareUpdateConfig()
  const { publishSpanEvent } = useSplunkRum(
    SPLUNK_RUM_CUSTOM_EVENTS.FW_UPDATE_CHOICE
  )

  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useMemo(
    () => (isBinaryApp ? fwUpdateChoiceAnalytics : fwUpdateAnalytics),
    [isBinaryApp, fwUpdateChoiceAnalytics, fwUpdateAnalytics]
  )

  useEffect(() => {
    const fetchCmsData = async () => {
      if (!isBinaryApp) {
        startQuery({
          content_type: CONTENT_STACK_TYPES.firmware_update_choice_web
        })
      } else {
        let cartridgeType = CARTRIDGE_TYPE.DEFAULT
        try {
          cartridgeType = (await isCMYInkSupported())
            ? CARTRIDGE_TYPE.CIRCUIT
            : CARTRIDGE_TYPE.CHIP
        } catch (error) {
          Logger.error('Error trying to get the ink type of the printer', error)
        }
        startQuery({
          content_type: CONTENT_STACK_TYPES.firmware_update_choice,
          additional_params: {
            cartridge_type: cartridgeType
          }
        })
      }
    }
    if (!currentlyFetching && !dataRetrieved) {
      fetchCmsData()
    }
  }, [
    currentlyFetching,
    dataRetrieved,
    isBinaryApp,
    isCMYInkSupported,
    startQuery
  ])

  const learnMoreClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(LINKS.LEARN_MORE)
    }
  }

  const radioButtonHandler = (value) => {
    if (value === FW_UPDATE_AUTO) {
      fireButtonClick(BUTTONS.APPLY, { detail: 'AutoUpdate' })
    } else if (value === FW_UPDATE_NOTIFY) {
      fireButtonClick(BUTTONS.APPLY, { detail: 'Notify' })
    }
    setSelectedRadioButton(value)
  }

  useEffect(() => {
    try {
      const bizModel = sessionContext?.user?.selectedBizModel?.toUpperCase?.()
      if (bizModel === BIZ_MODEL_HP_PLUS) {
        dispatchNextStage('ineligible')
        return
      }
      setIsEligible(true)
    } catch (_) {
      setIsEligible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectFirmwareUpdate = async () => {
    fireButtonClick(BUTTONS.APPLY)
    if (isBinaryApp) {
      publishSpanEvent({
        [SPLUNK_RUM_FIELDS.WORKFLOW]:
          selectedRadioButton === FW_UPDATE_AUTO
            ? SPLUNK_RUM_RUN_TIME_WORKFLOWS.FW_UPDATE_AUTO
            : SPLUNK_RUM_RUN_TIME_WORKFLOWS.FW_UPDATE_NOTIFY
      })
      await writeFWUChoiceOnPrinter(selectedRadioButton)
    }
    dispatchSuccessStage({
      firmwareUpdateConfig: {
        updateType: selectedRadioButton
      }
    })
  }

  if ((!isBinaryApp && !isEligible) || !pageData) return <></>

  fireScreenDisplayed()
  return (
    <>
      <div
        className={clsx(classes.firmwareUpdateChoice, {
          [classes.hpx]: isHpx
        })}
        data-testid={`firmware-update-choice${isHpx ? '-hpx' : ''}`}
      >
        <div className={classes.textHeader}>{pageData.header}</div>
        <p
          className={classes.description}
          onClick={learnMoreClickHandler}
          dangerouslySetInnerHTML={{
            __html: pageData.description
          }}
        />
        <div className={classes.radioSection}>
          <RadioGroup
            name="radiobutton_default"
            onChange={(_e, value) => radioButtonHandler(value)}
          >
            <div>
              <RadioButton
                checked={selectedRadioButton === FW_UPDATE_AUTO}
                className={classes.radioButton}
                key="auto_update_button"
                value={FW_UPDATE_AUTO}
                data-testid={FW_UPDATE_AUTO}
                label={
                  <span className={classes.radioButtonLabel}>
                    {pageData.auto_update_option.label}
                  </span>
                }
              />
              <div className={classes.radioText}>
                {pageData.auto_update_option.description}
              </div>
            </div>

            <div>
              <RadioButton
                checked={selectedRadioButton === FW_UPDATE_NOTIFY}
                className={classes.radioButton}
                key="notify_button"
                value={FW_UPDATE_NOTIFY}
                data-testid={FW_UPDATE_NOTIFY}
                label={
                  <span className={classes.radioButtonLabel}>
                    {pageData.notify_option.label}
                  </span>
                }
              />
              <div className={classes.radioText}>
                {pageData.notify_option.description}
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className={classes.footer}>
          <NextStageButton
            appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
            onClick={selectFirmwareUpdate}
            disabled={selectedRadioButton === null}
            dataTestId="btn-apply"
            copy={pageData.apply_button_text}
          />
        </div>
      </div>
    </>
  )
}

export default FirmwareUpdateChoicePage
