import * as Contentstack from 'contentstack'
import { CONTENT_STACK_STACKS } from '@/store/Constants'
import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { Stack } from '@jarvis/web-stratus-client'

let STACK = null

const getCredentials = (stack) => {
  switch (stack) {
    case Stack.stage:
      return CONTENT_STACK_STACKS.stage
    case Stack.prod:
      return CONTENT_STACK_STACKS.prod
    default:
      return CONTENT_STACK_STACKS.pie
  }
}

export const getStack = (env, isLivePreviewEnabled = false) => {
  const { delivery_token, env_name, management_token } = getCredentials(env)
  if (!STACK) {
    STACK = new Contentstack.Stack({
      api_key: CONTENT_STACK_STACKS.api_key,
      delivery_token,
      environment: env_name,
      ...(isLivePreviewEnabled && {
        live_preview: {
          management_token,
          enable: true,
          host: 'api.contentstack.io'
        }
      })
    })
    if (isLivePreviewEnabled) {
      ContentstackLivePreview.init({
        stackSdk: STACK,
        editButton: { enable: false }
      })
    }
  }
  return STACK
}

export const reset = () => {
  STACK = null
}
