import { useCallback, useContext, useEffect, useMemo } from 'react'
import { hashString } from '@/hooks/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ConfigContext, UserContext } from '@/store'
import useCookie from 'react-use-cookie'

export const STATUS = {
  GRANTED: 'GRANTED',
  INVALID: 'INVALID',
  NOT_STARTED: 'NOT_STARTED'
}

export function useDeltaAccessCodeValidation() {
  const { deltaAccessStatus, setDeltaAccessStatus } = useContext(UserContext)
  const [lastDeltaCodeHashCookie, setLastDeltaCodeHashCookie] = useCookie(
    'portal-oobe-last-delta-code-hash'
  )
  const { isScanSetup } = useContext(ConfigContext)

  const {
    onbpReleasePortalOobeDeltaAccessCode: deltaAccessCodeHash,
    onbpReleasePortalOobeSaltString: salt
  } = useFlags()

  const validate = useCallback(
    (code) => {
      const hash = hashString(code, salt)
      if (hash === deltaAccessCodeHash) {
        setDeltaAccessStatus(STATUS.GRANTED)
        setLastDeltaCodeHashCookie(hash)
      } else {
        setDeltaAccessStatus(STATUS.INVALID)
        setLastDeltaCodeHashCookie('')
      }
    },
    [deltaAccessCodeHash, salt, setLastDeltaCodeHashCookie]
  )

  const showDeltaCodePage = useMemo(
    () =>
      deltaAccessCodeHash &&
      isScanSetup &&
      deltaAccessStatus !== STATUS.GRANTED,
    [deltaAccessCodeHash, isScanSetup, deltaAccessStatus]
  )

  useEffect(() => {
    if (
      showDeltaCodePage &&
      lastDeltaCodeHashCookie &&
      deltaAccessStatus === STATUS.NOT_STARTED
    ) {
      if (lastDeltaCodeHashCookie === deltaAccessCodeHash) {
        setDeltaAccessStatus(STATUS.GRANTED)
      } else {
        setLastDeltaCodeHashCookie('')
      }
    }
  }, [
    lastDeltaCodeHashCookie,
    deltaAccessStatus,
    deltaAccessCodeHash,
    showDeltaCodePage,
    setLastDeltaCodeHashCookie
  ])

  return {
    deltaAccessStatus,
    validate,
    showDeltaCodePage
  }
}
