import React, { useMemo } from 'react'
import { FETCH_STATUS } from '@/store/Constants'
import IconCheckmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import { InputsPartnerLink } from '../InputsPartnerLink'

import classes from './ContentSectionPartnerLink.module.scss'

export function ContentSectionPartnerLink({
  pageData,
  status,
  accountId,
  setAccountId
}) {
  const isSuccess = useMemo(() => status === FETCH_STATUS.SUCCESS, [status])

  const header = useMemo(
    () =>
      isSuccess ? (
        <div className={classes.headerContainer}>
          <span>{pageData.finish_setup.header}</span>
          <IconCheckmarkCircle
            filled
            size={40}
            className={classes.iconCheckmark}
            color="hunterGreen7"
          />
        </div>
      ) : (
        pageData.header
      ),
    [pageData, isSuccess]
  )

  const subheaderHtml = useMemo(
    () => (isSuccess ? pageData.finish_setup.subheader : pageData.body),
    [pageData, isSuccess]
  )

  return (
    <div className={classes.contentSectionPartnerLink}>
      <div>
        <TextHeader>{header}</TextHeader>
        <TextSubHeader dangerouslySetInnerHTML={{ __html: subheaderHtml }} />
      </div>
      {!isSuccess && (
        <InputsPartnerLink
          pageData={pageData.inputs}
          status={status}
          setAccountId={setAccountId}
          accountId={accountId}
        />
      )}
    </div>
  )
}
