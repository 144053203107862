import { useCallback, useContext, useMemo } from 'react'
import { PrinterContext } from '@/store'
import useDeviceAuthGrantApi from '@/hooks/useDeviceAuthGrantApi'
import { usePrinterSystemConfig } from '@/hooks/DeviceHttpProxy/usePrinterSystemConfig'

export function usePrinterDetailsApp() {
  const {
    productNumber: localProductNumber,
    setProductNumber,
    deviceCapabilities
  } = useContext(PrinterContext)
  const { handlePrinterDetails } = useDeviceAuthGrantApi()
  const { getProductNumber } = usePrinterSystemConfig()

  const handlePrinterDetailsApp = useCallback(async () => {
    if (!localProductNumber || !deviceCapabilities) {
      const productNumber = await getProductNumber()
      // Reusing handlePrinterDetails, but it should be refactored to follow single responsibility
      await handlePrinterDetails(productNumber)
      setProductNumber(productNumber)
    }
  }, [
    getProductNumber,
    handlePrinterDetails,
    localProductNumber,
    setProductNumber,
    deviceCapabilities
  ])

  return useMemo(() => ({ handlePrinterDetailsApp }), [handlePrinterDetailsApp])
}
