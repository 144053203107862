import { useCallback, useEffect, useRef } from 'react'
import { DataCollectionEventNames, WhenJWebReady } from '@jarvis/jweb-core'
import { Logger } from '@/utils/Logger'
import { publishRumEvent } from '@/utils/splunkRum'

export function useAnalyticsTrackEvent() {
  const subscriberRef = useRef(null)

  useEffect(() => {
    const startSubscriber = async () => {
      try {
        const response = await WhenJWebReady
        const eventService = response?.Plugins?.EventService
        subscriberRef.current = await eventService.createSubscriber()
      } catch (error) {
        Logger.error('Error creating event subscriber:', error)
      }
    }
    startSubscriber()
  }, [])

  const watchEventByTrackingIdentifier = useCallback((trackingIdentifier) => {
    subscriberRef.current && subscriberRef.current.subscribe(
      { eventName: DataCollectionEventNames.cdmEventStatus },
      ({ eventData }) => {
        /**
         * This implementation shouldn't use app-based dependencies (such as contexts and react states)
         * because should run and watch even when the MFE is unloaded
         */
        if (eventData.trackingIdentifier === trackingIdentifier) {
          publishRumEvent(
            'onbp-analytics-tracking-event',
            'AnalyticsTrackingEvent',
            {
              action: eventData.action,
              trackingIdentifier
            }
          )
        }
      }
    )
  }, [])

  return {
    watchEventByTrackingIdentifier
  }
}
