import { useContext, useMemo } from 'react'
import { UserContext } from '@/store'

export const useSplunkRumWithCorrelation = () => {
  const { getSessionId } = useContext(UserContext)

  return useMemo(() => {
    const splunkRumSessionId = window.SplunkRum?.getSessionId?.()
    return (
      getSessionId() + (splunkRumSessionId ? `-[${splunkRumSessionId}]` : '')
    )
  }, [getSessionId])
}
