import React, { useMemo, useState } from 'react'
import TextBox from '@veneer/core/dist/esm/scripts/text_box'
import classes from './DeltaCodePage.module.scss'
import {
  useDeltaAccessCodeValidation,
  STATUS
} from '@/hooks/useDeltaAccessCodeValidation'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES } from '@/store/Constants'

const { INVALID } = STATUS

const DeltaCodePage = () => {
  const [code, setCode] = useState('')

  const { deltaAccessStatus, validate } = useDeltaAccessCodeValidation()

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.delta_access_page
  })

  const codeSubmitHandler = (event) => {
    event.preventDefault()
    validate(code)
  }

  const invalidCode = useMemo(() => deltaAccessStatus === INVALID, [
    deltaAccessStatus
  ])

  return (
    <div data-testid="delta-code-page">
      <form className={classes.container} onSubmit={codeSubmitHandler}>
        <h2>{pageData?.title}</h2>
        <p className={classes.codeSubheader}>{pageData?.subtitle}</p>
        <div className={classes.inputContainer}>
          <TextBox
            className={classes.code}
            error={invalidCode}
            onChange={setCode}
            id="code-text-box"
            data-testid="code-text-box"
            label={pageData?.security_word_label}
            helperText={invalidCode ? pageData?.security_word_help_text : ''}
          />
          <button
            disabled={!code}
            type="submit"
            data-testid="go-button"
            id="go-button"
          >
            {pageData?.go_button}
          </button>
        </div>
      </form>
    </div>
  )
}

export default DeltaCodePage
