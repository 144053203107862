import React from 'react'
import classes from './CreateAccountPage.module.scss'
import { ANALYTICS, CONTENT_STACK_TYPES } from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import NextStageButton from '@/components/UI/NextStageButton'
import useContentStack from '@/hooks/useContentStack'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS

const CreateAccountPage = ({ createAccount, availableOrganizations }) => {
  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(SCREENS.ACCOUNT_CONFIRM)
  fireScreenDisplayed()

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.account_confirm
  })

  const startAccountCreation = () => {
    fireButtonClick(BUTTONS.CONTINUE)
    // Stratus constraints enforce one personal domain per account
    let accountId =
      availableOrganizations.length > 0
        ? availableOrganizations[0].nodeId
        : null
    createAccount({
      accountId: accountId,
      accountName: pageData.personal_account_name
    })
  }

  const fireAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (!closestAnchor || !e.currentTarget.contains(closestAnchor)) return
    if (closestAnchor.href.indexOf('tou') > -1) {
      fireHyperLinkClick(LINKS.HP_SMART_TOU)
    } else if (closestAnchor.href.indexOf('document') > -1) {
      fireHyperLinkClick(LINKS.END_USER_AGREEMENT_LINK)
    } else {
      fireHyperLinkClick(LINKS.LEARN_MORE)
    }
  }

  if (!pageData) return <></>

  return (
    <div className={classes.container} data-testid="create-acct-page">
      <div className={classes.header} data-testid="header">
        {pageData.header}
      </div>
      <div
        data-testid="body"
        onClick={fireAnalyticsEvent}
        dangerouslySetInnerHTML={{
          __html: pageData.body
        }}
      />
      <div className={classes.footer} data-testid="footer">
        <NextStageButton
          copy={pageData.button_text}
          onClick={startAccountCreation}
          disabled={availableOrganizations == null}
        />
      </div>
    </div>
  )
}

export default CreateAccountPage
