import { useContext, useState } from 'react'
import { ConfigContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'
import { mergeDeepLeft } from 'ramda'
import { useSplunkRumWithCorrelation } from './useSplunkRumWithCorrelation'
import eventDetailSchema from '@tests/assets/eventDetailSchema.json'

export const buildDataCollectionMetadata = (
  uuid,
  productNumber,
  xCorrelationId,
  trackingIdentifier
) => {
  return {
    metadata: {
      associatedDeviceUuid: uuid,
      associatedDeviceProductNumber: productNumber,
      xCorrelationId,
      trackingIdentifier
    }
  }
}

const validateEvent = (eventDetail) => {
  const Validator = require('jsonschema').Validator
  const validator = new Validator()
  if (validator.validate(eventDetail, eventDetailSchema).valid) {
    return eventDetail
  }
  return {}
}

const buildEventPayload = (action, activity, control, screen) => {
  const eventDetail = {
    action,
    ...(screen.mode ? { screenMode: screen.mode } : {}),
    screenName: screen.name,
    screenPath: screen.path,
    version: ANALYTICS.VERSION,
    ...(activity ? { activity: activity } : {}),
    ...(control.detail ? { controlDetail: control.detail } : {}),
    ...(control.name ? { controlName: control.name } : {}),
    ...(control.actionAuxParams
      ? { actionAuxParams: control.actionAuxParams }
      : {})
  }
  return {
    dateTime: new Date().toISOString(),
    eventDetailType: ANALYTICS.EVENT_DETAIL_TYPE,
    eventCategory: ANALYTICS.EVENT_CATEGORY,
    version: ANALYTICS.ENVELOPE_VERSION,
    eventDetail: validateEvent(eventDetail)
  }
}

function useAnalytics(screen, checkDone = true) {
  const { publishCdmEvent } = useContext(ConfigContext)
  const xCorrelationId = useSplunkRumWithCorrelation()
  const [done, setDone] = useState(false)
  const checkScreenValidity = () => {
    if (Object.values(ANALYTICS.SCREENS).indexOf(screen) === -1) {
      throw new Error('Invalid screen')
    }
  }

  const { activity, screenPath, screenName } = screen

  const _buildEvent = (action, { name, detail, mode, actionAuxParams }) => {
    if (mode) {
      const modes = Object.values(ANALYTICS.MODES).reduce((items, items1) => {
        return [...Object.values(items), ...Object.values(items1)]
      })
      if (modes.indexOf(mode) === -1) {
        throw new Error('Invalid mode')
      }
    }
    const event = buildEventPayload(
      action,
      activity,
      {
        name,
        detail,
        actionAuxParams
      },
      {
        name: screenName,
        mode,
        path: screenPath
      }
    )
    return event
  }

  const _fire = (
    action,
    { name, detail, mode, actionAuxParams },
    metadata = null
  ) => {
    const defaultMetaData = { xCorrelationId }
    publishCdmEvent(
      _buildEvent(action, { name, detail, mode, actionAuxParams }),
      mergeDeepLeft({ metadata: defaultMetaData }, metadata || {})
    )
  }

  const fireScreenDisplayed = (props = {}, metadata = null) => {
    checkScreenValidity()
    if (checkDone && done && !props.force) return
    setDone(true)
    _fire('ScreenDisplayed', props, metadata)
  }

  const buildButtonClickEvent = (name, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid button')
    }
    return _buildEvent('ControlButtonClicked', { name, ...props })
  }

  const fireBatchEvents = (events, metadata) => {
    publishCdmEvent(events, metadata)
  }

  const fireButtonClick = (name, props = {}) => {
    publishCdmEvent(buildButtonClickEvent(name, props))
  }

  const fireControlDisplayed = (name, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid button')
    }
    _fire('ControlDisplayed', { name, ...props })
  }

  const fireToggleClick = (name, enabled, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.BUTTONS).indexOf(name) === -1) {
      throw new Error('Invalid toggle')
    }
    _fire(enabled ? 'ControlToggleEnabled' : 'ControlToggleDisabled', {
      name,
      ...props
    })
  }

  const fireAccordionClick = (name, expanded, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.ACCORDIONS).indexOf(name) === -1) {
      throw new Error('Invalid accordion')
    }
    _fire(expanded ? 'ControlAccordianExpanded' : 'ControlAccordianCollapsed', {
      name,
      ...props
    })
  }

  const buildHyperLinkClickEvent = (name, props = {}) => {
    checkScreenValidity()
    if (Object.values(ANALYTICS.LINKS).indexOf(name) === -1) {
      throw new Error('Invalid link')
    }
    return _buildEvent('ControlHyperLinkClicked', { name, ...props })
  }

  const fireHyperLinkClick = (name, props = {}) => {
    publishCdmEvent(buildHyperLinkClickEvent(name, props))
  }

  const fireModalWindowDisplayed = (props = {}) => {
    checkScreenValidity()
    _fire('ModalWindowDisplayed', props)
  }

  return {
    buildButtonClickEvent,
    buildHyperLinkClickEvent,
    fireBatchEvents,
    fireScreenDisplayed,
    fireButtonClick,
    fireToggleClick,
    fireHyperLinkClick,
    fireControlDisplayed,
    fireModalWindowDisplayed,
    fireAccordionClick
  }
}

export default useAnalytics
