import React, { useContext, useEffect, useMemo, useState } from 'react'
import useContentStack from '@/hooks/useContentStack'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  RESPONSIVE_TABLET_SIZE,
  VENEER_PRIMARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import { GENERIC_MODAL_TYPES, LAYOUT_TYPES } from './GenericModal'
import classes from './LearnMoreModal.module.scss'
import Card from '@veneer/core/dist/scripts/card'
import Button from '@veneer/core/dist/scripts/button'
import useAnalytics from '@/hooks/useAnalytics'
import Modal from '@veneer/core/dist/scripts/modal'
import { UserContext } from '@/store'
import { getButtonCopy } from '@/hooks/utils'
import { CmsVeneerIcon } from '@/styles/LazyVeneer'

const { BUTTONS, SCREENS } = ANALYTICS

const LearnMoreModal = ({
  children,
  setVisibility,
  setOverride,
  setCustomLayout
}) => {
  const { isPersonalAccount } = useContext(UserContext)
  const learnMoreModalAnalytics = useAnalytics(SCREENS.LEARN_MORE_MODAL)

  const { pageData } = useContentStack({
    content_type: isPersonalAccount
      ? CONTENT_STACK_TYPES.learn_more_modal_personal
      : CONTENT_STACK_TYPES.learn_more_modal_business
  })

  useEffect(() => {
    setCustomLayout(LAYOUT_TYPES.CARD_LAYOUT)
    learnMoreModalAnalytics.fireModalWindowDisplayed()
  }, [])

  const closeClickHandler = () => {
    learnMoreModalAnalytics.fireButtonClick(BUTTONS.CLOSE)
    setVisibility(false)
  }

  const destinationClickHandler = (data) => {
    let cms_type

    Object.keys(GENERIC_MODAL_TYPES)
      .filter(
        (x) => CONTENT_STACK_TYPES[x.toLowerCase()]?.entry_uid === data?.uid
      )
      .map((x) => (cms_type = GENERIC_MODAL_TYPES[x]))

    learnMoreModalAnalytics.fireButtonClick(BUTTONS[cms_type.toUpperCase()])
    setOverride(cms_type)
  }

  if (!pageData) return <></>

  return (
    <>
      {children({
        buttonClicks: [closeClickHandler, destinationClickHandler],
        data: pageData
      })}
    </>
  )
}

const CARD_CLASSES = {
  [CONTENT_STACK_TYPES.learn_more_hp_plus_overview_modal.entry_uid]:
    classes.overviewImage,
  [CONTENT_STACK_TYPES.learn_more_hp_plus_requirements_modal.entry_uid]:
    classes.reqImage,
  [CONTENT_STACK_TYPES.learn_more_instant_ink_modal.entry_uid]:
    classes.instantInkImage,
  [CONTENT_STACK_TYPES.learn_more_hp_smart_pro_modal.entry_uid]:
    classes.proImage
}

const buildCardContent = (data, imageStyles, veneerImageSize) => {
  return (
    <div className={classes.cardContent}>
      {data?.veneer_icon?.icon ? (
        <div
          className={classes.veneerIcons}
          data-testid={`image-${data?.text
            .toLowerCase()
            .replace(/\s/g, '-')}-${veneerImageSize}`}
        >
          <CmsVeneerIcon
            icon={data?.veneer_icon?.icon}
            size={veneerImageSize}
          />
        </div>
      ) : (
        <img
          className={imageStyles}
          src={data?.image?.url}
          data-testid={`image-${data?.text.toLowerCase().replace(/\s/g, '-')}`}
        />
      )}
      <div className={classes.text}> {data?.text} </div>
    </div>
  )
}

export const CardLayout = ({ buttonClicks, data }) => {
  const [closeClickHandler, destinationClickHandler] = buttonClicks
  const { primaryButtonCopy } = useMemo(() => getButtonCopy(data), [data])
  const [veneerImageSize, setVeneerImageSize] = useState(
    document.documentElement.clientWidth > RESPONSIVE_TABLET_SIZE ? 48 : 34
  )

  useEffect(() => {
    const handleResize = () => {
      setVeneerImageSize(
        document.documentElement.clientWidth > RESPONSIVE_TABLET_SIZE ? 48 : 34
      )
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Modal
      id="generic-modal"
      data-testid="generic-modal"
      className={classes.learnMoreModal}
      show={true}
      closeOnBlur={false}
      footer={
        <div className={classes.closeButtonContainer}>
          <Button
            appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
            data-testid="close-button"
            onClick={closeClickHandler}
            className={classes.closeButton}
          >
            {primaryButtonCopy}
          </Button>
        </div>
      }
      alignFooter="end"
    >
      <div className={classes.header}>{data?.header}</div>
      <div className={classes.cardsContainer}>
        {data?.card.map((card, i) => {
          return (
            <Card
              className={classes.card}
              data-testid={'generic-card-' + i}
              key={card?.text}
              content={buildCardContent(
                card,
                CARD_CLASSES[card?.destination[0]?.uid] || classes.otherImages,
                veneerImageSize
              )}
              onClick={() => destinationClickHandler(card?.destination[0])}
            />
          )
        })}
      </div>
    </Modal>
  )
}

export default LearnMoreModal
