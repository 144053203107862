import React from 'react'

import classes from './TextHeader.module.scss'
import clsx from 'clsx'

export const TextSubHeader = (props) => {
  return (
    <div
      {...props}
      className={clsx(classes.subHeader, props.className)}
      data-testid="text-subheader"
    />
  )
}

const TextHeader = (props) => {  
  return (
    <div 
      {...props}
      className={clsx(classes.header, props.className)}
      data-testid="text-header"
    />
  )
}

export default TextHeader
