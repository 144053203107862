import React, { useContext } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import { ISO_VALUES, ANALYTICS } from '@/store/Constants'
import classes from './SupportedCountriesModal.module.scss'
import { ConfigContext } from '@/store'
import useAnalytics from '@/hooks/useAnalytics'
const { LINKS, SCREENS } = ANALYTICS

const SupportedCountriesModal = ({
  onClose,
  analyticsScreen = SCREENS.REQUIREMENTS_COUNTRY_SUPPORTED_LIST
}) => {
  const countryListAnalytics = useAnalytics(analyticsScreen)
  const { getTextTree, hpPlusCountries } = useContext(ConfigContext)
  const pageCopy = getTextTree(
    'pages.assign_organization.supported_countries_modal'
  )
  const sortedCountries = hpPlusCountries
    ? hpPlusCountries.map((locale) => ISO_VALUES[locale]).sort()
    : []

  const hpSupportClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      countryListAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
    }
  }

  return (
    <Modal
      show={true}
      onClose={onClose}
      title={pageCopy.header}
      closeButton={true}
    >
      <div
        className={classes.supportedCountriesBody}
        data-testid="supported-countries-modal"
      >
        <p
          onClick={hpSupportClickHandler}
          dangerouslySetInnerHTML={{
            __html: pageCopy.body
          }}
        />
        <ul data-testid="supported-countries-list">
          {sortedCountries.map((country) => {
            return <li key={country}>{country}</li>
          })}
        </ul>
      </div>
    </Modal>
  )
}
export default SupportedCountriesModal
