import React, { useContext } from 'react'
import { PrinterContext } from '@/store'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES } from '@/store/Constants'
import { FinishSetupActionList } from '@/components/UI/ECP'
import { useNextStage } from '@/hooks/useNextStage'
import classes from './FinishSetupPage.module.scss'
import { IconCheckmarkCircle } from '@veneer/core/dist/scripts/icons'

const FinishSetupEcpPage = () => {
  const { isPartnerLinkSupported } = useContext(PrinterContext)
  const { dispatchSuccessStage } = useNextStage()

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.finish_setup_ecp
  })

  if (!pageData) return <></>

  return (
    <div
      data-testid="finish-setup-page-for-ecp"
      className={classes.finishSetupEcp}
    >
      <div>
        <TextHeader>
          <div className={classes.headerContainer}>
            <span>{pageData.header}</span>
            <IconCheckmarkCircle
              filled
              size={40}
              className={classes.iconCheckmark}
              color="hunterGreen7"
            />
          </div>
        </TextHeader>
        <TextSubHeader>{pageData.subheader}</TextSubHeader>
      </div>
      <FinishSetupActionList
        partnerLinkAction={
          isPartnerLinkSupported ? dispatchSuccessStage : undefined
        }
        actionListCopy={pageData.action_list}
        buttonsCopy={pageData.buttons}
      />
    </div>
  )
}

export default FinishSetupEcpPage
