import React, { useContext, useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classes from './InstallPrintingSoftwarePage.module.scss'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  VENEER_TERTIARY_BUTTON_APPEARANCE,
  HUB_NAVIGATION_SERVICE
} from '@/store/Constants'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'
import useAnalytics from '@/hooks/useAnalytics'
import { PrinterContext, UserContext, ConfigContext } from '@/store'
import {
  changeHref,
  clipboardAPICopyCommand,
  fallbackCopyCommand
} from '@/utils/Functions'
import { Logger } from '@/utils/Logger'

const InstallPrintingSoftwarePage = () => {
  const { modelName, oid } = useContext(PrinterContext)
  const { organization } = useContext(UserContext)
  const { country, language } = useContext(ConfigContext)
  const { BUTTONS, LINKS, SCREENS } = ANALYTICS
  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(SCREENS.INSTALL_PRINTING_SOFTWARE)
  const { onbpReleaseLfpUsehubnavigation: useHubNavigationService } = useFlags()

  const parseCmsData = (data) => {
    let parsedData = {
      ...data,
      ...data.headers,
      primary_subheader: data.headers.primary_subheader.replace(
        '[[printer_name]]',
        modelName
      ),
      template_text: data.template_text
        .replace('[[company]]', organization)
        .replace('[[printer_name]]', modelName)
    }

    if (useHubNavigationService) {
      const newUrl = `${HUB_NAVIGATION_SERVICE}&h_product=${oid}&h_lang=${language}&h_cc=${country.toLowerCase()}`
      const alteredFooter = changeHref(parsedData.footer, newUrl)
      const alteredTemplateText = changeHref(parsedData.template_text, newUrl)
      parsedData = {
        ...parsedData,
        footer: alteredFooter,
        template_text: alteredTemplateText
      }
    }

    return parsedData
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.install_printing_software,
    parsing_function: parseCmsData
  })

  const copyHandler = () => {
    fireButtonClick(BUTTONS.COPY_TEMPLATE)

    try {
      if (navigator.clipboard && navigator.clipboard.write) {
        // Modern browsers supporting full Clipboard API and secure contexts only (HTTPS)
        clipboardAPICopyCommand(pageData.template_text)
      } else {
        // Fallback for browsers with limited Clipboard API support and for local (HTTP) testing
        fallbackCopyCommand(pageData.template_text)
      }
    } catch (error) {
      Logger.error('Error copying to clipboard: ', error)
    }
  }
  const downloadHpDriverClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(LINKS.HP_DRIVER_LINK)
    }
  }

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div
      className={classes.installPrintingSoftware}
      data-testid="install-printing-software-page"
    >
      <div className={classes.primaryHeader}>{pageData.primary_header}</div>
      <div className={classes.primarySubheader}>
        {pageData.primary_subheader}
      </div>
      <div className={classes.secondaryHeader}>
        <img src={pageData.secondary_header_image.url} />
        <p dangerouslySetInnerHTML={{ __html: pageData.secondary_header }} />
      </div>
      <div className={classes.secondarySubheader}>
        {pageData.secondary_subheader}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          appearance={VENEER_TERTIARY_BUTTON_APPEARANCE}
          data-testid="copy-btn"
          onClick={copyHandler}
        >
          <span>
            <img src={pageData.primary_button_image.url} />
            <p dangerouslySetInnerHTML={{ __html: pageData.primary_button }} />
          </span>
        </Button>
      </div>
      <div className={classes.templateContainer}>
        <p
          onClick={downloadHpDriverClickHandler}
          data-testid={'template-text'}
          dangerouslySetInnerHTML={{
            __html: pageData.template_text
          }}
        />
      </div>
      <div
        data-testid={'footer'}
        className={classes.footer}
        onClick={downloadHpDriverClickHandler}
        dangerouslySetInnerHTML={{ __html: pageData.footer }}
      />
    </div>
  )
}

export default InstallPrintingSoftwarePage
