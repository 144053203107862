import React, { useMemo } from 'react'

import TextBox from '@veneer/core/dist/esm/scripts/text_box/text_box'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { FETCH_STATUS } from '@/store/Constants'

import classes from './InputsPartnerLink.module.scss'

export function InputsPartnerLink({
  pageData,
  setAccountId,
  status,
  accountId
}) {
  const isLoading = useMemo(() => status === FETCH_STATUS.LOADING, [status])

  const inputHandle = (value) => {
    setAccountId(value)
  }

  return (
    <div className={classes.partnerLinkInputContainer}>
      <TextBox
        id="partner-link"
        className={classes.accountIdInput}
        label={pageData.input_label}
        onChange={inputHandle}
        maxLength={50}
        disabled={isLoading}
        required={true}
        value={accountId}
      />
      <span className={classes.inputHelpText}>
        <IconInfo size={20} />
        {pageData.helper_label}
      </span>
    </div>
  )
}
