import { useCallback, useMemo } from 'react'
import { useDeviceHttpProxy } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxy'
import { GET_SYSTEM_CONFIG_SERVICE } from '@/constants/DeviceHttpProxy'
import { getFirstTextContentByTag } from '@/utils/DeviceHttpProxy'

function getLedmProductNumberFromXml(xml) {
  return getFirstTextContentByTag(xml, 'dd:ProductNumber')
}

export function usePrinterSystemConfig() {
  const { sendRequest, isCdm } = useDeviceHttpProxy()

  const getProductNumber = useCallback(async () => {
    const {
      body: { data }
    } = await sendRequest(GET_SYSTEM_CONFIG_SERVICE)

    return isCdm ? data.productNumber : getLedmProductNumberFromXml(data)
  }, [sendRequest, isCdm])

  return useMemo(() => ({ getProductNumber }), [getProductNumber])
}
