import { useCallback, useContext, useMemo } from 'react'
import { DeviceHttpProxyContext } from '@/store'
import { usePrinterDiscoveryServices } from './usePrinterDiscoveryServices'

export function useDeviceHttpProxyInstance() {
  const {
    initializationController: {
      getInitInstance,
      unsubscribeInitInstance,
      subscribeInitInstance,
      isJarvisPluginReady
    },
    resourceController: { getPrinterServices, setPrinterServices }
  } = useContext(DeviceHttpProxyContext)

  const { fetchPrinterServices } = usePrinterDiscoveryServices()

  const initializeDhp = useCallback(async () => {
    setPrinterServices(await fetchPrinterServices())
  }, [setPrinterServices, fetchPrinterServices])

  /**
   * This method guarantees Device HTTP Proxy Jarvis plugin is initialized as well required
   * FW resources like services discovery are retrieved ahead of calling FW APIs
   */
  const isDeviceHttpProxyReady = useCallback(async () => {
    await isJarvisPluginReady()
    const initializationInstance = getInitInstance()
    /** Initialization logic should only run if it has not been instantiated yet as well as
     * required FW resources were not yet retrieved */
    if (!initializationInstance && !getPrinterServices()) {
      subscribeInitInstance(initializeDhp())
    }
    return unsubscribeInitInstance()
  }, [
    unsubscribeInitInstance,
    getInitInstance,
    initializeDhp,
    subscribeInitInstance,
    getPrinterServices,
    isJarvisPluginReady
  ])

  return useMemo(() => ({ isDeviceHttpProxyReady }), [isDeviceHttpProxyReady])
}
