// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1QjE_iNdr-B3p15XYdB7jC .MAEt00QZWmM4h2TQwDK1X{margin-top:40px;margin-bottom:72px}._1QjE_iNdr-B3p15XYdB7jC .MAEt00QZWmM4h2TQwDK1X ._11_ows96sM0pSUwrqFjECt{height:102px}._1QjE_iNdr-B3p15XYdB7jC .MAEt00QZWmM4h2TQwDK1X ._3DuyLKHko_L3LyHUWr9XeX{max-width:360px}@media screen and (max-width: 1279px){._1QjE_iNdr-B3p15XYdB7jC .MAEt00QZWmM4h2TQwDK1X{margin-top:16px}}.VXxbvkh-6NJBZRzBOj_6T{display:flex;justify-content:flex-end}.VXxbvkh-6NJBZRzBOj_6T button:first-of-type{min-width:80px;margin-right:12px}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/Activation/NamePrinterPage/Templates/NamePrinterWebTemplate/NamePrinterWebTemplate.module.scss"],"names":[],"mappings":"AAAA,gDAAiB,eAAe,CAAC,kBAAkB,CAAC,yEAAgC,YAAY,CAAC,yEAA0B,eAAe,CAAC,sCAAsC,gDAAiB,eAAe,CAAC,CAAC,uBAAS,YAAY,CAAC,wBAAwB,CAAC,4CAA8B,cAAc,CAAC,iBAAiB","sourcesContent":[".content .inputs{margin-top:40px;margin-bottom:72px}.content .inputs .textContainer{height:102px}.content .inputs .textBox{max-width:360px}@media screen and (max-width: 1279px){.content .inputs{margin-top:16px}}.buttons{display:flex;justify-content:flex-end}.buttons button:first-of-type{min-width:80px;margin-right:12px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_1QjE_iNdr-B3p15XYdB7jC",
	"inputs": "MAEt00QZWmM4h2TQwDK1X",
	"textContainer": "_11_ows96sM0pSUwrqFjECt",
	"textBox": "_3DuyLKHko_L3LyHUWr9XeX",
	"buttons": "VXxbvkh-6NJBZRzBOj_6T"
};
export default ___CSS_LOADER_EXPORT___;
