import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ConfigContext, SplunkRumContext } from '@/store'
import { SPLUNK_RUM_CUSTOM_EVENTS } from '@/store/Constants'

/** @typedef {{ end: () => void }} Span */

const CUSTOM_EVENTS_KEYS = Object.keys(SPLUNK_RUM_CUSTOM_EVENTS)

function getSpanIdByOptions(spanOptions) {
  return CUSTOM_EVENTS_KEYS.find(
    (key) => spanOptions === SPLUNK_RUM_CUSTOM_EVENTS[key]
  )
}

/**
 * @param {object} spanOptions Span options set up on SPLUNK_RUM_CUSTOM_EVENTS
 *
 * @return {{ span?: Span, startSpan: (attributes?: {}) => Span }}
 */
export function useSplunkRum(spanOptions) {
  const { sessionContext } = useContext(ConfigContext)
  const { spans, startSpan: startSpanWithId } = useContext(SplunkRumContext)
  const [span, setSpan] = useState()
  const spanId = useMemo(() => getSpanIdByOptions(spanOptions), [spanOptions])

  const startSpan = useCallback(
    (spanAttributes) => {
      const { moduleName } = spanOptions
      const moduleNameFormatted = moduleName
        ? moduleName.replace(
            '$currentServiceId',
            sessionContext?.currentServiceId
          )
        : sessionContext?.currentServiceId
      const span = startSpanWithId(
        spanId,
        { ...spanOptions, moduleName: moduleNameFormatted },
        spanAttributes
      )
      setSpan(span)
      return span
    },
    [startSpanWithId, spanId, spanOptions, sessionContext]
  )

  const publishSpanEvent = useCallback(
    (attrs) => {
      startSpan(attrs).end()
    },
    [startSpan]
  )

  useEffect(() => {
    if (!span && spans.has(spanId)) {
      setSpan(spans.get(spanId))
    }
  }, [span, spanId, spans])

  return useMemo(() => ({ span, startSpan, publishSpanEvent }), [
    startSpan,
    span,
    publishSpanEvent
  ])
}
