import React, { useState, useEffect, Suspense, useContext } from 'react'
import { IconMinusCircle } from '@/styles/LazyVeneer'
import classes from './PairingCode.module.scss'
import { ErrorContext } from '@/store'

const PERMITTED_CHARACTER_PATTERN = /[^AEIOUY_\W\d]/

const focusSibling = (index, next = true) => {
  const modifier = next ? 1 : -1
  const sibling = document.querySelector(
    `#pairing-code-${parseInt(index, 10) + modifier}`
  )

  if (sibling !== null) {
    sibling.focus()
    sibling.select()
  }
}

const PairingCode = (props) => {
  const { onCodeEmpty, onCodeChange, setCodeComplete } = props
  const codeLength = parseInt(props.codeLength)
  const defaultCode = {}
  for (let i = 0; i < codeLength; i++) {
    defaultCode[i] = ''
  }
  const [code, setCode] = useState(props.code || defaultCode)
  const [codeAsString, setCodeAsString] = useState(props.code || '')
  const [lastCode, setLastCode] = useState(props.code || '')
  const [haveDeletedEntry, setHaveDeletedEntry] = useState(false)
  const { clearPairingCode, setClearPairingCode } = useContext(ErrorContext)

  useEffect(() => {
    if (clearPairingCode) {
      setCode(new Array(8).fill(''))
      setClearPairingCode(false)
    }
  }, [clearPairingCode])

  useEffect(() => {
    if (codeAsString.length === 0) {
      onCodeEmpty()
    }
  }, [codeAsString, onCodeEmpty])

  useEffect(() => {
    const mappedCode = Object.values(code).join('').trim()
    if (lastCode !== mappedCode) {
      setCodeComplete(mappedCode.length === codeLength)
      setCodeAsString(mappedCode)
      onCodeChange(mappedCode)
      setLastCode(mappedCode)
    }
  }, [lastCode, code, codeLength, setCodeComplete, onCodeChange])

  const deleteEntry = (index) => {
    setCode((prevCode) => {
      const newCode = { ...prevCode }
      newCode[index] = ''
      setHaveDeletedEntry(true)
      return newCode
    })
  }

  const onChangeHandler = (index, event) => {
    const value = event.target.value

    if (
      !haveDeletedEntry &&
      !value.toString().toUpperCase().match(PERMITTED_CHARACTER_PATTERN)
    ) {
      deleteEntry(index)
      return
    }

    if (value.trim().length === 1) {
      setCode((prevCode) => {
        const newCode = { ...prevCode }
        newCode[index] = value.toString().toUpperCase()
        return newCode
      })
      focusSibling(index)
    }
  }

  const keyDownHandler = (event) => {
    setHaveDeletedEntry(false)
    const key = event.key.toString().toUpperCase()
    if (key !== 'BACKSPACE' && !key.match(PERMITTED_CHARACTER_PATTERN)) {
      event.preventDefault()
    }
  }

  const keyUpHandler = (index, event) => {
    // Backspace handling
    if (
      event.key.toString().toUpperCase() === 'BACKSPACE' &&
      !haveDeletedEntry
    ) {
      // Backspace from a field with a value. Delete that value
      if (code[index].length > 0) {
        deleteEntry(index)
        return
      }
      // Backspace from a field with no value, delete the previous
      focusSibling(index, false)
      deleteEntry(index - 1)
    }
  }

  const onPasteHandler = (index, event) => {
    event.preventDefault()
    const characters = event.clipboardData
      .getData('Text')
      .replace(/\s/g, '')
      .toUpperCase()
      .split('')
    let refIndex = index
    for (const character of characters) {
      if (
        character.match(PERMITTED_CHARACTER_PATTERN) &&
        refIndex < codeLength
      ) {
        code[refIndex] = character
        refIndex++
      }
    }
    focusSibling(refIndex - 1, true)
    setCode({ ...code })
  }

  const pairingCodes = []
  for (let i = 0; i < codeLength; i++) {
    pairingCodes.push(
      <input
        className={`${props.error ? classes.invalid : ''}`}
        onChange={onChangeHandler.bind(null, i)}
        onKeyUp={keyUpHandler.bind(null, i)}
        onKeyDown={keyDownHandler}
        onPaste={onPasteHandler.bind(null, i)}
        maxLength="1"
        value={code[i]}
        type="text"
        id={`pairing-code-${i}`}
        key={`code-${i}`}
        autoComplete="off"
      />
    )
  }

  return (
    <>
      <div className={classes.pairingCodes}>
        <p>{props.codeTitle}</p>
        <div className={classes.inputs}>{pairingCodes}</div>
      </div>
      {props.error && (
        <div className={classes.error} data-testid="pairing-code-error">
          <Suspense fallback={<></>}>
            <IconMinusCircle filled={true} color="red7" />
          </Suspense>
          <p>{props.errorText}</p>
        </div>
      )}
    </>
  )
}

export default PairingCode
