import React, { Suspense } from 'react'
import { IconInfo } from '@/styles/LazyVeneer'
import classes from './InfoSection.module.scss'

const InfoSection = ({ infoCopy }) => {
  return (
    <div className={classes.info}>
      <Suspense fallback={<></>}>
        <IconInfo />
      </Suspense>
      <p>{infoCopy}</p>
    </div>
  )
}
export default InfoSection
