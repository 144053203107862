// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2dArTc1oEscKFEj3IoMlf_ p{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Modals/SupportedCountriesModal.module.scss"],"names":[],"mappings":"AAAA,2BAA0B,kBAAkB","sourcesContent":[".supportedCountriesBody p{margin-bottom:24px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"supportedCountriesBody": "_2dArTc1oEscKFEj3IoMlf_"
};
export default ___CSS_LOADER_EXPORT___;
