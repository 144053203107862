import React, { Suspense, useContext } from 'react'
import classes from './ActivationBenefits.module.scss'
import { ConfigContext } from '@/store'
import InfoSection from '../UI/InfoSection'
import { CmsVeneerIcon } from '@/styles/LazyVeneer'
import clsx from 'clsx'

const ActivationBenefits = ({ copy }) => {
  const { isEcpExperience, isScanSetup } = useContext(ConfigContext)
  if (isEcpExperience) {
    return (
      <div className={classes.infoSection} data-testid="info-section">
        <InfoSection infoCopy={copy.header} />
      </div>
    )
  }

  const BenefitsSection = ({ items }) => {
    return (
      <Suspense fallback={<></>}>
        <div
          className={clsx(classes.vertical, {
            [classes.fullWidth]: isScanSetup
          })}
        >
          {items.map((benefit, index) => (
            <div key={index}>
              {benefit?.veneer_icon?.icon ? (
                <CmsVeneerIcon icon={benefit?.veneer_icon.icon} size={36} />
              ) : (
                <img src={benefit.image.url} data-testid={`image-${index}`} />
              )}

              {benefit?.title ? (
                <div className={classes.benefitsDetails}>
                  <p
                    className={classes.benefitsTitle}
                    data-testid="benefits-title"
                  >
                    {benefit.title}
                  </p>
                  <p data-testid="benefits-text">{benefit.text}</p>
                </div>
              ) : (
                <p data-testid="benefits-text">{benefit.text}</p>
              )}
            </div>
          ))}
        </div>
      </Suspense>
    )
  }

  return (
    <div className={classes.benefitsSection} data-testid="activation-benefits">
      <h2 data-testid="benefits-header">{copy.header}</h2>
      <div
        className={`${classes.benefits} ${classes.plus}`}
        data-testid="benefits"
      >
        <BenefitsSection items={copy.left_benefits} />
        <BenefitsSection items={copy.right_benefits} />
      </div>
    </div>
  )
}

export default ActivationBenefits
