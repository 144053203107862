import React, { useEffect, useContext } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ConfigContext, UserContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'

const { BUTTONS, SCREENS } = ANALYTICS

const ScanToEmailNotEnabledError = ({ error, children }) => {
  const { nextStage, shortcutsTenantManagerOutput } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)

  const errorModalAnalytics = useAnalytics(SCREENS.SCAN_TO_EMAIL_CREATION_ERROR)

  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CONTINUE)
    nextStage({
      result: 'success',
      output: shortcutsTenantManagerOutput,
      xCorrelationId: getSessionId()
    })
  }

  useEffect(() => {
    errorModalAnalytics.fireModalWindowDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
  }, [])

  return <>{children({ primaryClick })}</>
}

export default ScanToEmailNotEnabledError
