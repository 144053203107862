import { Stack, SubscriptionsApiClient } from '@jarvis/web-stratus-client'

const getPartnerLinkBaseURL = (stack) => {
  switch (stack) {
    case Stack.prod:
      return 'https://cc-gw.hpcloud.hp.com/cors/v1/subscriptions'
    case Stack.stage:
      return 'https://staging-cc-gw.hpcloud.hp.com/cors/v1/subscriptions'
    default:
      return 'https://daily-cc-gw.hpcloud.hp.com/cors/v1/subscriptions'
  }
}

export class PartnerLinkClient extends SubscriptionsApiClient {
  constructor(stack = Stack.pie, authProvider = {}) {
    super(getPartnerLinkBaseURL(stack), authProvider)
    this.defaultBaseURL = getPartnerLinkBaseURL(stack)
  }
}
