import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { ConfigContext } from '@/store/ConfigContext'
import {
  CDM_DEVICE,
  SPLUNK_RUM_CUSTOM_EVENTS,
  SPLUNK_RUM_FIELDS
} from '@/store/Constants'
import { Logger } from '@/utils/Logger'
import { WhenJWebReady } from '@jarvis/jweb-core'
import { parseDhpResponseData } from '@/utils/DeviceHttpProxy'
import { useSplunkRum } from '@/hooks/useSplunkRum'

const XML_HEADER = {
  'Content-Type': 'text/xml'
}

export const DeviceHttpProxyContext = React.createContext({
  isCdm: false,
  fetch: EMPTY_FUNCTION,
  resourceController: {
    getManifest: EMPTY_FUNCTION,
    setManifest: EMPTY_FUNCTION,
    setPrinterServices: EMPTY_FUNCTION,
    getPrinterServices: EMPTY_FUNCTION
  },
  initializationController: {
    subscribeInitInstance: EMPTY_FUNCTION,
    getInitInstance: EMPTY_FUNCTION,
    unsubscribeInitInstance: EMPTY_FUNCTION,
    isJarvisPluginReady: EMPTY_FUNCTION
  }
})

export function DeviceHttpProxyProvider({ children }) {
  const { sessionContext, appSessionId } = useContext(ConfigContext)

  const deviceHttpProxyRef = useRef({
    client: undefined,
    initializationInstance: undefined,
    printerServices: undefined,
    manifests: new Map()
  })

  const { publishSpanEvent } = useSplunkRum(SPLUNK_RUM_CUSTOM_EVENTS.DHP_ERRORS)

  const isCdm = useMemo(
    () => sessionContext?.device?.fwProtocolCapability === CDM_DEVICE,
    [sessionContext]
  )

  const isJarvisPluginReady = useCallback(async () => {
    const { current } = deviceHttpProxyRef
    if (!current.client) {
      const jWebInterface = await WhenJWebReady
      current.client = jWebInterface.Plugins.DeviceHttpProxy
      if (!current.client) {
        throw new Error('JWeb plugin: DeviceHttpProxy not available')
      }
    }
  }, [])

  const fetch = useCallback(
    async (dhpRequestPayload) => {
      Logger.log('Calling DHP', dhpRequestPayload)
      const isLedmOrHybrid = !isCdm
      let response

      try {
        response = await deviceHttpProxyRef.current.client.sendRequest({
          ...dhpRequestPayload,
          sessionId: appSessionId,
          allowUserInteraction: true,
          ...(isLedmOrHybrid && {
            headers: {
              ...dhpRequestPayload?.headers,
              ...XML_HEADER
            }
          })
        })
      } catch (error) {
        publishSpanEvent({
          [SPLUNK_RUM_FIELDS.DHP_PATH]: dhpRequestPayload.path
        })
        Logger.error(
          `DHP fails trying to fetch ${dhpRequestPayload.path}`,
          dhpRequestPayload,
          error
        )
        throw error
      }
      Logger.log('DHP response', dhpRequestPayload, response)

      return parseDhpResponseData(response)
    },
    [isCdm, appSessionId, publishSpanEvent]
  )

  const subscribeInitInstance = useCallback((initializationPromiseInstance) => {
    deviceHttpProxyRef.current.initializationInstance = initializationPromiseInstance
  }, [])

  const getInitInstance = useCallback(
    () => deviceHttpProxyRef.current.initializationInstance,
    []
  )

  const unsubscribeInitInstance = useCallback(async () => {
    if (deviceHttpProxyRef.current.initializationInstance) {
      await deviceHttpProxyRef.current.initializationInstance
      deviceHttpProxyRef.current.initializationInstance = undefined
    }
  }, [])

  const initializationController = useMemo(
    () => ({
      subscribeInitInstance,
      getInitInstance,
      unsubscribeInitInstance,
      isJarvisPluginReady
    }),
    [
      subscribeInitInstance,
      getInitInstance,
      unsubscribeInitInstance,
      isJarvisPluginReady
    ]
  )

  const getManifest = useCallback(
    (resourceType) => deviceHttpProxyRef.current.manifests.get(resourceType),
    []
  )

  const setManifest = useCallback((resourceType, manifest) => {
    deviceHttpProxyRef.current.manifests.set(resourceType, manifest)
  }, [])

  const getPrinterServices = useCallback(
    () => deviceHttpProxyRef.current.printerServices,
    []
  )

  const setPrinterServices = useCallback((printerServices) => {
    deviceHttpProxyRef.current.printerServices = printerServices
  }, [])

  const resourceController = useMemo(
    () => ({
      getManifest,
      setManifest,
      getPrinterServices,
      setPrinterServices
    }),
    [getManifest, setManifest, getPrinterServices, setPrinterServices]
  )

  const dhpContextValue = useMemo(
    () => ({
      isCdm,
      fetch,
      resourceController,
      initializationController
    }),
    [isCdm, fetch, resourceController, initializationController]
  )

  return (
    <DeviceHttpProxyContext.Provider value={dhpContextValue}>
      {children}
    </DeviceHttpProxyContext.Provider>
  )
}
