// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B5drLr8A6CqqyiablWxBZ{display:flex;justify-content:space-between}.B5drLr8A6CqqyiablWxBZ ._2gWEByYz5Aq4eTZtzConb0{flex:1;align-content:center}.B5drLr8A6CqqyiablWxBZ ._2W5wDXtu0R9eF6ncgxijel{display:flex;flex-direction:row;justify-content:flex-end;gap:18px}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/PartnerLink/components/ButtonsPartnerLink/ButtonsPartnerLink.module.scss"],"names":[],"mappings":"AAAA,uBAAQ,YAAY,CAAC,6BAA6B,CAAC,gDAA2B,MAAM,CAAC,oBAAoB,CAAC,gDAAiB,YAAY,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,QAAQ","sourcesContent":[".footer{display:flex;justify-content:space-between}.footer .checkingAccountId{flex:1;align-content:center}.footer .buttons{display:flex;flex-direction:row;justify-content:flex-end;gap:18px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "B5drLr8A6CqqyiablWxBZ",
	"checkingAccountId": "_2gWEByYz5Aq4eTZtzConb0",
	"buttons": "_2W5wDXtu0R9eF6ncgxijel"
};
export default ___CSS_LOADER_EXPORT___;
