import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LaunchDarkly from '@/store/LaunchDarkly'
import {
  ConfigProvider,
  PrinterProvider,
  UserProvider,
  ErrorProvider,
  SplunkRumProvider,
  DeviceHttpProxyProvider
} from '@/store'
import AppWrapper from './AppWrapper'
import { ThemeProvider } from '@veneer/theme'
import {
  getThemeProviderConfig,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

const PortalOobe = ({ monitoring, ldClientId, ...props }) => {
  const { uiBrand } = useMultiUiBrandContext()

  return (
    <Router>
      <ThemeProvider {...getThemeProviderConfig(uiBrand)}>
        <ConfigProvider {...props}>
          <SplunkRumProvider monitoring={monitoring}>
            <LaunchDarkly clientId={ldClientId}>
              <UserProvider>
                <PrinterProvider>
                  <ErrorProvider>
                    <DeviceHttpProxyProvider>
                      <AppWrapper />
                    </DeviceHttpProxyProvider>
                  </ErrorProvider>
                </PrinterProvider>
              </UserProvider>
            </LaunchDarkly>
          </SplunkRumProvider>
        </ConfigProvider>
      </ThemeProvider>
    </Router>
  )
}

export default PortalOobe
