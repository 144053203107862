// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._25beDUeIxlOfmCCtYzW6rA{display:none}._3jIUKgJ5pJm3RgHCoCdLNU{width:21cm;min-height:29.7cm}@media print{._25beDUeIxlOfmCCtYzW6rA{display:block}}\n", "",{"version":3,"sources":["webpack://./src/components/FinishSetup/FirstPrintButton.module.scss"],"names":[],"mappings":"AAAA,yBAAW,YAAY,CAAC,yBAAM,UAAU,CAAC,iBAAiB,CAAC,aAAa,yBAAW,aAAa,CAAC","sourcesContent":[".framePage{display:none}.page{width:21cm;min-height:29.7cm}@media print{.framePage{display:block}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"framePage": "_25beDUeIxlOfmCCtYzW6rA",
	"page": "_3jIUKgJ5pJm3RgHCoCdLNU"
};
export default ___CSS_LOADER_EXPORT___;
