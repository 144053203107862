import { Stack } from '@jarvis/web-stratus-client'
import { JarvisWebHttpClient } from '@jarvis/web-http'

const getStackSuffix = (stack) => {
  switch (stack) {
    case Stack.dev:
      return 'dev'
    case Stack.pie:
      return 'pie'
    case Stack.stage:
      return 'stage'
    default:
      return ''
  }
}

export class PrinterDetailsClient {
  constructor(stack = Stack.pie) {
    const defaultBaseURL = () => {
      return `https://api.hpsmart${getStackSuffix(
        stack
      )}.com/api/2/helper_nonAuth`
    }

    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: defaultBaseURL()
    })
  }

  /**
   * GET printer details
   * @param printerSKU
   * @returns Axios response object
   */
  getPrinterDetails(printerSKU) {
    return this.jarvisWebHttpInstance.get({
      url: `/printer-detail/${printerSKU}`
    })
  }
}
