import React, { useContext, useEffect } from 'react'

import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator/progress_indicator'
import { ConfigContext } from '@/store'

import classes from './LoadingBackdrop.module.scss'

export default function LoadingBackdrop() {
  const { setBackgroundClassName } = useContext(ConfigContext)

  useEffect(() => {
    setBackgroundClassName(classes.backgroundContainer)
    return () => {
      setBackgroundClassName(null)
    }
  }, [])

  return (
    <div
      data-testid="container-loading"
      className={classes.loadingContainer}
      hidden
    >
      <ProgressIndicator
        data-testid="spinner"
        appearance="circular"
        behavior="indeterminate"
      />
    </div>
  )
}
