import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ConfigContext, ErrorContext, STAGES } from '@/store'
import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { getStack } from '@/clients/ContentStackClient'
import { Stack as EnvironmentStack } from '@jarvis/web-stratus-client'
import { mergeDeepLeft } from 'ramda'
import {
  CUSTOM_ERRORS,
  UNABLE_TO_LOAD_PAGE_ERROR,
  UNSUPPORTED_LOCALE_ERROR
} from '@/store/ErrorContext'
import {
  LOCALES_NOT_SUPPORTED_ON_ECP,
  SUPPORTED_LOCALE_SELECTION_ITEMS
} from '@/store/Constants'
import { Logger } from '@/utils/Logger'

const FALLBACK_ISO_COUNTRY = 'en-us'
const ECP_UNSUPPORTED_LOCALE_ERROR = 'unsupported_locale_ecp'
const CONTENTSTACK_ATTRIBUTE_MAP = {
  tenant_type: 'account_type.account_type',
  applicable_products: 'applicable_products',
  printer_type: 'printer_type.printer_type',
  error_type: 'error_type',
  persona: 'persona',
  entry_point_is_activate: 'entry_point_is_activate',
  experience: 'global_experience.experience',
  product_family: 'global_product_family.product_family',
  printer_function_type: 'printer_function_type',
  hp_plus_country: 'hp_plus_country',
  hp_plus_offer_accepted: 'hp_plus_offer_accepted',
  product_flow: 'product_flow',
  stage: 'stage',
  printer_country: 'printer_country',
  entry: 'global_entry.entry',
  cartridge_type: 'cartridge_type'
}

const useContentStack = ({
  content_type,
  parsing_function = (data) => data,
  additional_params = {},
  queryOnInit = true,
  fillMissedFieldsWithFallback = false
}) => {
  const { stack, locale, language, setIsLoading, isEcpExperience } = useContext(
    ConfigContext
  )
  const { onError } = useContext(ErrorContext)
  // If the app is inside an iframe it means CMS is rendering it
  const livePreviewEnabled = useMemo(
    () => stack !== EnvironmentStack.prod && window.self !== window.top,
    [stack]
  )
  const [initialized, setInitialized] = useState(false)
  const Stack = useRef(null)
  const [pageData, setPageData] = useState(null)
  const [dataRetrieved, setDataRetrieved] = useState(false)
  const [currentlyFetching, setCurrentlyFetching] = useState(false)

  const buildQuery = (entry_uid, content_type_uid, additional_params) => {
    let query = Stack.current.ContentType(content_type_uid)
    if (entry_uid) {
      return query.Entry(entry_uid)
    }
    query = query.Query()
    if (additional_params) {
      for (let key of Object.keys(additional_params)) {
        const attr = CONTENTSTACK_ATTRIBUTE_MAP[key]
        if (attr) {
          query = query.where(attr, additional_params[key])
        }
      }
    }
    return query
  }

  const getLanguage = (isFallback, isUnsupportedLocaleError) => {
    if (isFallback) return FALLBACK_ISO_COUNTRY
    if (isUnsupportedLocaleError) {
      const unsupportedLocale = SUPPORTED_LOCALE_SELECTION_ITEMS.find(
        ({ value }) => value.includes(language)
      )

      return unsupportedLocale
        ? unsupportedLocale.value.substring(3) +
            '-' +
            unsupportedLocale.value.substring(0, 2)
        : FALLBACK_ISO_COUNTRY
    }
    return locale
  }

  const doQuery = async (content_type, additional_params) => {
    const { entry_uid, content_type_uid } = content_type
    const query = buildQuery(entry_uid, content_type_uid, additional_params)

    const doRequest = (isFallback) => {
      const language = getLanguage(
        isFallback,
        Object.keys(query).length &&
          additional_params?.error_type === UNSUPPORTED_LOCALE_ERROR
      )

      if (isEcpExperience && LOCALES_NOT_SUPPORTED_ON_ECP.includes(language)) {
        throw Object.assign(new Error('ECP unsupported locale'), {
          error_type: ECP_UNSUPPORTED_LOCALE_ERROR
        })
      }

      const request = query.language(language).toJSON()

      if (entry_uid) {
        // Fetching entries uses a different interface
        return request.fetch()
      } else {
        return request.findOne()
      }
    }

    const fallbackData =
      fillMissedFieldsWithFallback && locale !== FALLBACK_ISO_COUNTRY
        ? await doRequest(true)
        : {}
    const originalData = await doRequest(false)
    return mergeDeepLeft(originalData, fallbackData)
  }

  const startQuery = ({
    content_type,
    parsing_function = (data) => data,
    additional_params
  }) => {
    setIsLoading(true)
    setCurrentlyFetching(true)
    doQuery(content_type, additional_params)
      .then((response) => {
        setDataRetrieved(true)
        setPageData(parsing_function(response))
      })
      .catch((e) => {
        setDataRetrieved(true)
        // 141 = content stack entry does not exist
        if (
          e?.error_code === 141 ||
          e?.error_type === ECP_UNSUPPORTED_LOCALE_ERROR
        ) {
          onError({
            err: `OP_404_${CUSTOM_ERRORS[UNSUPPORTED_LOCALE_ERROR]}_XX`,
            stg: STAGES.generic
          })
        } else if (
          additional_params?.error_type !== UNABLE_TO_LOAD_PAGE_ERROR
        ) {
          onError({
            err: `OP_XXX_${CUSTOM_ERRORS[UNABLE_TO_LOAD_PAGE_ERROR]}_XX`,
            stg: STAGES[UNABLE_TO_LOAD_PAGE_ERROR],
            behavior: startQuery.bind(null, {
              content_type,
              parsing_function,
              additional_params
            })
          })
        }
        Logger.error(
          `Error fetching CMS (content-type: ${content_type.content_type_uid}/${
            content_type.entry_uid || ''
          }, locale: ${locale})`,
          JSON.stringify(additional_params),
          e
        )
      })
      .finally(() => {
        setCurrentlyFetching(false)
        setIsLoading(false)
      })
  }

  const startQueryPreviewWrapper = useCallback(
    (params) => {
      const queryRef = startQuery.bind(null, params)
      queryRef()
      if (livePreviewEnabled) {
        ContentstackLivePreview.onEntryChange(queryRef)
      }
    },
    [livePreviewEnabled]
  )

  const reset = () => {
    setCurrentlyFetching(false)
    setDataRetrieved(false)
    setPageData(null)
  }

  useEffect(() => {
    if (!initialized) {
      Stack.current = getStack(stack, livePreviewEnabled)
      setInitialized(true)
    }
  }, [initialized, stack])

  useEffect(() => {
    if (
      initialized &&
      queryOnInit &&
      !currentlyFetching &&
      !dataRetrieved &&
      content_type
    ) {
      const queryRef = startQuery.bind(null, {
        content_type,
        parsing_function,
        additional_params
      })
      queryRef()
      if (livePreviewEnabled) {
        ContentstackLivePreview.onEntryChange(queryRef)
      }
    }
  }, [
    content_type,
    currentlyFetching,
    dataRetrieved,
    parsing_function,
    initialized,
    queryOnInit,
    additional_params,
    startQuery,
    livePreviewEnabled
  ])

  return {
    pageData,
    dataRetrieved,
    currentlyFetching,
    startQuery: startQueryPreviewWrapper,
    reset
  }
}

export default useContentStack
