// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1pScua4bkZDBFBwNhIS4P-{margin-bottom:0;max-width:500px}._1pScua4bkZDBFBwNhIS4P- .vmZBcrdvSNQthmRxoHJb7{padding-bottom:30px;margin:0}._1pScua4bkZDBFBwNhIS4P- .vmZBcrdvSNQthmRxoHJb7 span:last-of-type{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}._1pScua4bkZDBFBwNhIS4P- .vmZBcrdvSNQthmRxoHJb7:last-of-type{padding-bottom:0;margin-bottom:10px}\n", "",{"version":3,"sources":["webpack://./src/pages/Hybrid/AssignOrganizationPage/Components/SelectDomain/SelectDomain.module.scss"],"names":[],"mappings":"AAAA,yBAAc,eAAe,CAAC,eAAe,CAAC,gDAA2B,mBAAmB,CAAC,QAAQ,CAAC,kEAA6C,kBAAkB,CAAC,eAAe,CAAC,sBAAsB,CAAC,6DAAwC,gBAAgB,CAAC,kBAAkB","sourcesContent":[".radioButtons{margin-bottom:0;max-width:500px}.radioButtons .radioButton{padding-bottom:30px;margin:0}.radioButtons .radioButton span:last-of-type{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.radioButtons .radioButton:last-of-type{padding-bottom:0;margin-bottom:10px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtons": "_1pScua4bkZDBFBwNhIS4P-",
	"radioButton": "vmZBcrdvSNQthmRxoHJb7"
};
export default ___CSS_LOADER_EXPORT___;
