// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OWj53O4zi7G4OPny8ptZ9{font-size:40px;line-height:48px;color:#212121;align-self:flex-start}._2azyUHixTuGQYFdcSIKxOO{margin-top:16px;font-size:16px;line-height:24px;color:#404040;align-self:flex-start}@media screen and (max-width: 767px){.OWj53O4zi7G4OPny8ptZ9{font-size:1.5em}}\n", "",{"version":3,"sources":["webpack://./src/components/UI/TextHeader.module.scss"],"names":[],"mappings":"AAAA,uBAAQ,cAAc,CAAC,gBAAgB,CAAC,aAAa,CAAC,qBAAqB,CAAC,yBAAW,eAAe,CAAC,cAAc,CAAC,gBAAgB,CAAC,aAAa,CAAC,qBAAqB,CAAC,qCAAqC,uBAAQ,eAAe,CAAC","sourcesContent":[".header{font-size:40px;line-height:48px;color:#212121;align-self:flex-start}.subHeader{margin-top:16px;font-size:16px;line-height:24px;color:#404040;align-self:flex-start}@media screen and (max-width: 767px){.header{font-size:1.5em}}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OWj53O4zi7G4OPny8ptZ9",
	"subHeader": "_2azyUHixTuGQYFdcSIKxOO"
};
export default ___CSS_LOADER_EXPORT___;
