import React from 'react'

import classes from './PageHeader.module.scss'

export function PageHeader({ header, description }) {
  return (
    <header className={classes.pageHeader}>
      <h5>{header}</h5>
      <p>{description}</p>
    </header>
  )
}
