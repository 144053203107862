import React, { useContext, useEffect, useState, useMemo } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import clsx from 'clsx'

import useAnalytics from '@/hooks/useAnalytics'
import NextStageButton from '@/components/UI/NextStageButton'
import useContentStack from '@/hooks/useContentStack'
import FirmwareUpdateModal from '@/components/UI/Modals/FirmwareUpdateModal'
import FeaturesList from '@/components/FirmwareUpdate/FeaturesList'
import { ConfigContext, UserContext } from '@/store'
import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  CONTENT_STACK_TYPES,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'

import classes from './FirmwareUpdate.module.scss'

const { BUTTONS, SCREENS, LINKS } = ANALYTICS

const STYLES = {
  default: {
    cancelButtonStyle: VENEER_PRIMARY_BUTTON_APPEARANCE,
    continueButtonStyle: VENEER_PRIMARY_BUTTON_APPEARANCE
  },
  ecp: {
    cancelButtonStyle: VENEER_SECONDARY_BUTTON_APPEARANCE,
    continueButtonStyle: VENEER_PRIMARY_BUTTON_APPEARANCE
  },
  smallville: {
    cancelButtonStyle: VENEER_SECONDARY_BUTTON_APPEARANCE,
    continueButtonStyle: VENEER_SECONDARY_BUTTON_APPEARANCE
  }
}

export const FirmwareUpdatePage = () => {
  const {
    nextStage,
    sessionContext,
    isEcpExperience,
    isScanSetup,
    isBinaryApp
  } = useContext(ConfigContext)
  const { getSessionId, accountType } = useContext(UserContext)
  const [showFirmwareUpdateModal, setShowFirmwareUpdateModal] = useState(false)
  const [isEligible, setIsEligible] = useState(false)
  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(SCREENS.IRIS)

  const showFeaturesList = !isScanSetup && !isBinaryApp && !isEcpExperience

  const { productFamily, entry } = sessionContext?.onboardingContext || {}

  const parseContentStackResponse = (cmsData) => {
    return {
      page: {
        header: cmsData.page.header,
        content: cmsData.page.content,
        cancel_button: cmsData.page.cancel_and_setup_manually_button,
        continue_button: cmsData.page.confirm_and_continue_button
      },
      modal: {
        header: cmsData.modal.header,
        content_before_features: cmsData.modal.content_before_features,
        content_after_features: cmsData.modal.content_after_features,
        cancel_button: cmsData.modal.cancel_online_setup_button,
        continue_button: cmsData.modal.continue_online_setup_button
      },
      features: cmsData.features
    }
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.iris_firmware_update,
    parsing_function: parseContentStackResponse,
    additional_params: {
      tenant_type: accountType,
      product_family: productFamily || null,
      entry: entry
    }
  })

  const continueClickHandler = () => {
    fireButtonClick(BUTTONS.CONFIRM_CONTINUE)
    nextStage({
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  const modalOpenClickHandler = () => {
    fireButtonClick(BUTTONS.CANCEL_SETUP_MANUAL)
    setShowFirmwareUpdateModal(true)
  }

  const modalCloseClickHandler = () => {
    setShowFirmwareUpdateModal(false)
    fireScreenDisplayed({ force: true })
  }

  const learnMoreClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(LINKS.LEARN_MORE)
    }
  }

  const { cancelButtonStyle, continueButtonStyle } = useMemo(() => {
    if (isScanSetup || isBinaryApp) return STYLES.smallville

    if (isEcpExperience) return STYLES.ecp

    return STYLES.default
  }, [isScanSetup, isBinaryApp, isEcpExperience])

  useEffect(() => {
    const bizModel = sessionContext?.onboardingContext?.bizModelHint?.toUpperCase?.()
    if (bizModel === BIZ_MODEL_HP_PLUS) {
      nextStage({
        result: 'ineligible',
        xCorrelationId: getSessionId()
      })
    }
    setIsEligible(true)
    fireScreenDisplayed()
  }, [])

  if (!isEligible || !pageData) return <></>

  return (
    <>
      <div
        className={clsx(classes.firmwareUpdate, {
          [classes.binaryApp]: isBinaryApp
        })}
        data-testid="firmware-update"
      >
        <h1 data-testid="firmware-update-header">{pageData.page.header}</h1>
        <div
          className={classes.pageContent}
          data-testid="page-content"
          onClick={learnMoreClickHandler}
          dangerouslySetInnerHTML={{ __html: pageData.page.content }}
        />
        {showFeaturesList && <FeaturesList copy={pageData.features} />}
        <div
          className={clsx(classes.buttonSection, {
            [classes.ecpExperience]: isEcpExperience,
            [classes.buttonSectionBinaryApp]: isBinaryApp
          })}
        >
          {pageData.page.cancel_button && (
            <Button
              onClick={modalOpenClickHandler}
              data-testid="btn-cancel"
              appearance={cancelButtonStyle}
            >
              {pageData.page.cancel_button}
            </Button>
          )}
          <NextStageButton
            onClick={continueClickHandler}
            dataTestId="btn-continue"
            copy={pageData.page.continue_button}
            appearance={continueButtonStyle}
          />
        </div>
      </div>
      {pageData.page.cancel_button && (
        <FirmwareUpdateModal
          copy={pageData}
          showModal={showFirmwareUpdateModal}
          onClose={modalCloseClickHandler}
          continueButtonStyle={cancelButtonStyle}
          cancelButtonStyle={continueButtonStyle}
        />
      )}
    </>
  )
}

export default FirmwareUpdatePage
