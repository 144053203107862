import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { ConfigContext } from './ConfigContext'
import { PrinterContext } from './PrinterContext'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import useContentStack from '@/hooks/useContentStack'
import {
  CONTENT_STACK_TYPES,
  ECP_EXPERIENCE,
  SPLUNK_RUM_CUSTOM_EVENTS,
  SPLUNK_RUM_FIELDS
} from './Constants'
import {
  getContentStackQueryFormattedPersona,
  getContentStackQueryIsEntryPointActivate
} from '@/hooks/utils'
import { UserContext } from '@/store/UserContext'
import { useSplunkRum } from '@/hooks/useSplunkRum'

const HP_PLUS_KEY = 'hp_plus'
const TRADITIONAL_KEY = 'traditional'
const SINGLE_SKU_KEY = 'single_sku'
const SINGLE_SKU_HP_PLUS_KEY = 'single_sku_hp_plus'
const DEFAULT_KEY = 'default'
const FIVE_MINUTES = 5 * 1000 * 60

export const UNSUPPORTED_LOCALE_ERROR = 'unsupported_locale'
export const UNABLE_TO_LOAD_PAGE_ERROR = 'unable_to_load_page'
export const BIZ_MODEL_MISMATCH_ERROR = 'biz_model_mismatch'

export const CUSTOM_ERRORS = {
  [BIZ_MODEL_MISMATCH_ERROR]: 'OPBM00001',
  duplicated_account: 'EB000U0100',
  partner_link: 'PTLK00001',
  device_is_paas: 'PAAS00001',
  [UNSUPPORTED_LOCALE_ERROR]: 'LINS00001',
  [UNABLE_TO_LOAD_PAGE_ERROR]: 'UTLP00001',
  shortcut_creation_failed: 'SCCF00001'
}

export const STAGES = {
  account: 'account_error',
  pairing: 'pairing_error',
  activation: 'activation_error',
  generic: 'generic_error',
  generic_single_sku: 'generic_error_single_sku',
  [UNABLE_TO_LOAD_PAGE_ERROR]: 'unable_to_load_page_error'
}

const GENERIC_ERROR_MAP = {
  generic_error: {
    is_blocking: true,
    retryable: false
  },
  generic_error_single_sku: {
    is_blocking: true,
    retryable: true
  },
  pairing_error: {
    retryable: true,
    reconsider: true
  },
  activation_error: {
    reconsider: true,
    retryable: false,
    is_blocking: true
  },
  account_error: {
    retryable: true
  }
}

const ERROR_MAP = {
  rate_limit: {
    retryable: false,
    timeout: FIVE_MINUTES,
    errors: ['E0888U0024']
  },
  unsupported_country: {
    retryable: false,
    errors: ['UHPC00001'],
    is_blocking: true
  },
  pairing_code_expired: {
    reconsider: true,
    retryable: true,
    errors: ['E0888U0025'],
    is_blocking: false,
    has_error_list: true
  },
  pairing_code_consumed: {
    retryable: true,
    errors: ['E0888U0023'],
    is_blocking: false,
    has_error_list: true,
    reconsider: true
  },
  duplicated_account: {
    errors: [CUSTOM_ERRORS.duplicated_account]
  },
  biz_model_mismatch: {
    errors: [CUSTOM_ERRORS.biz_model_mismatch]
  },
  partner_link: {
    errors: [CUSTOM_ERRORS.partner_link]
  },
  device_is_paas: {
    errors: [CUSTOM_ERRORS.device_is_paas]
  },
  scan_to_email_not_enabled: {
    errors: [CUSTOM_ERRORS.shortcut_creation_failed]
  },
  [UNSUPPORTED_LOCALE_ERROR]: {
    errors: [CUSTOM_ERRORS[UNSUPPORTED_LOCALE_ERROR]]
  },
  [UNABLE_TO_LOAD_PAGE_ERROR]: {
    retryable: true,
    errors: [CUSTOM_ERRORS[UNABLE_TO_LOAD_PAGE_ERROR]]
  }
}

const errorCodeLookup = (errorCode, cachedErrors, setCachedErrors) => {
  let err = null
  let errorKey = null

  if (cachedErrors[errorCode]) {
    errorKey = cachedErrors[errorCode].errorKey
    err = cachedErrors[errorCode].err
    return { err, errorKey }
  }

  for (let key of Object.keys(ERROR_MAP)) {
    for (let knownError of ERROR_MAP[key].errors) {
      if (errorCode.indexOf(knownError) > -1) {
        err = ERROR_MAP[key]
        errorKey = key
        cachedErrors[errorCode] = { errorKey, err }
        setCachedErrors(cachedErrors)
      }
    }
  }
  return { err, errorKey }
}

export const ErrorContext = React.createContext({
  error: '',
  stage: '',
  errorCopy: {},
  errorData: {},
  reconsiderModalVisible: false,
  allErrorDataAvailable: false,
  errorProps: {},
  isLegacy: true,
  setError: EMPTY_FUNCTION, // only used for setting error without intent to show modal
  retry: EMPTY_FUNCTION,
  onError: EMPTY_FUNCTION,
  resetError: EMPTY_FUNCTION,
  showReconsiderModal: EMPTY_FUNCTION,
  hideReconsiderModal: EMPTY_FUNCTION,
  clearPairingCode: false,
  setClearPairingCode: EMPTY_FUNCTION
})

const ErrorProvider = (props) => {
  const {
    getTextTree,
    nextStageError,
    setNextStageError,
    sessionContext,
    isEcpExperience
  } = useContext(ConfigContext)
  const { isHpPlus, isSingleSku, modelName } = useContext(PrinterContext)
  const { hpPlusOfferAccepted } = useContext(UserContext)
  const { productFamily } = sessionContext?.onboardingContext || {}

  const persona = getContentStackQueryFormattedPersona(isSingleSku, isHpPlus)
  const isEntryPointActivate = getContentStackQueryIsEntryPointActivate(
    sessionContext?.onboardingContext?.bizModelHint
  )
  const [error, setError] = useState()
  const [retry, setRetry] = useState()
  const [stage, setStage] = useState()
  const [errorProps, setErrorProps] = useState()
  const [allErrorDataAvailable, setAllErrorDataAvailable] = useState(false)
  const [errorCopy, setErrorCopy] = useState()
  const [errorData, setErrorData] = useState()
  const [clearPairingCode, setClearPairingCode] = useState(false)
  const [errorCategory, setErrorCategory] = useState()
  const [isLegacy, setIsLegacy] = useState(true)
  const [reconsiderModalVisible, setReconsiderModalVisible] = useState(false)
  const pageCopy = useMemo(() => {
    return getTextTree('pages.onboarding_error')
  }, [getTextTree])
  const [onErrorDismiss, setOnErrorDismiss] = useState(EMPTY_FUNCTION)
  const [cachedErrors, setCachedErrors] = useState({})
  const errorCategoriesThatNeedAdditionalParams = [
    'activation_error',
    'pairing_code_consumed',
    'pairing_code_expired',
    'pairing_error'
  ]
  const {
    pageData: contentStackData,
    dataRetrieved: contentStackDataRetrieved,
    currentlyFetching: checkingContentStack,
    startQuery,
    reset: resetContentStack
  } = useContentStack({ queryOnInit: false })

  const { publishSpanEvent } = useSplunkRum(SPLUNK_RUM_CUSTOM_EVENTS.ERRORS)

  const addOptionalParams = ({ additional_params, category, stage }) => {
    if (additional_params?.error_type === BIZ_MODEL_MISMATCH_ERROR) {
      additional_params.entry_point_is_activate = isEntryPointActivate
    } else if (errorCategoriesThatNeedAdditionalParams.indexOf(category) > -1) {
      //do not include experience param for: expired code, dag down, or rate limit (from pairing code page)
      if (
        stage !== STAGES.pairing ||
        (category !== 'pairing_code_expired' &&
          category !== STAGES.pairing &&
          category !== 'rate_limit')
      ) {
        additional_params.experience = isEcpExperience
          ? ECP_EXPERIENCE.toUpperCase()
          : null
      }
      additional_params.product_family =
        sessionContext?.onboardingContext?.productFamily || null
      additional_params.entry_point_is_activate = isEntryPointActivate
      additional_params.hp_plus_offer_accepted = hpPlusOfferAccepted
      additional_params.stage = stage === STAGES.pairing ? STAGES.pairing : null
    }
  }
  const resetError = () => {
    onErrorDismiss()
    resetContentStack()
    setRetry(null)
    setError(null)
    setStage(null)
    setNextStageError(null)
    setErrorCopy(null)
    setErrorData(null)
    setErrorCategory(null)
    setIsLegacy(true)
    setAllErrorDataAvailable(false)
    setErrorProps(null)
    setOnErrorDismiss(EMPTY_FUNCTION)
  }

  const onError = ({
    err,
    stg,
    behavior = null,
    errorProperties = {},
    onErrorDismissCallback = EMPTY_FUNCTION
  }) => {
    publishSpanEvent({
      [SPLUNK_RUM_FIELDS.WORKFLOW]: err,
      [SPLUNK_RUM_FIELDS.MODEL_NAME]: modelName,
      [SPLUNK_RUM_FIELDS.FLOW_TYPE]: productFamily
    })

    if (typeof behavior == 'function') {
      // See: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
      // React will invoke the function when setting it as the state here
      setRetry(() => {
        return behavior
      })
    } else {
      setRetry(behavior)
    }

    setStage(stg)
    setError(err)
    setErrorProps(errorProperties)
    setOnErrorDismiss(() => onErrorDismissCallback)
  }

  useEffect(() => {
    if (nextStageError) {
      onError({ err: 'OD_XXX_OPNS00001_NS', stg: STAGES.generic })
    }
  }, [nextStageError])

  const getError = useCallback(
    (errorCode) => {
      // Check specific error map
      let { err, errorKey } = errorCodeLookup(
        errorCode,
        cachedErrors,
        setCachedErrors
      )
      // Check generic error map
      if (!err) {
        err = {
          retryable: !!retry,
          is_blocking: !retry,
          ...GENERIC_ERROR_MAP[stage]
        }
        errorKey = stage
      }

      setErrorData((x) => ({ ...x, ...err }))
      return errorKey
    },
    [retry, stage, cachedErrors]
  )

  const getBizModelKey = useCallback(
    (tree) => {
      if (tree?.single_sku_hp_plus && isSingleSku && isHpPlus)
        return SINGLE_SKU_HP_PLUS_KEY
      else if (tree?.single_sku && isSingleSku) return SINGLE_SKU_KEY
      else if (tree?.hp_plus) {
        return isHpPlus ? HP_PLUS_KEY : TRADITIONAL_KEY
      }
      return ''
    },
    [isHpPlus, isSingleSku]
  )

  const getCopy = useCallback(
    (errorCategory) => {
      const copy = {
        header: '',
        body: []
      }
      const errorStage = pageCopy[stage]
      let defaultError = errorStage[DEFAULT_KEY]
      // Get a reference to the default error copy, by biz model if it exists
      const defaultBizModelKey = getBizModelKey(defaultError)
      defaultError = defaultBizModelKey
        ? defaultError[defaultBizModelKey]
        : defaultError
      // Check if there is a specific error and it has a header and body
      // Use default if not
      if (errorCategory && errorStage[errorCategory]) {
        let specificError = errorStage[errorCategory]
        // Get a reference to the specific error copy, by biz model if it exists
        const specificBizModelKey = getBizModelKey(specificError)
        specificError = specificBizModelKey
          ? specificError[specificBizModelKey]
          : specificError

        copy.header = specificError.header
          ? specificError.header
          : defaultError.header
        copy.body = specificError.body ? specificError.body : defaultError.body
      } else {
        copy.header = defaultError.header
        copy.body = defaultError.body
      }
      setErrorCopy(copy)
    },
    [getBizModelKey, pageCopy, stage]
  )

  // Once react state has propagated for necessary data check ContentStack
  useEffect(() => {
    if (
      !allErrorDataAvailable &&
      stage &&
      error &&
      (nextStageError || (retry !== undefined && errorProps !== undefined)) &&
      !contentStackDataRetrieved &&
      !checkingContentStack
    ) {
      const category = getError(error)
      setErrorCategory(category)
      const additional_params = {
        error_type: category,
        persona
      }
      addOptionalParams({ additional_params, category, stage })
      startQuery({
        content_type: CONTENT_STACK_TYPES.error_modal,
        parsing_function: (data) => data,
        additional_params
      })
    }
  }, [
    stage,
    error,
    nextStageError,
    retry,
    errorProps,
    allErrorDataAvailable,
    contentStackDataRetrieved,
    checkingContentStack,
    getError,
    startQuery,
    persona,
    isEntryPointActivate,
    addOptionalParams,
    errorCategory,
    isHpPlus
  ])

  // Handle ContentStack response, and get legacy copy if necessary
  useEffect(() => {
    if (!contentStackDataRetrieved || checkingContentStack) return
    if (!contentStackData) {
      getCopy(errorCategory)
    } else {
      setErrorCopy(contentStackData)
      setIsLegacy(false)
    }
  }, [
    contentStackData,
    contentStackDataRetrieved,
    checkingContentStack,
    errorCategory,
    getCopy
  ])

  useEffect(() => {
    if (errorCopy && errorData) {
      setAllErrorDataAvailable(true)
    }
  }, [errorCopy, errorData])

  const errorState = {
    error,
    errorCopy,
    errorData,
    errorCategory,
    stage,
    retry,
    reconsiderModalVisible,
    allErrorDataAvailable,
    errorProps,
    isLegacy,
    setError,
    onError,
    resetError,
    showReconsiderModal: setReconsiderModalVisible.bind(null, true),
    hideReconsiderModal: setReconsiderModalVisible.bind(null, false),
    clearPairingCode,
    setClearPairingCode
  }

  return (
    <ErrorContext.Provider value={errorState}>
      {props.children}
    </ErrorContext.Provider>
  )
}

export default ErrorProvider
