import React, { useContext, useEffect } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import {
  ErrorContext,
  PrinterContext,
  ConfigContext,
  UserContext
} from '@/store'
import {
  ANALYTICS,
  HP_PLUS_SUPPORT_URL,
  TRADITIONAL_SUPPORT_URL
} from '@/store/Constants'
import { getModeForAnalytics } from '@/utils/Functions'

const { BUTTONS, SCREENS } = ANALYTICS
const GenericError = ({ error, children }) => {
  const { isHpPlus } = useContext(PrinterContext)
  const { isEcpExperience, nextStage } = useContext(ConfigContext)
  const { showReconsiderModal } = useContext(ErrorContext)
  const { getSessionId } = useContext(UserContext)

  const errorModalAnalytics = useAnalytics(SCREENS.ACTIVATION_ERROR_GENERIC)

  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.SUPPORT)
    const url = isHpPlus ? HP_PLUS_SUPPORT_URL : TRADITIONAL_SUPPORT_URL
    window.open(url, '_blank')
  }

  const secondaryClick = () => {
    if (isEcpExperience) {
      nextStage({
        result: 'failure',
        xCorrelationId: getSessionId()
      })
    } else {
      errorModalAnalytics.fireButtonClick(BUTTONS.CANCEL_SETUP_MANUAL)
      showReconsiderModal()
    }
  }

  useEffect(() => {
    const mode = getModeForAnalytics(error)

    errorModalAnalytics.fireScreenDisplayed({
      mode: mode,
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
  }, [])

  return <>{children({ primaryClick, secondaryClick })}</>
}

export default GenericError
