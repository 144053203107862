// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GppFFBFfd2UlPpBuaxvw{display:flex;flex-direction:column;justify-content:space-between;flex-grow:1}.GppFFBFfd2UlPpBuaxvw ._1wN0kMwJCpnxXGto2Ln2j_{margin-left:8px}.GppFFBFfd2UlPpBuaxvw ._1jlmrwK08SPoYYeSC-L_ze{display:flex;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/FinishSetup/FinishSetupPage.module.scss"],"names":[],"mappings":"AAAA,sBAAgB,YAAY,CAAC,qBAAqB,CAAC,6BAA6B,CAAC,WAAW,CAAC,+CAA+B,eAAe,CAAC,+CAAiC,YAAY,CAAC,kBAAkB","sourcesContent":[".finishSetupEcp{display:flex;flex-direction:column;justify-content:space-between;flex-grow:1}.finishSetupEcp .iconCheckmark{margin-left:8px}.finishSetupEcp .headerContainer{display:flex;align-items:center}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishSetupEcp": "GppFFBFfd2UlPpBuaxvw",
	"iconCheckmark": "_1wN0kMwJCpnxXGto2Ln2j_",
	"headerContainer": "_1jlmrwK08SPoYYeSC-L_ze"
};
export default ___CSS_LOADER_EXPORT___;
