const APP_NAME = 'portal-oobe'
const VERSION = process.env.APP_VERSION
const PREFIX = `${APP_NAME}:${VERSION}`

const SEVERITIES = ['log', 'info', 'error', 'warn', 'debug']

/**
 * @typedef {{
 *  [K in 'log' | 'info' | 'error' | 'warn' | 'debug']: (...message: unknown[]) => void
 * }} LoggerType
 */

/**
 * @type {LoggerType}
 */
export const Logger = SEVERITIES.reduce(
  (logger, severity) => ({
    ...logger,
    [severity]: (...message) =>
      process.env.SHOULD_LOG && console[severity](PREFIX, ...message)
  }),
  /** @type {LoggerType} */
  {}
)
