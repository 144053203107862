// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1zkGIIL5NGs-NshqDAzGSQ{position:relative}._1kiVmZIs-dGjObaaxOOPff{z-index:2;position:absolute;width:100%;height:100%;justify-content:center;align-items:center;display:flex;top:0;left:0}\n", "",{"version":3,"sources":["webpack://./src/components/UI/LoadingBackdrop.module.scss"],"names":[],"mappings":"AAAA,yBAAqB,iBAAiB,CAAC,yBAAkB,SAAS,CAAC,iBAAiB,CAAC,UAAU,CAAC,WAAW,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,YAAY,CAAC,KAAK,CAAC,MAAM","sourcesContent":[".backgroundContainer{position:relative}.loadingContainer{z-index:2;position:absolute;width:100%;height:100%;justify-content:center;align-items:center;display:flex;top:0;left:0}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundContainer": "_1zkGIIL5NGs-NshqDAzGSQ",
	"loadingContainer": "_1kiVmZIs-dGjObaaxOOPff"
};
export default ___CSS_LOADER_EXPORT___;
