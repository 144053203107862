// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._170BynPdG0dypEYaadBXSo{direction:ltr}._170BynPdG0dypEYaadBXSo ._2Ngxt0HRqv6bGVxokfqTEs{margin-bottom:20px}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Errors/Layouts/SelectSupportedLocaleLayout.module.scss"],"names":[],"mappings":"AAAA,yBAAkC,aAAa,CAAC,kDAA0C,kBAAkB","sourcesContent":[".selectSupportedLocaleLayoutModal{direction:ltr}.selectSupportedLocaleLayoutModal .select{margin-bottom:20px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectSupportedLocaleLayoutModal": "_170BynPdG0dypEYaadBXSo",
	"select": "_2Ngxt0HRqv6bGVxokfqTEs"
};
export default ___CSS_LOADER_EXPORT___;
