import React, { useContext, useEffect } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ErrorContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'
import { getModeForAnalytics } from '@/utils/Functions'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS
const PairingTokenError = ({ error, children }) => {
  const { resetError, setClearPairingCode, showReconsiderModal } = useContext(
    ErrorContext
  )

  const errorModalAnalytics = useAnalytics(
    SCREENS.ACTIVATION_ERROR_PAIRING_TOKEN
  )
  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.TRY_AGAIN)
    resetError()
    setClearPairingCode(true)
  }

  const secondaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CANCEL_SETUP_MANUAL)
    showReconsiderModal()
  }

  const hyperlinkClick = () => {
    errorModalAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
  }

  useEffect(() => {
    const mode = getModeForAnalytics(error)

    errorModalAnalytics.fireScreenDisplayed({
      mode: mode,
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
  }, [])

  return <>{children({ primaryClick, secondaryClick, hyperlinkClick })}</>
}

export default PairingTokenError
