import { useContext, useEffect, useRef } from 'react'
import { ErrorContext } from '@/store'

const useErrorRegistered = () => {
  const { error } = useContext(ErrorContext)
  const errorHasBeenRegistered = useRef(null)

  useEffect(() => {
    errorHasBeenRegistered.current = !!error
  }, [error])

  return { errorHasBeenRegistered }
}

export default useErrorRegistered
