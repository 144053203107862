import React, { useContext, useState } from 'react'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import TextHeader from '@/components/UI/TextHeader'
import classes from './ActivationPage.module.scss'
import useAnalytics from '@/hooks/useAnalytics'
import { IconCheckmarkCircle } from '@veneer/core/dist/scripts/icons'

import {
  ANALYTICS,
  BIZ_MODEL_HP_PLUS,
  CONTENT_STACK_TYPES,
  HP_PLUS_PROGRAM_LEVEL,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'

const { BUTTONS, SCREENS, LINKS } = ANALYTICS
import SupportedCountriesModal from '@/components/UI/Modals/SupportedCountriesModal'
import Button from '@veneer/core/dist/scripts/button'
import useContentStack from '@/hooks/useContentStack'
import GenericModal, {
  GENERIC_MODAL_TYPES
} from '@/components/UI/Modals/GenericModal'
import { replaceFragments } from '@/utils/Functions'
import clsx from 'clsx'

const ActivationPage = () => {
  const { navigation } = useContext(ConfigContext)
  const { isSingleSku, setBizModel } = useContext(PrinterContext)
  const { setProgramLevel, accountType } = useContext(UserContext)
  const [
    showSupportedCountriesModal,
    setShowSupportedCountriesModal
  ] = useState(false)

  const parseCmsData = (data) => {
    const result = {}
    result.header = data?.header
    result.content = data?.content
    result.secondary_button_text = data?.secondary_button_text
    result.primary_button_text = data?.primary_button_text
    return result
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.activation,
    parsing_function: parseCmsData,
    additional_params: {
      tenant_type: accountType,
      applicable_products: isSingleSku ? 'single_sku' : 'dual_sku'
    }
  })

  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(SCREENS.REQUIREMENTS)
  const countrySupportedListAnalytics = useAnalytics(
    SCREENS.REQUIREMENTS_COUNTRY_SUPPORTED_LIST,
    false
  )
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  fireScreenDisplayed()

  const onClickHandler = (bizModel = null, programLevel = null) => {
    if (isSingleSku) {
      setBizModel(bizModel)
      setProgramLevel(programLevel)
    }
    fireButtonClick(BUTTONS.CONFIRM)
    navigation.push('/activating')
  }

  const onContentClick = (behavior) => {
    switch (behavior) {
      case 'supported countries':
        return supportedCountriesClickHandler
      default:
        return () => {}
    }
  }
  const supportedCountriesClickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      fireHyperLinkClick(LINKS.SUPPORTED_COUNTRIES)
      setShowSupportedCountriesModal(true)
      countrySupportedListAnalytics.fireScreenDisplayed()
    }
  }
  const toggleDeclineModal = (value) => {
    setShowDeclineModal(value)
  }

  if (!pageData) return <></>

  return (
    <div data-testid="activation-page" className={classes.activation}>
      <GenericModal
        type={GENERIC_MODAL_TYPES.DECLINE_HP_PLUS_MODAL}
        visible={showDeclineModal}
        setVisibility={toggleDeclineModal}
      />
      {showSupportedCountriesModal && (
        <SupportedCountriesModal
          onClose={() => {
            countrySupportedListAnalytics.fireButtonClick(BUTTONS.CLOSE)
            setShowSupportedCountriesModal(false)
            fireScreenDisplayed({ force: true })
          }}
        />
      )}
      <div>
        <TextHeader>{pageData.header}</TextHeader>
      </div>
      {pageData?.content &&
        Object.values(pageData.content).map((item, i) => {
          if (item?.block_copy)
            return (
              <div
                className={clsx(
                  item.block_copy?.click_behavior,
                  classes.descriptionText
                )}
                key={`block-${i}`}
                onClick={onContentClick(item.block_copy?.click_behavior)}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: replaceFragments(item.block_copy.copy)
                  }}
                  data-testid={item.block_copy?.click_behavior}
                />
              </div>
            )
          else if (item?.list_item)
            return (
              <div
                className={isSingleSku ? classes.container : classes.list}
                onClick={onContentClick(item.list_item?.click_behavior)}
                key={`step-${i}`}
                onContextMenu={(e) => {
                  e.preventDefault()
                }}
              >
                {isSingleSku && <IconCheckmarkCircle size={20} />}
                {isSingleSku && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: replaceFragments(item.list_item.copy)
                    }}
                    data-testid={item.list_item?.click_behavior}
                  />
                )}
                {!isSingleSku && (
                  <li
                    dangerouslySetInnerHTML={{
                      __html: replaceFragments(item.list_item.copy)
                    }}
                    key={`step-${i}`}
                    data-testid={item.list_item?.click_behavior}
                  />
                )}
              </div>
            )
        })}
      <div className={classes.footer}>
        <div>
          {isSingleSku && (
            <Button
              appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
              onClick={() => {
                fireButtonClick(BUTTONS.BACK)
                navigation.push('/hp-plus-benefits')
              }}
              data-testid="back"
            >
              {pageData.secondary_button_text}
            </Button>
          )}
          <Button
            appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
            className={classes.button}
            onClick={() => {
              onClickHandler(BIZ_MODEL_HP_PLUS, HP_PLUS_PROGRAM_LEVEL)
            }}
            data-testid="activate-hp-plus"
          >
            {pageData.primary_button_text}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ActivationPage
