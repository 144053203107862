import React from 'react'
import { EMPTY_FUNCTION } from '@/utils/Functions'

const ErrorDetail = ({
  copy,
  error,
  click = EMPTY_FUNCTION,
  timeoutDisplay = ''
}) => {
  if (!copy || !error || Object.keys(copy).length < 1) {
    return <></>
  }
  return (
    <>
      {error?.has_error_list && (
        <>
          {copy?.body?.header && <div>{copy.body.header}</div>}
          {copy.body.bullets ? (
            <ul>
              {Object.values(copy.body.bullets).map((val, i) => {
                return <li key={i}>{val}</li>
              })}
            </ul>
          ) : (
            <br />
          )}
          {copy?.body?.footer && (
            <p
              onClick={click}
              dangerouslySetInnerHTML={{
                __html: copy.body.footer
              }}
            />
          )}
        </>
      )}
      {!error?.has_error_list &&
        copy?.body &&
        Object.values(copy.body).map((val, i) => {
          return (
            <p
              onClick={click}
              dangerouslySetInnerHTML={{
                __html: val.replace('[[time]]', timeoutDisplay)
              }}
              key={i}
            />
          )
        })}
    </>
  )
}

export default ErrorDetail
