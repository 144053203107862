import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { PartnerLinkClient } from '@/clients/PartnerLinkClient'
import { ConfigContext, PrinterContext } from '@/store'

export function usePartnerLinkClient() {
  const { stack, authProvider } = useContext(ConfigContext)
  const { productNumber, serialNumber, cloudId } = useContext(PrinterContext)

  const partnerClient = useRef(undefined)

  const connectPrinterToPartner = useCallback(
    (accountId) => {
      return partnerClient.current.connect({
        productNumber,
        serialNumber,
        deviceId: cloudId,
        accountId
      })
    },
    [productNumber, serialNumber, cloudId]
  )

  useEffect(() => {
    partnerClient.current = new PartnerLinkClient(stack, authProvider)
  }, [stack])

  return useMemo(() => ({ connectPrinterToPartner }), [connectPrinterToPartner])
}
