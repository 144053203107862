import React, { useContext, useEffect, useState } from 'react'
import { ErrorContext } from '@/store'

export const TimerLayout = ({
  errorCopy,
  errorData,
  fireChildAnalyticsEvent
}) => {
  const { resetError } = useContext(ErrorContext)
  const [timeoutValue, setTimeoutValue] = useState(0)
  const [timeoutDisplay, setTimeoutDisplay] = useState('')

  useEffect(() => {
    if (timeoutValue === 0 && errorData?.timeout) {
      setTimeoutDisplay(millisToMinutesAndSeconds(errorData.timeout))
      setTimeoutValue(errorData.timeout)
    }
  }, [errorData, timeoutValue])

  useEffect(() => {
    let timeout
    let interval
    if (errorData?.timeout && !interval) {
      let secondsPassed = 0
      interval = setInterval(() => {
        secondsPassed += 1
        setTimeoutDisplay(
          millisToMinutesAndSeconds(timeoutValue - 1000 * secondsPassed)
        )
      }, 1000)
      timeout = setTimeout(() => {
        resetError()
        clearInterval(interval)
      }, errorData.timeout)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [errorData, resetError, timeoutValue])

  return (
    <div
      data-testid={'timer-layout'}
      dangerouslySetInnerHTML={{
        __html: errorCopy.body.replace('[[time]]', timeoutDisplay)
      }}
      onClick={fireChildAnalyticsEvent}
    />
  )
}

export const millisToMinutesAndSeconds = (millis) => {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)
  const secondPrefix = seconds < 10 ? '0' : ''
  return seconds == 60
    ? minutes + 1 + ':00'
    : minutes + ':' + secondPrefix + seconds
}
