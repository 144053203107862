import React from 'react'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import classes from './AssignOrgWebTemplate.module.scss'
import NextStageButton from '@/components/UI/NextStageButton'

export function AssignOrgWebTemplate({
  pageData,
  retry,
  assignOwnershipHandler,
  submitDisabled,
  domainRegistrationSection
}) {
  return (
    <>
      <TextHeader>{pageData.header}</TextHeader>
      <TextSubHeader>{pageData.subheader}</TextSubHeader>
      <div className={classes.assignOrganization} data-testid="assign-org-page">
        {domainRegistrationSection}
        <div className={classes.buttons}>
          <NextStageButton
            copy={pageData.continue_button_text}
            onClick={assignOwnershipHandler}
            dataTestId="submit"
            disabled={submitDisabled}
            loading={retry}
          />
        </div>
      </div>
    </>
  )
}
