import { JSON_CONTENT_TYPE } from '@/store/Constants'
import { xmlParser } from '@/utils/DeviceHttpProxy/LedmHelpers'

export class ParseDhpResponseError extends Error {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}

export function parseDhpResponseData(response) {
  let data = response?.body?.data

  if (!data || typeof data !== 'string') {
    return response
  }

  try {
    const isJson = response.body.contentType?.includes(JSON_CONTENT_TYPE)
    if (response.body.isBase64Encoded) {
      data = atob(data)
    }
    response.body.data = isJson ? JSON.parse(data) : xmlParser(data)
  } catch (error) {
    throw new ParseDhpResponseError(
      'Error parsing DHP data: ' + error,
      response
    )
  }
  return response
}
