import React from 'react'
import { Router } from 'react-router-dom'
import App from '@/App'
import { ConfigContext } from '@/store'
import { useContext } from 'react'

const AppWrapper = () => {
  const { navigation } = useContext(ConfigContext)
  return (
    <Router history={navigation}>
      <App />
    </Router>
  )
}

export default AppWrapper
