import React, { createContext, useMemo, useState, useCallback } from 'react'
import { SPLUNK_RUM_FIELDS } from './Constants'
import { EMPTY_FUNCTION } from '@/utils/Functions'
import { Logger } from '@/utils/Logger'

export const SplunkRumContext = createContext({
  spans: new Map(),
  startSpan: EMPTY_FUNCTION
})

export function SplunkRumProvider({ monitoring, children }) {
  const [spans, setSpans] = useState(new Map())

  const startSpan = useCallback(
    (spanId, spanOptions, attributes = {}) => {
      const { moduleName, workflow } = spanOptions
      const span = monitoring?.startSpan?.(moduleName, {
        [SPLUNK_RUM_FIELDS.WORKFLOW]: workflow,
        ...attributes
      })
      setSpans(new Map(spans.set(spanId, span)))
      return span
    },
    [spans, monitoring]
  )

  const splunkRumValue = useMemo(
    () => ({
      spans,
      startSpan
    }),
    [spans, startSpan]
  )

  return (
    <SplunkRumContext.Provider value={splunkRumValue}>
      {children}
    </SplunkRumContext.Provider>
  )
}
