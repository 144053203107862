import React, { useContext, useEffect } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS

const DuplicatedAccountError = ({ error, children, errorCopy }) => {
  const { logout } = useContext(ConfigContext)
  const { isSingleSku, isHpPlus } = useContext(PrinterContext)
  const { userEmail } = useContext(UserContext)

  const initialStep = isSingleSku
    ? 'ONBOARD'
    : isHpPlus
    ? 'ACTIVATE'
    : 'CONNECT'
  const initialPath = `/${initialStep.toLowerCase()}`

  const errorModalAnalytics = useAnalytics(
    SCREENS[`USER_ALREADY_EXISTS_${initialStep}`]
  )

  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CLOSE)
    window.location.pathname = initialPath
  }

  const secondaryClick = async () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CREATE_ACCOUNT)
    await logout({
      postLogoutRedirect: initialPath
    })
  }

  const hyperlinkClick = () => {
    errorModalAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
  }

  errorModalAnalytics.fireScreenDisplayed({
    detail: error,
    name: ANALYTICS.ERROR_CODE
  })

  return (
    <>
      {children({
        primaryClick,
        secondaryClick,
        hyperlinkClick,
        errorCopy: {
          ...errorCopy,
          body: errorCopy.body.replace('[[EMAIL_ADDRESS]]', userEmail)
        }
      })}
    </>
  )
}

export default DuplicatedAccountError
