import React, { useContext, useEffect } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ErrorContext, PrinterContext } from '@/store'
import { ANALYTICS } from '@/store/Constants'
import { getRedirectionPath } from '@/utils/Functions'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS
const BizModelMismatchError = ({ error, children }) => {
  const { offer, bizModel } = useContext(PrinterContext)
  const { resetError, setClearPairingCode } = useContext(ErrorContext)

  const errorModalAnalytics = useAnalytics(SCREENS.INCORRECT_FLOW_MODAL)
  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CONTINUE)
    window.location.pathname = getRedirectionPath(offer, bizModel)
  }

  const secondaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.TRY_AGAIN)
    resetError()
    setClearPairingCode(true)
  }

  const hyperlinkClick = () => {
    errorModalAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
  }

  useEffect(() => {
    errorModalAnalytics.fireScreenDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
  })

  return <>{children({ primaryClick, secondaryClick, hyperlinkClick })}</>
}

export default BizModelMismatchError
