import React from 'react'

import Button from '@veneer/core/dist/scripts/button'
import { IconArrowUpRight } from '@veneer/core/dist/scripts/icons'

export function LinkButton({
  href,
  onClick = undefined,
  iconPosition = 'end',
  appearance = 'primary',
  disabled = false,
  openNewTab = false,
  children
}) {
  return (
    <a
      tabIndex={-1}
      href={!disabled ? href : undefined}
      target={openNewTab ? '_blank' : undefined}
      rel="noreferrer"
    >
      <Button
        appearance={appearance}
        disabled={disabled}
        onClick={onClick}
        leadingIcon={
          iconPosition === 'start' ? <IconArrowUpRight /> : undefined
        }
        trailingIcon={iconPosition === 'end' ? <IconArrowUpRight /> : undefined}
      >
        {children}
      </Button>
    </a>
  )
}
