import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  STAGES,
  UserContext
} from '@/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import useAnalytics from '@/hooks/useAnalytics'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  ISO_VALUES,
  SMB_EXPERIENCE
} from '@/store/Constants'
import useContentStack from '@/hooks/useContentStack'
import { AssignOrgAppTemplate, AssignOrgWebTemplate } from './Templates'
import { DomainRegistrationSection } from './Components/DomainRegistrationSection'

const { MODES, BUTTONS, SCREENS } = ANALYTICS

const AssignOrganizationPage = ({
  createAccount,
  availableOrganizations,
  doDomainsExist,
  didDomainLoad,
  setRetryBehavior
}) => {
  const {
    onbpReleaseOrgcountryddassignprinterowner: isCountrySelectorEnabled
  } = useFlags()
  const { onError } = useContext(ErrorContext)
  const userCtx = useContext(UserContext)
  const {
    country,
    hpPlusCountries,
    experience,
    sessionContext,
    isBinaryApp
  } = useContext(ConfigContext)

  const { isHpPlus } = useContext(PrinterContext)

  // TODO: review these states and move to proper place
  const [showContent, setShowContent] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [enteredOrganization, setEnteredOrganization] = useState('')
  const [isOrganizationNameValid, setIsOrganizationNameValid] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [hpPlusCountrySelected, setHpPlusCountrySelected] = useState(false)
  const [countrySelectorEval, setCountrySelectorEval] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(country.toUpperCase())
  const [
    enteredOrganizationDescription,
    setEnteredOrganizationDescription
  ] = useState(null)
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.COMPANY_NAME
  )
  const analyticsUnsupportedCountryError = useAnalytics(
    SCREENS.COUNTRY_UNSUPPORTED_ERROR,
    false
  )
  const [retry, setRetry] = useState(false)

  const parseCmsResponse = (data) => ({
    header: data.header,
    subheader: data.subheader,
    new_organization: data.organization_labels.new_organization_text,
    organization_name: data.organization_labels.name,
    invalid_name: data.organization_labels.invalid_name_text,
    organization_country: data.organization_labels.country,
    country_error: data.organization_labels.country_error_text,
    organization_description: data.organization_labels.description,
    agree_terms: data.agreement_terms,
    continue_button_text: data.continue_button_text
  })
  const productFamily = sessionContext?.onboardingContext?.productFamily
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.assign_organization,
    parsing_function: parseCmsResponse,
    additional_params: {
      product_family: productFamily?.toUpperCase(),
      experience: experience
        ? experience.toUpperCase()
        : SMB_EXPERIENCE.toUpperCase()
    }
  })

  const fireUnsupportedCountryErrorAnalytics = useCallback(
    (cc) => {
      if (isHpPlus && hpPlusCountries?.indexOf(cc) === -1)
        analyticsUnsupportedCountryError.fireScreenDisplayed({
          mode: MODES.DOMAIN[doDomainsExist]
        })
    },
    [
      analyticsUnsupportedCountryError,
      doDomainsExist,
      isHpPlus,
      hpPlusCountries
    ]
  )

  useEffect(() => {
    if (didDomainLoad && !showContent) {
      setShowContent(true)
      fireScreenDisplayed({
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }
  }, [didDomainLoad, doDomainsExist, fireScreenDisplayed, showContent])

  useEffect(() => {
    if (
      didDomainLoad &&
      isCountrySelectorEnabled &&
      userCtx.supportedCountries?.length > 0 &&
      !countrySelectorEval &&
      (!doDomainsExist || selectedOrganization === 'new')
    ) {
      setCountrySelectorEval(true)
      fireButtonClick(BUTTONS.SELECT_COUNTRY, {
        detail: selectedCountry,
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireUnsupportedCountryErrorAnalytics(selectedCountry)
    }
  }, [
    userCtx.supportedCountries,
    countrySelectorEval,
    didDomainLoad,
    doDomainsExist,
    selectedCountry,
    selectedOrganization,
    isCountrySelectorEnabled,
    fireUnsupportedCountryErrorAnalytics,
    fireButtonClick
  ])

  const accountFailedCreationHandler = (accountDetails) => {
    setRetry(true)
    createAccount(accountDetails)
  }

  const assignOwnershipHandler = () => {
    const isExistingOrg = selectedOrganization && selectedOrganization !== 'new'

    if (doDomainsExist) {
      fireButtonClick(BUTTONS.RADIO, {
        detail: MODES.ORGANIZATION[isExistingOrg],
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }

    if (!isExistingOrg) {
      fireButtonClick(BUTTONS.ORG_NAME, {
        detail: MODES.PROVIDED[!!enteredOrganization],
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireButtonClick(BUTTONS.ORG_DESC, {
        detail: MODES.PROVIDED[!!enteredOrganizationDescription],
        mode: MODES.DOMAIN[doDomainsExist]
      })
    }

    fireButtonClick(BUTTONS.CONTINUE, {
      mode: MODES.DOMAIN[doDomainsExist]
    })

    if (
      isCountrySelectorEnabled &&
      userCtx.supportedCountries?.length > 0 &&
      isHpPlus &&
      !hpPlusCountrySelected
    ) {
      // OP = Onboarding Platform, UCHP = Unsupported HP Country
      onError({
        err: 'OP_XXX_UHPC00001_XX',
        stg: STAGES.account,
        errorProperties: {
          mode: MODES.DOMAIN[doDomainsExist]
        }
      })
      return
    }

    let accountDetails = { country: selectedCountry }
    userCtx.setOrganizationDescription(enteredOrganizationDescription)
    userCtx.setUsingExistingDomain(!!isExistingOrg)
    if (isExistingOrg) {
      //Type coercion on purpose here as input yields string
      // noinspection EqualityComparisonWithCoercionJS
      const { accountName: selectedName } = availableOrganizations.find(
        (org) => org.nodeId == selectedOrganization
      )
      userCtx.setOrganization(selectedName)
      accountDetails.accountId = selectedOrganization
    } else {
      userCtx.setOrganization(enteredOrganization.trim())
      accountDetails.accountName = enteredOrganization
      accountDetails.organizationDescription = enteredOrganizationDescription
    }
    setRetryBehavior(() => () => accountFailedCreationHandler(accountDetails))
    createAccount(accountDetails)
  }

  const organizationEntryHandler = (orgName) => {
    orgName = orgName.substring(0, 1024)
    const regex = /^[\p{L}\p{Nd}\p{P}\p{Zs}$&+=]{1,1024}$/u
    setIsOrganizationNameValid(regex.test(orgName))
    setEnteredOrganization(orgName)
  }

  const countrySelectorHandler = useCallback(
    (selected) => {
      setSelectedCountry(selected.value)
      userCtx.setSelectedCountry(selected.label)
      userCtx.setSelectedCountryISO(selected.value)
      fireButtonClick(BUTTONS.SELECT_COUNTRY, {
        detail: selected.value,
        mode: MODES.DOMAIN[doDomainsExist]
      })
      fireUnsupportedCountryErrorAnalytics(selected.value)
    },
    [
      doDomainsExist,
      fireButtonClick,
      userCtx,
      fireUnsupportedCountryErrorAnalytics
    ]
  )

  useEffect(() => {
    userCtx.setSelectedCountry(ISO_VALUES[selectedCountry])
  })

  useEffect(() => {
    if (isHpPlus && selectedCountry) {
      setHpPlusCountrySelected(hpPlusCountries?.indexOf(selectedCountry) > -1)
    }
  }, [selectedCountry, isHpPlus, hpPlusCountries, setHpPlusCountrySelected])

  useEffect(() => {
    if (selectedCountry) {
      // No orgs available, org name provided
      if (
        !doDomainsExist &&
        enteredOrganization.trim().length > 0 &&
        isOrganizationNameValid
      ) {
        setSubmitDisabled(false)
        return
      }
      // Orgs available, existing org selected
      if (
        doDomainsExist &&
        selectedOrganization &&
        selectedOrganization !== 'new'
      ) {
        setSubmitDisabled(false)
        return
      }
      // New selected, org name provided
      if (
        selectedOrganization === 'new' &&
        enteredOrganization.trim().length > 0 &&
        isOrganizationNameValid
      ) {
        setSubmitDisabled(false)
        return
      }
    }
    setSubmitDisabled(true)
  }, [
    availableOrganizations,
    doDomainsExist,
    enteredOrganization,
    selectedCountry,
    selectedOrganization,
    isOrganizationNameValid
  ])

  useEffect(() => {
    if (userCtx.supportedCountriesErrorCode) {
      onError({
        err: userCtx.supportedCountriesErrorCode,
        stg: STAGES.account,
        errorProperties: {
          mode: MODES.DOMAIN[doDomainsExist]
        }
      })
    }
  }, [doDomainsExist, userCtx.supportedCountriesErrorCode, onError])

  if (!showContent || !pageData) return <></>

  const domainRegistrationSection = (
    <DomainRegistrationSection
      pageData={pageData}
      retry={retry}
      doDomainsExist={doDomainsExist}
      setSelectedOrganization={setSelectedOrganization}
      selectedOrganization={selectedOrganization}
      availableOrganizations={availableOrganizations}
      enteredOrganization={enteredOrganization}
      organizationEntryHandler={organizationEntryHandler}
      isOrganizationNameValid={isOrganizationNameValid}
      hpPlusCountrySelected={hpPlusCountrySelected}
      selectedCountry={selectedCountry}
      countrySelectorHandler={countrySelectorHandler}
      setEnteredOrganizationDescription={setEnteredOrganizationDescription}
    />
  )

  const templateProps = {
    pageData,
    retry,
    assignOwnershipHandler,
    submitDisabled,
    domainRegistrationSection
  }

  return isBinaryApp ? (
    <AssignOrgAppTemplate {...templateProps} />
  ) : (
    <AssignOrgWebTemplate {...templateProps} />
  )
}

export default AssignOrganizationPage
