import React from 'react'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Button from '@veneer/core/dist/scripts/button'
import classes from './NamePrinterWebTemplate.module.scss'
import { VENEER_SECONDARY_BUTTON_APPEARANCE } from '@/store/Constants'

export function NamePrinterWebTemplate({
  pageData,
  enteredName,
  enteredLocation,
  setEnteredName,
  setEnteredLocation,
  skipHandler,
  submitHandler
}) {
  return (
    <>
      <div data-testid="name-printer-page">
        <TextHeader>{pageData.header}</TextHeader>
        <TextSubHeader>{pageData.subhead}</TextSubHeader>
        <div className={classes.content}>
          <div className={classes.inputs}>
            <div className={classes.textContainer}>
              <TextBox
                id="printer-name"
                onChange={setEnteredName}
                className={classes.textBox}
                helperTextVisibility="visible"
                maxLength={32}
                label={pageData.name_label}
                helperText={pageData.name_helper_text}
              />
            </div>
            <div className={classes.textContainer}>
              <TextBox
                id="location"
                onChange={setEnteredLocation}
                className={classes.textBox}
                helperTextVisibility="visible"
                maxLength={32}
                label={pageData.location_label}
                helperText={pageData.location_helper_text}
              />
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
            onClick={skipHandler}
            data-testid="name-printer-skip"
          >
            {pageData.buttons.skip}
          </Button>
          <Button
            disabled={!enteredName && !enteredLocation}
            onClick={submitHandler}
            data-testid="name-printer-continue"
          >
            {pageData.buttons.continue}
          </Button>
        </div>
      </div>
    </>
  )
}
