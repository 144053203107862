import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classes from './Background.module.scss'
import { ConfigContext } from '@/store'
import clsx from 'clsx'

const Background = ({ children, className = '' }) => {
  const {
    isBinaryApp,
    isMobile,
    setSidebarVisibility,
    sessionContext,
    isPreview
  } = useContext(ConfigContext)
  const [hide, setHide] = useState()
  const location = useLocation()
  const { isHpx } = useContext(ConfigContext)

  useEffect(() => {
    if (
      isPreview ||
      (sessionContext && sessionContext?.currentServiceId.indexOf('onbp') > -1)
    ) {
      let hideBackground
      try {
        hideBackground =
          // Shouldn't hide background if it's binary app
          !isBinaryApp &&
          // Check the length of the path elements. A length of 4 is a top level route
          // Ex: ['', 'us', 'en', 'value-prop']
          window.location.pathname.split('/').length < 5 &&
          ['connect', 'activate', 'onboard', 'value-prop'].indexOf(
            window.location.pathname.split('/').pop()
          ) > -1
        // Should hide sidebar if it's a binary app or if background is hidden
        setSidebarVisibility(!isBinaryApp && !hideBackground)
      } catch (e) {
        hideBackground = false
        setSidebarVisibility(true)
      }
      setHide(hideBackground)
    }
  }, [location, setHide, setSidebarVisibility, sessionContext, isBinaryApp])

  if (hide) return children
  return (
    <div
      className={clsx(className, {
        [classes.binaryAppMobileBackground]: isBinaryApp && isMobile && !isHpx,
        [classes.binaryAppBackground]: isBinaryApp && !isMobile && !isHpx,
        [classes.background]: !isBinaryApp && !isMobile && !isHpx,
        [classes.hpx]: isHpx
      })}
      data-testid={`${isBinaryApp ? 'binary-app-background' : 'background'}`}
    >
      <div
        className={isBinaryApp ? classes.binaryAppContainer : classes.container}
        data-testid={`${isBinaryApp ? 'binary-app-container' : 'container'}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Background
