import React, { useEffect, useContext, useState } from 'react'
import useContentStack from '@/hooks/useContentStack'
import { ANALYTICS, CONTENT_STACK_TYPES } from '@/store/Constants'
import {
  ConfigContext,
  ErrorContext,
  STAGES,
  UserContext,
  PrinterContext
} from '@/store'
import useAnalytics from '@/hooks/useAnalytics'
import { getContentStackQueryFormattedPersona } from '@/hooks/utils'
const { BUTTONS, SCREENS } = ANALYTICS

const ReconsiderModal = ({ children, setOverride }) => {
  const {
    nextStage,
    isScanSetup,
    shortcutsTenantManagerOutput,
    sessionContext,
    publishCdmEvent,
    experience
  } = useContext(ConfigContext)
  const { stage, error, hideReconsiderModal } = useContext(ErrorContext)
  const { getSessionId } = useContext(UserContext)
  const { isSingleSku, isHpPlus } = useContext(PrinterContext)
  const productFamily = sessionContext?.onboardingContext?.productFamily
  const [eventsBuilt, setEventsBuilt] = useState(false)
  const [continueEvent, setContinueEvent] = useState(null)
  const [exitEvent, setExitEvent] = useState(null)

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.reconsider_modal,
    additional_params: {
      persona: getContentStackQueryFormattedPersona(isSingleSku, isHpPlus),
      experience: experience?.toUpperCase(),
      product_family: productFamily || null
    }
  })
  const page =
    stage === STAGES.activation
      ? SCREENS.RECONSIDER_ACTIVATION_MODAL
      : SCREENS.RECONSIDER_PAIRING_MODAL
  const analyticsReconsiderModal = useAnalytics(page)

  useEffect(() => {
    if (analyticsReconsiderModal && !eventsBuilt) {
      analyticsReconsiderModal.fireModalWindowDisplayed()
      setContinueEvent(
        analyticsReconsiderModal.buildButtonClickEvent(BUTTONS.CONTINUE_SETUP)
      )
      setExitEvent(
        analyticsReconsiderModal.buildButtonClickEvent(BUTTONS.CANCEL_SETUP)
      )
      setEventsBuilt(true)
    }
  }, [analyticsReconsiderModal, eventsBuilt])

  const continueSetup = () => {
    if (continueEvent) {
      publishCdmEvent(continueEvent)
    }
    setOverride(null)
    hideReconsiderModal()
  }

  const exitSetup = () => {
    if (exitEvent) {
      publishCdmEvent(exitEvent)
    }
    nextStage({
      result: 'failure',
      ...(isScanSetup && shortcutsTenantManagerOutput),
      errorInfo: {
        errorCode: error,
        errorDescription: ''
      },
      xCorrelationId: getSessionId()
    })
  }

  return (
    <>
      {children({
        buttonClicks: [continueSetup, exitSetup],
        data: pageData
      })}
    </>
  )
}

export default ReconsiderModal
