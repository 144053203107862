import React, { useContext, useEffect, useState } from 'react'
import classes from './InstallDriversWindowsPage.module.scss'
import {
  ANALYTICS,
  CONTENT_STACK_TYPES,
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'
import { ConfigContext, UserContext } from '@/store'
import Button from '@veneer/core/dist/scripts/button'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import ButtonGroup from '@veneer/core/dist/scripts/button_group'
import useAnalytics from '@/hooks/useAnalytics'
import useContentStack from '@/hooks/useContentStack'
import { useGetWinSoftwareUrl } from '@/hooks/useGetWinSoftwareUrl'
import { IconInfo } from '@veneer/core/dist/scripts/icons'

const InstallDriversWindowsContent = ({ cmsData }) => {
  return (
    <div className={classes.installDriversWindows}>
      <div className={classes.subheader}>
        <TextHeader>{cmsData.header}</TextHeader>
        <TextSubHeader>{cmsData.subheader}</TextSubHeader>
      </div>
      <div className={classes.steps}>
        {cmsData?.steps &&
          Object.values(cmsData.steps).map((item, i) => (
            <div className={classes.container} key={`container-${i}`}>
              <img
                src={item.step.image.url}
                key={`image-${i}`}
                data-testid={`image-${item.step.text}`}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: item.step.text
                }}
                key={`text-${i}`}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

const InstallDriversWindowsPage = () => {
  const configCtx = useContext(ConfigContext)
  const { navigation, driverUrl } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)
  const {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  } = useAnalytics(ANALYTICS.SCREENS.INSTALL_DRIVER_WINDOWS)
  const [
    installDriversWindowsClicked,
    setInstallDriversWindowsClicked
  ] = useState(false)
  const { checkingForDriver, getWinSoftwareUrl } = useGetWinSoftwareUrl()

  const parseCmsData = (data) => {
    const parsedData = {}

    parsedData.header = data.header
    parsedData.subheader = data.subheader
    parsedData.steps = data.steps
    parsedData.install_driver_button_text = data.install_driver_button_text
    parsedData.footer_text = data.footer_text
    parsedData.footer_image = data.footer_image
    parsedData.skip_button_text = data.skip_button_text
    parsedData.continue_button_text = data.continue_button_text

    return parsedData
  }

  const installDriversWindowsHandler = () => {
    fireButtonClick(ANALYTICS.BUTTONS.INSTALL_DRIVER)
    getWinSoftwareUrl().then(() => {
      setInstallDriversWindowsClicked(true)
    })
  }

  const skipHandler = () => {
    fireButtonClick(ANALYTICS.BUTTONS.SKIP)
    configCtx.nextStage({
      result: 'declined',
      xCorrelationId: getSessionId()
    })
  }

  const handleFooterClick = (event) => {
    const anchor = event.target.closest('a')
    if (anchor && event.currentTarget.contains(anchor)) {
      fireHyperLinkClick(ANALYTICS.LINKS.HP_SMART_DRIVER)
    }
  }

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.install_drivers_windows,
    parsing_function: parseCmsData
  })

  useEffect(() => {
    if (installDriversWindowsClicked && driverUrl && !checkingForDriver) {
      window.open(driverUrl, '_blank')
    }
  }, [checkingForDriver, driverUrl, installDriversWindowsClicked])

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div
      className={classes.installDriversWindows}
      data-testid="install-drivers-windows-page"
    >
      <InstallDriversWindowsContent cmsData={pageData} />
      <div className={classes.installDriverWindowsButton}>
        <Button
          appearance={
            installDriversWindowsClicked
              ? VENEER_SECONDARY_BUTTON_APPEARANCE
              : VENEER_PRIMARY_BUTTON_APPEARANCE
          }
          loading={checkingForDriver}
          onClick={installDriversWindowsHandler}
          data-testid="install-driver"
        >
          {pageData.install_driver_button_text}
        </Button>
      </div>
      <div className={classes.footer}>
        <span>
          <IconInfo filled={true} color="gray7" size={20} />
        </span>
        <p
          onClick={handleFooterClick}
          data-testid="hp-smart-driver"
          dangerouslySetInnerHTML={{
            __html: pageData.footer_text
          }}
        />
      </div>
      <div className={classes.buttons}>
        <ButtonGroup orientation="horizontal">
          <Button
            appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
            data-testid="skip"
            onClick={skipHandler}
          >
            {pageData.skip_button_text}
          </Button>
          <Button
            disabled={!installDriversWindowsClicked}
            data-testid="continue"
            onClick={() => {
              fireButtonClick(ANALYTICS.BUTTONS.CONTINUE)
              navigation.push('first-print')
            }}
          >
            {pageData.continue_button_text}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default InstallDriversWindowsPage
