import {
  getAndParseListElementsIntoArray,
  getFirstElementByTag
} from './ElementUtils'

export const getDiscoveryTreeElement = (discoveryTree) =>
  getFirstElementByTag(discoveryTree, 'ledm:DiscoveryTree')

export const getSupportedTreeElements = (discoveryTree) =>
  getAndParseListElementsIntoArray(
    getDiscoveryTreeElement(discoveryTree),
    'ledm:SupportedTree'
  )

export const getSupportedIfcElements = (discoveryTree) =>
  getAndParseListElementsIntoArray(
    getDiscoveryTreeElement(discoveryTree),
    'ledm:SupportedIfc'
  )
