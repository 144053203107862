import React, { useContext } from 'react'
import classes from './HpSmartAdminPage.module.scss'
import {
  ACCOUNT_TYPE_COMPANY,
  ANALYTICS,
  CONTENT_STACK_TYPES
} from '@/store/Constants'
import { ConfigContext, PrinterContext, UserContext } from '@/store'
import Button from '@veneer/core/dist/scripts/button'
import TextHeader, { TextSubHeader } from '@/components/UI/TextHeader'
import useContentStack from '@/hooks/useContentStack'
import useAnalytics from '@/hooks/useAnalytics'

const HpSmartAdminCardContent = ({ cmsData, isHpPlusCountry, isHpPlus }) => {
  const buildCards = (data) => {
    return data
      .filter(
        (item, index) =>
          (isHpPlusCountry &&
            data?.[index].card?.show_card_hp_plus_countries) ||
          (!isHpPlusCountry &&
            data?.[index].card?.show_card_non_hp_plus_countries) ||
          isHpPlus
      )
      .map((item, i) => {
        return (
          <div className={classes.card} key={i}>
            <div className={classes.cardImageContainer}>
              <img
                src={item.card?.image?.url}
                data-testid={`image-${item?.card?.header}`}
              />
            </div>
            <div className={classes.cardInfo}>
              <div className={classes.cardHeader}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item?.card?.header
                  }}
                />
              </div>
              <div className={classes.cardText}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item?.card?.text
                  }}
                />
              </div>
            </div>
          </div>
        )
      })
  }
  return (
    <>
      <TextHeader>{cmsData?.header}</TextHeader>
      <TextSubHeader>{cmsData?.subheader}</TextSubHeader>
      <div className={classes.cards}>{buildCards(cmsData?.cards)}</div>
    </>
  )
}

const HpSmartAdminPage = () => {
  const { nextStage } = useContext(ConfigContext)
  const companyAnalytics = useAnalytics(
    ANALYTICS.SCREENS.SMART_ADMIN_VALUE_PROP
  )
  const personalAnalytics = useAnalytics(
    ANALYTICS.SCREENS.SMART_ADMIN_VALUE_PROP_PERSONAL
  )
  const { getSessionId, accountType, isHpPlusCountry } = useContext(UserContext)
  const { isHpPlus } = useContext(PrinterContext)

  const parseCmsData = (data) => ({
    header: data?.header,
    subheader: data?.subheader,
    cards: data?.cards,
    button_text: data?.button_text,
    footer: data?.footer
  })

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.hp_smart_admin,
    parsing_function: parseCmsData,
    additional_params: {
      tenant_type: accountType
    }
  })

  const fireButtonClick =
    accountType === ACCOUNT_TYPE_COMPANY
      ? companyAnalytics.fireButtonClick
      : personalAnalytics.fireButtonClick

  const fireScreenDisplayed =
    accountType === ACCOUNT_TYPE_COMPANY
      ? companyAnalytics.fireScreenDisplayed
      : personalAnalytics.fireScreenDisplayed

  const onClickHandler = () => {
    if (accountType === ACCOUNT_TYPE_COMPANY) {
      fireButtonClick(ANALYTICS.BUTTONS.HP_SMART_ADMIN)
    } else {
      fireButtonClick(ANALYTICS.BUTTONS.GO_TO_DASHBOARD)
    }
    nextStage({
      result: 'success',
      xCorrelationId: getSessionId()
    })
  }

  if (!pageData) return <></>

  fireScreenDisplayed()

  return (
    <div className={classes.hpSmartAdmin} data-testid="hp-smart-admin-page">
      <HpSmartAdminCardContent
        cmsData={pageData}
        isHpPlusCountry={isHpPlusCountry}
        isHpPlus={isHpPlus}
      />
      <div className={classes.footerContainer}>
        <div className={classes.buttons}>
          <Button
            data-testid="continue"
            onClick={onClickHandler}
            className={classes.continueButton}
          >
            {pageData.button_text}
          </Button>
        </div>
        {pageData.footer && (
          <div className={classes.footerNote}>
            <span>{pageData.footer}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default HpSmartAdminPage
