import { BIZ_MODEL_HP_PLUS } from '@/store/Constants'
import sha256 from 'crypto-js/sha256'

export const getContentStackQueryFormattedPersona = (isSingleSku, isHpPlus) => {
  if (isSingleSku) {
    return 'single_sku'
  }
  if (isHpPlus) {
    return 'e2e'
  }
  return 'flex'
}

export const getContentStackQueryIsEntryPointActivate = (
  onboardingContext = null
) => {
  if (!onboardingContext) return false
  return onboardingContext.toUpperCase() === BIZ_MODEL_HP_PLUS
}

export const getButtonCopy = (data) => {
  return (
    data?.buttons?.reduce?.((buttons, button) => {
      const { primary_button, back_button } = button
      return {
        ...buttons,
        primaryButtonCopy: primary_button?.text || buttons.primaryButtonCopy,
        backButtonCopy: back_button?.text || buttons.backButtonCopy
      }
    }, {}) || {}
  )
}

export const getProductFlow = (
  isHPPlus,
  isEcpExperience,
  isLfp,
  isScanSetup
) => {
  return (
    (isScanSetup && 'scansetup') ||
    (isLfp && 'lfp') ||
    (isEcpExperience && 'ecp') ||
    (isHPPlus && 'e2e') ||
    'flex'
  )
}

export const hashString = (input, salt) => {
  return sha256(input + sha256(salt).toString()).toString()
}
