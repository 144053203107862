import { useContext, useEffect, useRef, useState } from 'react'
import { UiMgtSvcClient } from '@jarvis/web-stratus-client'
import { ISO_VALUES, UCDE_PROGRAM_ID, YETI_PROGRAM_ID } from '@/store/Constants'
import { ConfigContext, UserContext } from '@/store'
import { Logger } from '@/utils/Logger'

const useLocales = () => {
  const [isFetchingLocales, setIsFetchingLocales] = useState(false)
  const [locales, setLocales] = useState(null)
  const [errorCode, setErrorCode] = useState(null)
  const { getSessionId } = useContext(UserContext)
  const { authProvider, stack, updateHpPlusCountries } = useContext(
    ConfigContext
  )
  const uiMgtSvcClient = useRef(null)

  const getLocales = () => {
    if (!uiMgtSvcClient.current || isFetchingLocales) return
    setIsFetchingLocales(true)
    return uiMgtSvcClient.current
      .getProgramLocales([YETI_PROGRAM_ID, UCDE_PROGRAM_ID], {
        'X-Correlation-ID': getSessionId()
      })
      .then((response) => {
        if (response.data?.length > 0) {
          let obj = []
          for (const data of response.data) {
            if (data.programId === YETI_PROGRAM_ID) {
              updateHpPlusCountries(
                data.supportedCountryList.filter((item) => ISO_VALUES[item])
              )
            } else {
              obj = data.supportedCountryList
                .filter((item) => ISO_VALUES[item]) // Remove missing mappings
                .map((value) => {
                  return {
                    value,
                    label: ISO_VALUES[value]
                  }
                })
              obj.sort((a, b) => {
                return a.label.localeCompare(b.label) // Sort by display name
              })
            }
          }
          setLocales(locales ? { ...locales, ...obj } : obj)
        }
      })
      .catch((error) => {
        Logger.error('getProgramLocales error: ', error)
        return setErrorCode(`UU_${error.response.status}_OPGL00001_GL`)
      })
      .finally(() => {
        setIsFetchingLocales(false)
      })
  }

  useEffect(() => {
    uiMgtSvcClient.current = new UiMgtSvcClient(stack, authProvider)
    return () => {
      uiMgtSvcClient.current = null
    }
  }, [authProvider, stack])

  return {
    locales,
    errorCode,
    getLocales
  }
}

export default useLocales
