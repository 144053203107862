import FirmwareUpdateConfigBodyXml from '@/assets/DeviceHttpProxyLedmBodies/FirmwareUpdateConfig.xml'

export const GET_SYSTEM_CONFIG_SERVICE = {
  method: 'GET',
  cdm: {
    serviceGun: 'com.hp.cdm.service.system.version.1',
    rel: 'identity'
  },
  ledm: {
    resourceType: 'ledm:hpLedmProductConfigDyn'
  }
}
export const SET_FW_UPDATE_CHOICE = {
  cdm: {
    method: 'PATCH',
    serviceGun: 'com.hp.cdm.service.firmwareUpdate.version.2',
    rel: 'configuration'
  },
  ledm: {
    method: 'PUT',
    bodyTemplate: FirmwareUpdateConfigBodyXml,
    manifest: {
      resourceType: 'ledm:hpLedmFirmwareUpdateManifest',
      uriResourceTypeKey: 'fwudyn:FirmwareUpdateElementType',
      nodes: [
        {
          resourceType: 'FirmwareUpdate',
          resourceUri: '/WebFWUpdate'
        },
        {
          resourceType: 'FirmwareUpdateConfig'
        }
      ]
    }
  }
}
export const GET_DEVICE_SETUP_STATUS_SERVICE = {
  method: 'GET',
  cdm: {
    serviceGun: 'com.hp.cdm.service.deviceSetup.version.1',
    rel: 'status'
  }
}
export const SET_DEVICE_SETUP_ACTION_SERVICE = {
  method: 'PATCH',
  cdm: {
    serviceGun: 'com.hp.cdm.service.deviceSetup.version.1',
    rel: 'status'
  }
}

export const GET_CONSUMABLE_SUPPLIES_SERVICE = {
  method: 'GET',
  cdm: {
    serviceGun: 'com.hp.cdm.service.supply.version.1',
    rel: 'suppliesPublic'
  },
  ledm: {
    resourceType: 'ledm:hpLedmConsumableConfigDyn'
  }
}
