import React, { useContext, useEffect, useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import classes from './CantFindPairingCodeModal.module.scss'
import Button from '@veneer/core/dist/scripts/button'
import {
  VENEER_PRIMARY_BUTTON_APPEARANCE,
  ANALYTICS,
  HP_EASY_START_DOWNLOAD_URL,
  VENEER_TERTIARY_BUTTON_APPEARANCE,
  VENEER_SECONDARY_BUTTON_APPEARANCE,
  HP_PLUS_SUPPORT_URL
} from '@/store/Constants'
import useAnalytics from '@/hooks/useAnalytics'
import { PrinterContext } from '@/store'
import { IconChevronLeft } from '@veneer/core/dist/scripts/icons'
const { LINKS, SCREENS, BUTTONS } = ANALYTICS

const CantFindPairingCodeModal = ({ showModal, onClose, copy }) => {
  const { isSingleSku } = useContext(PrinterContext)
  const CANT_FIND_PAIRING_CODE_MODAL = 1
  const GET_PAIRING_CODE_THROUGH_NETWORK_MODAL = 2
  const GET_PAIRING_CODE_THROUGH_EWS = 3
  const [currentModal, setCurrentModal] = useState(CANT_FIND_PAIRING_CODE_MODAL)
  const cantFindPairingCodeAnalytics = useAnalytics(
    SCREENS.CANT_FIND_PAIRING_CODE
  )
  const getPairingCodeThroughNetworkAnalytics = useAnalytics(
    SCREENS.GET_PAIR_CODE_THROUGH_NETWORK
  )
  const getPairingCodeThroughEwsAnalytics = useAnalytics(
    SCREENS.GET_PAIR_CODE_THROUGH_EWS
  )
  const MODAL_ANALYTICS = [
    {
      display: cantFindPairingCodeAnalytics.fireModalWindowDisplayed,
      hyperlink_click: cantFindPairingCodeAnalytics.fireHyperLinkClick,
      click: cantFindPairingCodeAnalytics.fireButtonClick
    },
    {
      display: getPairingCodeThroughNetworkAnalytics.fireModalWindowDisplayed,
      click: getPairingCodeThroughNetworkAnalytics.fireButtonClick
    },
    {
      display: getPairingCodeThroughEwsAnalytics.fireModalWindowDisplayed,
      click: getPairingCodeThroughEwsAnalytics.fireButtonClick
    }
  ]

  useEffect(() => {
    if (isSingleSku) {
      MODAL_ANALYTICS[currentModal - 1].display()
    }
  }, [currentModal])

  const clickHandler = (e) => {
    const closestAnchor = e.target.closest('a')
    if (closestAnchor && e.currentTarget.contains(closestAnchor)) {
      if (!isSingleSku) {
        const href = closestAnchor.href
        if (href === HP_EASY_START_DOWNLOAD_URL)
          MODAL_ANALYTICS[currentModal - 1].hyperlink_click(
            LINKS.HP_EASY_START_DOWNLOAD
          )
        else MODAL_ANALYTICS[currentModal - 1].hyperlink_click(LINKS.HP_SUPPORT)
      } else {
        const href = closestAnchor.href
        if (href === HP_PLUS_SUPPORT_URL)
          MODAL_ANALYTICS[currentModal - 1].hyperlink_click(LINKS.HP_SUPPORT)
      }
    }
  }

  const footer = (
    <div className={classes.footer}>
      {!isSingleSku ? (
        <Button
          appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
          onClick={onClose}
          data-testid="cant-find-pairing-code-modal-close"
        >
          {copy?.close_button_text}
        </Button>
      ) : (
        <Button
          appearance={VENEER_PRIMARY_BUTTON_APPEARANCE}
          onClick={() => {
            onClose()
            MODAL_ANALYTICS[currentModal - 1].click(BUTTONS.CLOSE)
            setCurrentModal(CANT_FIND_PAIRING_CODE_MODAL)
          }}
          data-testid={
            'cant-find-pairing-code-modals-close-modal-' + currentModal
          }
        >
          {
            copy[currentModal - 1]?.cant_find_pairing_code_modal
              ?.close_button_text
          }
        </Button>
      )}
    </div>
  )

  return (
    <>
      {!isSingleSku ? (
        <Modal
          className={classes.cantFindPairingCodeModal}
          show={showModal}
          onClose={onClose}
          title={copy?.header}
          footer={footer}
          alignFooter="end"
        >
          <div
            className={classes.body}
            data-testid="cant-find-pairing-code-modal"
          >
            <p
              data-testid="cant-find-pairing-code-modal-body"
              onClick={clickHandler}
              dangerouslySetInnerHTML={{
                __html: copy?.body
              }}
            />
          </div>
        </Modal>
      ) : (
        <Modal
          className={classes.cantFindPairingCodeMultipleModals}
          show={showModal}
          onClose={onClose}
          footer={footer}
          alignFooter="end"
        >
          {currentModal > 1 && (
            <Button
              appearance={VENEER_TERTIARY_BUTTON_APPEARANCE}
              onClick={() => {
                MODAL_ANALYTICS[currentModal - 1].click(BUTTONS.BACK)
                setCurrentModal(CANT_FIND_PAIRING_CODE_MODAL)
              }}
              data-testid={
                'cant-find-pairing-code-modals-back-btn-modal-' + currentModal
              }
            >
              {
                <IconChevronLeft
                  color="hpBlue7"
                  className={classes.iconChevronLeft}
                />
              }
              {
                copy[currentModal - 1]?.cant_find_pairing_code_modal
                  .back_button_text
              }
            </Button>
          )}
          <div
            className={
              currentModal === CANT_FIND_PAIRING_CODE_MODAL
                ? classes.headerMain
                : classes.headerAlt
            }
            data-testid={
              'cant-find-pairing-code-modals-header-modal-' + currentModal
            }
          >
            {copy[currentModal - 1]?.cant_find_pairing_code_modal.header}
          </div>
          <div
            className={classes.body}
            data-testid={
              'cant-find-pairing-code-modals-body-modal-' + currentModal
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  copy[currentModal - 1]?.cant_find_pairing_code_modal.body
              }}
            />
          </div>
          {currentModal === CANT_FIND_PAIRING_CODE_MODAL && (
            <>
              <div className={classes.buttons}>
                <Button
                  appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
                  onClick={() => {
                    MODAL_ANALYTICS[currentModal - 1].click(
                      BUTTONS.GET_CODE_THROUGH_NETWORK_BUTTON
                    )
                    setCurrentModal(GET_PAIRING_CODE_THROUGH_NETWORK_MODAL)
                  }}
                  data-testid="cant-find-pairing-code-modals-connect-printer-btn"
                >
                  {
                    copy[currentModal - 1]?.cant_find_pairing_code_modal
                      .connect_printer_button_text
                  }
                </Button>
                <Button
                  appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
                  onClick={() => {
                    MODAL_ANALYTICS[currentModal - 1].click(
                      BUTTONS.GET_CODE_THROUGH_EWS_BUTTON
                    )
                    setCurrentModal(GET_PAIRING_CODE_THROUGH_EWS)
                  }}
                  data-testid="cant-find-pairing-code-modals-get-pairing-code-btn"
                >
                  {
                    copy[currentModal - 1]?.cant_find_pairing_code_modal
                      .get_pairing_code_button_text
                  }
                </Button>
              </div>
              <div onClick={clickHandler} className={classes.bodyFooter}>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      copy[currentModal - 1]?.cant_find_pairing_code_modal
                        .footer
                  }}
                />
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  )
}
export default CantFindPairingCodeModal
