import React, { useContext } from 'react'
import useAnalytics from '@/hooks/useAnalytics'
import { ANALYTICS } from '@/store/Constants'
import { ErrorContext } from '@/store'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS
const ExpiredCodeError = ({ error, children }) => {
  const { resetError, setClearPairingCode, showReconsiderModal } = useContext(
    ErrorContext
  )

  const errorModalAnalytics = useAnalytics(SCREENS.EXPIRED_CODE_ERROR)
  const primaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.TRY_AGAIN)
    resetError()
    setClearPairingCode(true)
  }

  const secondaryClick = () => {
    errorModalAnalytics.fireButtonClick(BUTTONS.CANCEL_SETUP_MANUAL)
    showReconsiderModal()
  }

  const hyperlinkClick = () => {
    errorModalAnalytics.fireHyperLinkClick(LINKS.HP_SUPPORT)
  }

  errorModalAnalytics.fireModalWindowDisplayed({
    detail: error,
    name: ANALYTICS.ERROR_CODE
  })

  return <>{children({ primaryClick, secondaryClick, hyperlinkClick })}</>
}

export default ExpiredCodeError
