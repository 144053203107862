import { useCallback, useContext, useMemo } from 'react'
import { DeviceHttpProxyContext } from '@/store/DeviceHttpProxyContext'
import {
  getLedmURIFromManifest,
  getCdmHrefByServiceRelAndMethod,
  getLedmResourceUriFromDiscoveryTree
} from '@/utils/DeviceHttpProxy'
import { useDeviceHttpProxyInstance } from '@/hooks/DeviceHttpProxy/useDeviceHttpProxyInstance'
import { useLedmManifest } from '@/hooks/DeviceHttpProxy/useLedmManifest'

export function useDeviceHttpProxy() {
  const {
    fetch,
    isCdm,
    resourceController: { getPrinterServices }
  } = useContext(DeviceHttpProxyContext)
  const { fetchManifest } = useLedmManifest()
  const { isDeviceHttpProxyReady } = useDeviceHttpProxyInstance()

  const buildCdmRequestData = useCallback(
    (dhpService, cdmBody) => {
      const { serviceGun, rel } = dhpService.cdm
      const method = dhpService.cdm.method || dhpService.method
      const path = getCdmHrefByServiceRelAndMethod({
        servicesDiscovery: getPrinterServices(),
        serviceGun,
        rel,
        method
      })
      const data = cdmBody && JSON.stringify(cdmBody)
      return { path, method, data }
    },
    [getPrinterServices]
  )

  const getLedmManifestPath = useCallback(
    async (manifest, method) => {
      const { uriResourceTypeKey, nodes } = manifest
      return getLedmURIFromManifest({
        manifest: await fetchManifest(manifest.resourceType),
        uriResourceTypeKey,
        nodes,
        method
      })
    },
    [fetchManifest]
  )

  const buildLedmRequestData = useCallback(
    async (dhpService, ledmBodyParser) => {
      const { resourceType, manifest } = dhpService.ledm
      const method = dhpService.ledm.method || dhpService.method
      const path = manifest
        ? await getLedmManifestPath(manifest, method)
        : getLedmResourceUriFromDiscoveryTree(
            getPrinterServices(),
            resourceType
          )

      const data =
        typeof ledmBodyParser === 'function'
          ? ledmBodyParser(dhpService.ledm.bodyTemplate)
          : dhpService.ledm.bodyTemplate

      return { path, method, data }
    },
    [getLedmManifestPath, getPrinterServices]
  )

  /** @typedef {
   * (
   *   dhpService: object,
   *   cdmBody?: object,
   *   ledmBodyParser?: (xmlTemplate: string) => string
   * ) => ReturnType<typeof fetch>} sendRequest */
  const sendRequest = useCallback(
    async (dhpService, cdmBody = undefined, ledmBodyParser = undefined) => {
      await isDeviceHttpProxyReady()

      const { path, method, data } = isCdm
        ? buildCdmRequestData(dhpService, cdmBody)
        : await buildLedmRequestData(dhpService, ledmBodyParser)

      return fetch({
        path,
        method,
        ...(data && {
          body: { data }
        })
      })
    },
    [
      isCdm,
      fetch,
      buildLedmRequestData,
      buildCdmRequestData,
      isDeviceHttpProxyReady
    ]
  )

  return useMemo(() => ({ isCdm, sendRequest }), [isCdm, sendRequest])
}
