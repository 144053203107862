import React, { useEffect } from 'react'
import useContentStack from '@/hooks/useContentStack'
import { ANALYTICS, CONTENT_STACK_TYPES } from '@/store/Constants'
import { GENERIC_MODAL_TYPES } from './GenericModal'
import useAnalytics from '@/hooks/useAnalytics'
const { BUTTONS, SCREENS } = ANALYTICS

const DeclineHpPlusModal = ({ children, setVisibility, setOverride }) => {
  const { fireModalWindowDisplayed, fireButtonClick } = useAnalytics(
    SCREENS.DECLINE_HP_PLUS_MODAL
  )
  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.decline_hp_plus_offer_modal
  })
  useEffect(() => {
    fireModalWindowDisplayed()
  }, [])

  const declineClickHandler = () => {
    fireButtonClick(BUTTONS.DECLINE_HP_PLUS_BUTTON)
    setOverride(GENERIC_MODAL_TYPES.PDSN_MODAL)
  }
  const backClickHandler = () => {
    fireButtonClick(BUTTONS.BACK_TO_OFFER_BUTTON)
    setVisibility(false)
  }
  return (
    <>
      {children({
        buttonClicks: [declineClickHandler, backClickHandler],
        data: pageData
      })}
    </>
  )
}

export default DeclineHpPlusModal
