import { useCallback, useContext, useMemo } from 'react'
import { ConfigContext, UserContext } from '@/store'

export function useNextStage() {
  const { nextStage } = useContext(ConfigContext)
  const { getSessionId } = useContext(UserContext)

  const dispatchNextStage = useCallback(
    (result, output = undefined) =>
      nextStage({
        result,
        xCorrelationId: getSessionId(),
        ...(output && { output })
      }),
    [nextStage, getSessionId]
  )

  const dispatchDeclinedStage = useCallback(
    (output = undefined) => dispatchNextStage('declined', output),
    [dispatchNextStage]
  )

  const dispatchSuccessStage = useCallback(
    (output = undefined) => dispatchNextStage('success', output),
    [dispatchNextStage]
  )

  return useMemo(
    () => ({
      dispatchNextStage,
      dispatchSuccessStage,
      dispatchDeclinedStage
    }),
    [dispatchSuccessStage, dispatchDeclinedStage, dispatchNextStage]
  )
}
