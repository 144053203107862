// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Ghc-nCX-JB9BtN13zeN-g{display:flex;flex-direction:column;align-items:center;max-width:546px}._1sTstkauIj6sHhSNLsAEpV{margin:80px 0 0}._1sTstkauIj6sHhSNLsAEpV ._2V_dO4YsIPw7uFLfhj7Gxx{margin-top:20px;display:flex;flex-direction:column;font-size:18px;line-height:24px;text-align:center;color:#404040}._2qqRboO0_1GcFeTIUCu-kG{margin:30px 0 0}\n", "",{"version":3,"sources":["webpack://./src/pages/Web/Activation/ActivatingPage.module.scss"],"names":[],"mappings":"AAAA,yBAAW,YAAY,CAAC,qBAAqB,CAAC,kBAAkB,CAAC,eAAe,CAAC,yBAAY,eAAe,CAAC,kDAA4B,eAAe,CAAC,YAAY,CAAC,qBAAqB,CAAC,cAAc,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,aAAa,CAAC,yBAAS,eAAe","sourcesContent":[".container{display:flex;flex-direction:column;align-items:center;max-width:546px}.activating{margin:80px 0 0}.activating .activatingText{margin-top:20px;display:flex;flex-direction:column;font-size:18px;line-height:24px;text-align:center;color:#404040}.success{margin:30px 0 0}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3Ghc-nCX-JB9BtN13zeN-g",
	"activating": "_1sTstkauIj6sHhSNLsAEpV",
	"activatingText": "_2V_dO4YsIPw7uFLfhj7Gxx",
	"success": "_2qqRboO0_1GcFeTIUCu-kG"
};
export default ___CSS_LOADER_EXPORT___;
