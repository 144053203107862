import { useState, useRef, useEffect, useContext } from 'react'
import { RelationshipsClient } from '@jarvis/web-stratus-client'
import { ConfigContext } from '@/store'
import { ACCOUNT_TYPE_COMPANY } from '@/store/Constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Logger } from '@/utils/Logger'

const useTenancyRegistry = () => {
  const tenancyClient = useRef(null)
  const { authProvider, stack } = useContext(ConfigContext)
  const [isFetching, setIsFetching] = useState(false)
  const [availableOrganizations, setAvailableOrganizations] = useState(null)
  const {
    onbpReleaseTenantlesstokensupport: isTenantlessTokenSupported
  } = useFlags()

  const getOrganizations = (type = ACCOUNT_TYPE_COMPANY) => {
    setIsFetching(true)
    if (!tenancyClient.current) return
    let nodes = []
    return tenancyClient.current
      .getAllPersonNodes({ filter: 'admin' })
      .then(async (response) => {
        for (const node of response.data.content) {
          await tenancyClient.current
            .getNode({ nodeId: node })
            .then((nodeResponse) => {
              if (nodeResponse.data.type === type) {
                nodes.push(nodeResponse.data)
              }
            })
        }
        setAvailableOrganizations(nodes)
      })
      .catch((e) => {
        Logger.error('getOrganizations error:', e)
        setAvailableOrganizations([])
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    tenancyClient.current = new RelationshipsClient(
      stack,
      isTenantlessTokenSupported
        ? authProvider.createOrglessAuthProvider()
        : authProvider
    )
    return () => {
      tenancyClient.current = null
    }
  }, [authProvider, stack, isTenantlessTokenSupported])

  return {
    isFetching,
    availableOrganizations,
    getOrganizations
  }
}

export default useTenancyRegistry
