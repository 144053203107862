import React, { useContext } from 'react'
import useContentStack from '@/hooks/useContentStack'
import { CONTENT_STACK_TYPES, MULTI_FUNCTION_PRINTER } from '@/store/Constants'
import { PrinterContext } from '@/store'
import { useNextStage } from '@/hooks/useNextStage'

export function ExitHpCloudConnection({ children, setVisibility }) {
  const { printerFunctionType } = useContext(PrinterContext)
  const { dispatchDeclinedStage } = useNextStage()

  const { pageData } = useContentStack({
    content_type:
      printerFunctionType === MULTI_FUNCTION_PRINTER
        ? CONTENT_STACK_TYPES.exit_hp_cloud_connection_mfp
        : CONTENT_STACK_TYPES.exit_hp_cloud_connection_sfp
  })

  const closeClickHandler = () => {
    // TODO: Analytics
    setVisibility(false)
  }
  const exitWithoutConnecting = () => {
    // TODO: Analytics
    dispatchDeclinedStage()
  }

  return (
    <>
      {children({
        data: pageData,
        buttonClicks: [closeClickHandler, exitWithoutConnecting],
        onClose: closeClickHandler,
        alignment: {
          content: 'center'
        }
      })}
    </>
  )
}
