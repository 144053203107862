import React, { useCallback, useContext, useMemo } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { FETCH_STATUS } from '@/store/Constants'
import { LinkButton } from '@/components/UI/Buttons'
import { Logger } from '@/utils/Logger'
import { ErrorContext, STAGES } from '@/store'
import { usePartnerLinkClient } from '@/hooks/clients/usePartnerLinkClient'
import classes from './ButtonsPartnerLink.module.scss'
import { CUSTOM_ERRORS } from '@/store/ErrorContext'

export function ButtonsPartnerLink({
  buttonsCopy,
  footerCopy,
  status,
  accountId,
  setStatus
}) {
  const { onError } = useContext(ErrorContext)
  const { connectPrinterToPartner } = usePartnerLinkClient()

  const isLoading = useMemo(() => status === FETCH_STATUS.LOADING, [status])

  const connectHandler = useCallback(async () => {
    setStatus(FETCH_STATUS.LOADING)
    try {
      await connectPrinterToPartner(accountId)
      setStatus(FETCH_STATUS.SUCCESS)
    } catch (error) {
      setStatus(FETCH_STATUS.FAILURE)
      // If it's not a user input error we log the error
      if (error?.status !== 400) {
        Logger.error('connect (PartnerLink) error:', error)
      }
      onError({
        err: `PL_${error?.status || 400}_${CUSTOM_ERRORS.partner_link}_SB`,
        stg: STAGES.generic,
        /**
         * The retry behavior will infer the Continue Without Connecting logic,
         * this logic will be called inside PartnerLinkError component modal (non-part of
         * PartnerLinkPage render chain).
         *
         * The Continue Without Connecting is a logic that should change the
         * PartnerLinkPage local state Status to success, but as this state is available only
         * for the PartnerLinkPage render chain, it can't be changed by components not-part of
         * this chain components (PartnerLinkError modal component).
         *
         * Therefore, we are passing a lambda callback function through the ErrorContext to manipulate
         * this local state by components are not-part of the PartnerLinkPage render chain but part of
         * ErrorContext.
         */
        behavior: () => setStatus(FETCH_STATUS.SUCCESS)
      })
    }
  }, [onError, accountId, setStatus, connectPrinterToPartner])

  return (
    <div className={classes.footer}>
      <div className={classes.checkingAccountId}>
        {isLoading && footerCopy.checking_account_label}
      </div>
      <div className={classes.buttons}>
        <LinkButton
          iconPosition="start"
          appearance="ghost"
          disabled={isLoading}
          href={buttonsCopy.hp_command_center.link}
        >
          {buttonsCopy.hp_command_center.label}
        </LinkButton>
        <LinkButton
          openNewTab
          iconPosition="start"
          appearance="ghost"
          disabled={isLoading}
          href={buttonsCopy.get_drivers.link}
        >
          {buttonsCopy.get_drivers.label}
        </LinkButton>
        <Button
          data-testid="connect-button"
          onClick={connectHandler}
          disabled={!accountId || isLoading}
          loading={isLoading}
        >
          {footerCopy.connect}
        </Button>
      </div>
    </div>
  )
}
