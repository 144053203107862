import axios from 'axios'
import { Stack } from '@jarvis/web-stratus-client'
import { useCallback, useContext, useMemo, useState } from 'react'
import { ConfigContext, PrinterContext } from '@/store'
import { useFlags } from 'launchdarkly-react-client-sdk'
import parseUserAgent from 'user-agent-parser'
import { Logger } from '@/utils/Logger'
import { SOFTWARE_TYPES } from '@/store/Constants'

const { FULLKARNAK, NOT_SUPPORTED } = SOFTWARE_TYPES

const HP123_DOMAIN = {
  [Stack.pie]: 'https://123-test.hpoobe.com',
  [Stack.stage]: 'https://123-stage.hpoobe.com',
  [Stack.prod]: 'https://123.hp.com'
}

function formatFtpUrl(ftpUrl) {
  return ftpUrl.replace('ftp://', 'https://')
}

function swlsSoftwareFtpUrl(data, status) {
  const swlsSoftware = data.find(({ Type: type }) => type === FULLKARNAK)
  if (!swlsSoftware?.FtpURL) {
    const error = new Error("FULLKARNAK wasn't found")
    error.data = data
    error.status = status
    throw error
  }
  return swlsSoftware.FtpURL
}

export const useGetWinSoftwareUrl = () => {
  const { stack, setDriverUrl } = useContext(ConfigContext)
  const { productNumber } = useContext(PrinterContext)
  const [checkingForDriver, setCheckingForDriver] = useState(false)
  const {
    onbpReleaseMarconipdlList: { marconiPdl }
  } = useFlags()

  const getWinSoftwareUrl = useCallback(async () => {
    setCheckingForDriver(true)

    const softwareUrl = `${HP123_DOMAIN[stack]}/oobe/${productNumber}`

    const userAgent = parseUserAgent(window.navigator.userAgent)
    const swlsParams = new URLSearchParams({
      os: `${userAgent.os.name} ${userAgent.os.version}`,
      Client: 'Portal-OOBE',
      ProductNumber: productNumber
    })
    const swlsSoftwareUrl = `https://h20614.www2.hp.com/ediags/solutions/software/v4?${swlsParams}`

    const driversNotFoundUrl = `${HP123_DOMAIN[stack]}/printers/laserjet`
    const driversNotFoundPdlUrl = `${HP123_DOMAIN[stack]}/printers/officejet`

    try {
      const { data } = await axios.get(softwareUrl, {
        headers: {
          userAgent: window.navigator.userAgent
        }
      })
      setDriverUrl(formatFtpUrl(data.softwareUrl))
    } catch (e) {
      if (
        !axios.isAxiosError(e) ||
        (axios.isAxiosError(e) && e.status !== 404)
      ) {
        Logger.error('HP123 software error:', e)
      }

      try {
        const { data, status } = await axios.get(swlsSoftwareUrl)
        const ftpUrl = swlsSoftwareFtpUrl(data, status)
        setDriverUrl(formatFtpUrl(ftpUrl))
      } catch (e) {
        if (!e.data || e.data[0]?.Type !== NOT_SUPPORTED) {
          Logger.error('FULLKARNAK software error:', e)
        }
        setDriverUrl(
          marconiPdl.includes(productNumber)
            ? driversNotFoundPdlUrl
            : driversNotFoundUrl
        )
      }
    } finally {
      setCheckingForDriver(false)
    }
  }, [stack, productNumber, setDriverUrl, marconiPdl])

  return useMemo(() => ({ checkingForDriver, getWinSoftwareUrl }), [
    checkingForDriver,
    getWinSoftwareUrl
  ])
}
