// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2KKzZIsGHrJyYxhhmOvA8P header{display:flex;flex-direction:column;gap:8px;margin-bottom:20px}._2KKzZIsGHrJyYxhhmOvA8P header h5{font-size:32px;line-height:40px;font-weight:400}._2KKzZIsGHrJyYxhhmOvA8P header p{font-size:20px;font-weight:300;line-height:28px;min-height:56px}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Smallville/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAAA,gCAAmB,YAAY,CAAC,qBAAqB,CAAC,OAAO,CAAC,kBAAkB,CAAC,mCAAsB,cAAc,CAAC,gBAAgB,CAAC,eAAe,CAAC,kCAAqB,cAAc,CAAC,eAAe,CAAC,gBAAgB,CAAC,eAAe","sourcesContent":[".pageHeader header{display:flex;flex-direction:column;gap:8px;margin-bottom:20px}.pageHeader header h5{font-size:32px;line-height:40px;font-weight:400}.pageHeader header p{font-size:20px;font-weight:300;line-height:28px;min-height:56px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "_2KKzZIsGHrJyYxhhmOvA8P"
};
export default ___CSS_LOADER_EXPORT___;
