// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._23CKK9y0JfNbpvSJjlEzl1 li{display:flex;align-items:center;padding:2px 0}._23CKK9y0JfNbpvSJjlEzl1 p{margin:10px 0 0 20px;display:flex}._23CKK9y0JfNbpvSJjlEzl1 img{position:relative;top:3px}\n", "",{"version":3,"sources":["webpack://./src/components/FirmwareUpdate/FeaturesList.module.scss"],"names":[],"mappings":"AAAA,4BAAkB,YAAY,CAAC,kBAAkB,CAAC,aAAa,CAAC,2BAAiB,oBAAoB,CAAC,YAAY,CAAC,6BAAmB,iBAAiB,CAAC,OAAO","sourcesContent":[".benefitsGroup li{display:flex;align-items:center;padding:2px 0}.benefitsGroup p{margin:10px 0 0 20px;display:flex}.benefitsGroup img{position:relative;top:3px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"benefitsGroup": "_23CKK9y0JfNbpvSJjlEzl1"
};
export default ___CSS_LOADER_EXPORT___;
