import React, { useState } from 'react'
import { CONTENT_STACK_TYPES, FETCH_STATUS } from '@/store/Constants'
import useContentStack from '@/hooks/useContentStack'
import {
  ContentSectionPartnerLink,
  FooterSectionPartnerLink
} from '@/pages/Web/PartnerLink/components'

import classes from './PartnerLinkPage.module.scss'

export function PartnerLinkPage() {
  const [accountId, setAccountId] = useState('')
  const [status, setStatus] = useState(FETCH_STATUS.IDLE)

  const { pageData } = useContentStack({
    content_type: CONTENT_STACK_TYPES.partner_link
  })

  if (!pageData) return <></>

  return (
    <div className={classes.partnerLinkPage} data-testid="partner-link-page">
      <ContentSectionPartnerLink
        status={status}
        pageData={pageData}
        accountId={accountId}
        setAccountId={setAccountId}
      />
      <FooterSectionPartnerLink
        status={status}
        pageData={pageData}
        accountId={accountId}
        setStatus={setStatus}
      />
    </div>
  )
}
