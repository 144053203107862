import React, { useContext, useState, useEffect, useCallback } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import classes from './ErrorModal.module.scss'
import Button from '@veneer/core/dist/scripts/button'
import {
  ConfigContext,
  ErrorContext,
  PrinterContext,
  UserContext,
  STAGES
} from '@/store'
import {
  TRADITIONAL_SUPPORT_URL,
  HP_PLUS_SUPPORT_URL,
  ANALYTICS,
  VENEER_SECONDARY_BUTTON_APPEARANCE
} from '@/store/Constants'

const { BUTTONS, LINKS, SCREENS } = ANALYTICS
import useAnalytics from '@/hooks/useAnalytics'
import ErrorDetail from '../ErrorDetail'
import { getModeForAnalytics } from '@/utils/Functions'
import { UNABLE_TO_LOAD_PAGE_ERROR } from '@/store/ErrorContext'

const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  var secondPrefix = seconds < 10 ? '0' : ''
  return seconds == 60
    ? minutes + 1 + ':00'
    : minutes + ':' + secondPrefix + seconds
}

const ErrorModal = () => {
  const {
    error,
    stage,
    errorCopy,
    errorData,
    retry,
    resetError,
    showReconsiderModal,
    errorProps,
    reconsiderModalVisible
  } = useContext(ErrorContext)
  const {
    navigation,
    getTextTree,
    publishCdmEvent,
    getText,
    nextStage
  } = useContext(ConfigContext)
  const { isHpPlus } = useContext(PrinterContext)
  const { selectedCountry, getSessionId } = useContext(UserContext)
  const pageCopy = getTextTree('pages.onboarding_error')
  const commonCopy = getTextTree('common')
  const [timeoutValue, setTimeoutValue] = useState(0)
  const [timeoutDisplay, setTimeoutDisplay] = useState('')
  const [clickEvent, setClickEvent] = useState(null)
  const [hyperlinkClickEvent, setHyperlinkClickEvent] = useState(null)
  const [reconsiderClickEvent, setReconsiderClickEvent] = useState(null)
  const [builtAnalytics, setBuiltAnalytics] = useState(false)
  const errorForDisplay = `(${commonCopy.error_code} ${error})`

  const analyticsUnsupportedCountryErrorModal = useAnalytics(
    SCREENS.COUNTRY_UNSUPPORTED_ERROR_MODAL
  )
  const analyticsDomainErrorModal = useAnalytics(SCREENS.DOMAIN_ERROR_MODAL)
  const analyticsCodeLimitError = useAnalytics(SCREENS.CODE_LIMIT_ERROR, false)
  const analyticsGenericError = useAnalytics(SCREENS.GENERIC_ERROR)
  const analyticsActivationErrorGeneric = useAnalytics(
    SCREENS.ACTIVATION_ERROR_GENERIC
  )
  const analyticsPairingCodeDownError = useAnalytics(
    SCREENS.PAIRING_CODE_DOWN_ERROR
  )
  const analyticsActivationErrorPairingToken = useAnalytics(
    SCREENS.ACTIVATION_ERROR_PAIRING_TOKEN
  )
  const analyticsExpiredCodeError = useAnalytics(
    SCREENS.EXPIRED_CODE_ERROR,
    false
  )
  const analyticsUnableToLoadPageError = useAnalytics(
    SCREENS.UNABLE_TO_LOAD_PAGE
  )
  const accountErrorAnalytics = useCallback(() => {
    if (error.indexOf('UHPC00001') > -1) {
      analyticsUnsupportedCountryErrorModal.fireScreenDisplayed({
        mode: errorProps.mode,
        detail: error,
        name: ANALYTICS.ERROR_CODE
      })
      setClickEvent(
        analyticsUnsupportedCountryErrorModal.buildButtonClickEvent(
          BUTTONS.SUPPORT,
          {
            mode: errorProps.mode
          }
        )
      )
      return
    }
    analyticsDomainErrorModal.fireScreenDisplayed({
      mode: errorProps.mode,
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
    setClickEvent(
      analyticsDomainErrorModal.buildButtonClickEvent(BUTTONS.TRY_AGAIN, {
        mode: errorProps.mode
      })
    )
    setHyperlinkClickEvent(
      analyticsDomainErrorModal.buildHyperLinkClickEvent(LINKS.HP_SUPPORT, {
        mode: errorProps.mode
      })
    )
  }, [
    error,
    analyticsDomainErrorModal,
    analyticsUnsupportedCountryErrorModal,
    errorProps
  ])

  const pairingErrorAnalytics = useCallback(() => {
    let analyticsError = analyticsPairingCodeDownError
    if (error.indexOf('E0888U0024') > -1) {
      analyticsError = analyticsCodeLimitError
    } else if (error.indexOf('E0888U0025') > -1) {
      analyticsError = analyticsExpiredCodeError
    }

    analyticsError.fireScreenDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
    setClickEvent(analyticsError.buildButtonClickEvent(BUTTONS.TRY_AGAIN))
    setHyperlinkClickEvent(
      analyticsError.buildHyperLinkClickEvent(LINKS.HP_SUPPORT)
    )
    setReconsiderClickEvent(
      analyticsError.buildButtonClickEvent(BUTTONS.CANCEL_SETUP_MANUAL)
    )
  }, [
    error,
    analyticsCodeLimitError,
    analyticsExpiredCodeError,
    analyticsPairingCodeDownError
  ])

  const unableToLoadPageErrorAnalytics = useCallback(() => {
    analyticsUnableToLoadPageError.fireModalWindowDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
    setClickEvent(
      analyticsUnableToLoadPageError.buildButtonClickEvent(BUTTONS.TRY_AGAIN)
    )
    setHyperlinkClickEvent(
      analyticsUnableToLoadPageError.buildHyperLinkClickEvent(LINKS.HP_SUPPORT)
    )
  }, [error, analyticsUnableToLoadPageError])

  const genericErrorAnalytics = useCallback(() => {
    analyticsGenericError.fireScreenDisplayed({
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
    setClickEvent(analyticsGenericError.buildButtonClickEvent(BUTTONS.SUPPORT))
  }, [error, analyticsGenericError])

  const activationErrorAnalytics = useCallback(() => {
    const mode = getModeForAnalytics(error)

    if (errorData?.is_blocking) {
      analyticsActivationErrorGeneric.fireScreenDisplayed({
        detail: error,
        mode,
        name: ANALYTICS.ERROR_CODE
      })
      setClickEvent(
        analyticsActivationErrorGeneric.buildButtonClickEvent(BUTTONS.SUPPORT)
      )
      setReconsiderClickEvent(
        analyticsActivationErrorGeneric.buildButtonClickEvent(
          BUTTONS.CANCEL_SETUP_MANUAL
        )
      )
      return
    }
    analyticsActivationErrorPairingToken.fireScreenDisplayed({
      mode,
      detail: error,
      name: ANALYTICS.ERROR_CODE
    })
    setClickEvent(
      analyticsActivationErrorPairingToken.buildButtonClickEvent(
        BUTTONS.TRY_AGAIN
      )
    )
    setReconsiderClickEvent(
      analyticsActivationErrorPairingToken.buildButtonClickEvent(
        BUTTONS.CANCEL_SETUP_MANUAL
      )
    )
    setHyperlinkClickEvent(
      analyticsActivationErrorPairingToken.buildHyperLinkClickEvent(
        LINKS.HP_SUPPORT
      )
    )
  }, [
    error,
    analyticsActivationErrorGeneric,
    analyticsActivationErrorPairingToken,
    errorData
  ])

  const buildErrorAnalytics = useCallback(() => {
    switch (stage) {
      case STAGES.account:
        accountErrorAnalytics()
        break
      case STAGES.pairing:
        pairingErrorAnalytics()
        break
      case STAGES.generic:
        genericErrorAnalytics()
        break
      case STAGES[UNABLE_TO_LOAD_PAGE_ERROR]:
        unableToLoadPageErrorAnalytics()
        break
      default:
        activationErrorAnalytics()
    }
    setBuiltAnalytics(true)
  }, [
    stage,
    accountErrorAnalytics,
    pairingErrorAnalytics,
    activationErrorAnalytics,
    genericErrorAnalytics
  ])

  const handleClick = () => {
    if (clickEvent) {
      publishCdmEvent(clickEvent)
    }
    if (typeof retry == 'function') {
      retry()
    } else if (retry) {
      navigation.push(retry)
    }
    resetError()
  }

  const showReconsider = () => {
    if (reconsiderClickEvent) {
      publishCdmEvent(reconsiderClickEvent)
    }
    showReconsiderModal()
  }

  const openSupport = () => {
    if (clickEvent) {
      publishCdmEvent(clickEvent)
    }
    const url = isHpPlus ? HP_PLUS_SUPPORT_URL : TRADITIONAL_SUPPORT_URL
    window.open(url, '_blank').focus()
  }

  const cancelAndManualSetup = () => {
    analyticsGenericError.fireButtonClick(BUTTONS.CANCEL_SETUP_MANUAL)
    const additionalOutputData = errorProps?.nextStageData
      ? { output: errorProps.nextStageData }
      : {}
    nextStage({
      result: 'failure',
      ...additionalOutputData,
      errorInfo: {
        errorCode: error,
        errorDescription: ''
      },
      xCorrelationId: getSessionId()
    })
  }

  /*
   This function binds to a container that contains a dynamically embedded
   anchor tag. The target of the event will yield this anchor tag, if the
   anchor tag is clicked within this container
  */
  const fireChildAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (
      closestAnchor &&
      e.currentTarget.contains(closestAnchor) &&
      hyperlinkClickEvent
    ) {
      publishCdmEvent(hyperlinkClickEvent)
    }
  }

  useEffect(() => {
    if (timeoutValue === 0 && errorData?.timeout) {
      setTimeoutDisplay(millisToMinutesAndSeconds(errorData.timeout))
      setTimeoutValue(errorData.timeout)
    }
  }, [errorData, timeoutValue])

  useEffect(() => {
    if (!builtAnalytics) {
      buildErrorAnalytics()
    }
  }, [builtAnalytics, buildErrorAnalytics])

  useEffect(() => {
    let timeout
    let interval
    if (errorData?.timeout && !interval) {
      let secondsPassed = 0
      interval = setInterval(() => {
        secondsPassed += 1
        setTimeoutDisplay(
          millisToMinutesAndSeconds(timeoutValue - 1000 * secondsPassed)
        )
      }, 1000)
      timeout = setTimeout(() => {
        resetError()
        clearInterval(interval)
      }, errorData.timeout)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [errorData, resetError, timeoutValue])

  let footer = <></>
  let reconsiderButton = <></>
  if (!isHpPlus && errorData?.reconsider) {
    reconsiderButton = (
      <Button
        appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
        onClick={showReconsider}
        data-testid="reconsider"
      >
        {getText('common.manual_setup')}
      </Button>
    )
  }

  if (errorData?.retryable) {
    footer = (
      <>
        <Button onClick={handleClick} data-testid="try-again">
          {pageCopy.try_again}
        </Button>
        {reconsiderButton}
      </>
    )
  } else if (errorData?.is_blocking) {
    footer = (
      <>
        <Button onClick={openSupport} data-testid="support">
          {isHpPlus ? commonCopy.hp_plus_support : commonCopy.hp_support}
        </Button>
        {stage === STAGES.generic && !isHpPlus && (
          <Button
            data-testid="cancel-manual"
            appearance={VENEER_SECONDARY_BUTTON_APPEARANCE}
            onClick={cancelAndManualSetup}
          >
            {commonCopy.manual_setup}
          </Button>
        )}
        {reconsiderButton}
      </>
    )
  }
  return (
    <Modal
      id="error-modal"
      data-testid="error-modal"
      className={classes.modal}
      show={!reconsiderModalVisible}
      closeOnBlur={false}
      title={errorCopy.header.replace('[[country]]', selectedCountry)}
      footer={<div className="vn-button-group--responsive">{footer}</div>}
      alignFooter="end"
    >
      <div className={classes.error}>
        <ErrorDetail
          copy={errorCopy}
          error={errorData}
          click={fireChildAnalyticsEvent}
          timeoutDisplay={timeoutDisplay}
        />
        <div className={classes.errorForDisplay}>
          <div>{errorForDisplay}</div>
        </div>
      </div>
    </Modal>
  )
}
export default ErrorModal
