import React, { useState, useContext, useEffect } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import { ErrorContext } from '@/store/ErrorContext'
import { VENEER_PRIMARY_BUTTON_APPEARANCE } from '@/store/Constants'
import { EMPTY_FUNCTION } from '@/utils/Functions'

const NextStageButton = ({
  copy,
  onClick = EMPTY_FUNCTION,
  disabled = false,
  appearance = VENEER_PRIMARY_BUTTON_APPEARANCE,
  dataTestId = null,
  loading = false
}) => {
  const [nextStageButtonPressed, setNextStageButtonPressed] = useState(false)
  const { error } = useContext(ErrorContext)

  const onClickHandler = () => {
    setNextStageButtonPressed(true)
    onClick()
  }

  useEffect(() => {
    if (error) {
      setNextStageButtonPressed(false)
    }
  }, [error])

  return (
    <Button
      data-testid={dataTestId}
      onClick={onClickHandler}
      loading={nextStageButtonPressed || loading}
      disabled={disabled}
      appearance={appearance}
    >
      {copy}
    </Button>
  )
}

export default NextStageButton
